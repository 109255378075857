import { Chip, IconButton, Box } from '@mui/material';
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
} from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcDotSpread, IcWarning } from '@/assets/images';
import { customNumericOperators } from '@/components';
import { excludeValues, formatDate } from '@/components/DataGrid/utils';
import { IAddress, IUser } from '@/models';
import { KaseStatusList } from '@/pages/Kases/data';

const renderPriorityCell = (isPriority: boolean) =>
  isPriority ? <IcWarning color="red" /> : null;

const sortDates = (a?: any, b?: any) => {
  const dateA = a?.length ? moment(a[a.length - 1].approvedAt).toDate() : null;
  const dateB = b?.length ? moment(b[b.length - 1].approvedAt).toDate() : null;

  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;
  return dateA.getTime() - dateB.getTime();
};

const priority = (): Partial<GridColDef> => ({
  filterable: false,
  renderCell: (params) =>
    renderPriorityCell(params.row.priorityInfo?.isPriority),
  sortComparator: (a, b) =>
    (b?.priorityInfo?.isPriority ? 1 : 0) -
    (a?.priorityInfo?.isPriority ? 1 : 0),
});

const address = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row?.address?.normalized;
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'isAnyOf',
      'isNotEmpty',
      'isEmpty',
      'equals',
      'doesNotEqual',
    ]),
  ),
  valueGetter: (a: IAddress) => a?.normalized,
  sortComparator: (a, b) =>
    (a?.toLowerCase() || '').localeCompare(b?.toLowerCase() || ''),
});

const createdBy = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const { firstName, lastName } = params.row.createdBy as IUser;
    return `${firstName || ''} ${lastName || ''}`;
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
});

const approvedInfo = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const kaseStatus =
      KaseStatusList[params.row.status as keyof typeof KaseStatusList];

    return kaseStatus === KaseStatusList.APPROVED_BY_SUPERVISOR ||
      kaseStatus === KaseStatusList.ARCHIVED
      ? formatDate(params.row.approvedInfo?.slice(-1)[0]?.approvedAt || '')
      : '';
  },
  sortComparator: sortDates,
  filterOperators: getGridDateOperators(true),
});

const number = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const getDateColumn = (key: string): Partial<GridColDef> => ({
  filterOperators: getGridDateOperators(true).filter(
    excludeValues(['isNotEmpty', 'isEmpty']),
  ),
  renderCell: (params) => formatDate(params.row[key]),
});

const masterNameCount = (): Partial<GridColDef> => ({
  filterOperators: customNumericOperators.filter(
    excludeValues(['isAnyOf', 'isEmpty', 'isNotEmpty']),
  ),
});

const status = (): Partial<GridColDef> => ({
  filterable: false,
  renderCell: (params) => {
    const kaseStatus =
      KaseStatusList[params.value as keyof typeof KaseStatusList];
    return (
      <Chip
        label={kaseStatus.label}
        sx={{
          backgroundColor: kaseStatus.bgColor,
          color: kaseStatus.color,
          fontWeight: 500,
          fontSize: '12px',
          height: '25px',
          borderRadius: '6px',
        }}
      />
    );
  },
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void,
): Partial<GridColDef> => ({
  filterable: false,
  sortable: false,
  renderCell: (params) => (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ background: 'transparent' }}
    >
      <IconButton onClick={(e) => openMenu?.(e, params.row._id)}>
        <IcDotSpread />
      </IconButton>
    </Box>
  ),
});

export const kaseCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
) => {
  return {
    number,
    priority,
    address,
    createdBy,
    approvedInfo,
    createdAt: () => getDateColumn('createdAt'),
    updatedAt: () => getDateColumn('updatedAt'),
    status,
    actions: () => actions(openMenu),
    masterNameCount,
  };
};
