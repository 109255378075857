import { getGridStringOperators, GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/config';

export interface DataGridColSettings {
  field: string;
  headerName: string;
  visible: boolean;
  width?: number;
  isDetailToggle?: boolean;
}

export const createColumns = (
  columnSettings: DataGridColSettings[],
  columnMap: Record<string, () => Partial<GridColDef>>,
): GridColDef[] => {
  const commonProps = ({
    field,
    headerName,
    width,
  }: DataGridColSettings): Partial<GridColDef> => ({
    filterOperators: getGridStringOperators().filter(
      (item) => item.value !== 'isAnyOf',
    ),
    field,
    headerName,
    width,
    flex: width ? undefined : 1,
    sortable: true,
  });

  return columnSettings.map((item) => {
    const getColumn = columnMap[item.field];

    const specificProps = getColumn ? getColumn() : {};

    return {
      field: item.field,
      ...commonProps(item),
      ...specificProps,
    };
  }) as GridColDef[];
};

export const formatDate = (date: string | null) =>
  date ? moment(date).format(DATE_TIME_FORMAT) : '';

export const excludeValues =
  <T>(excludedValues: T[]) =>
  (item: { value: T }) =>
    !excludedValues.includes(item.value);

export const deepEqual = (
  obj1: { [x: string]: any } | null,
  obj2: { [x: string]: any } | null,
) => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const sortStrings = (a = '', b = '') => {
  return a.localeCompare(b, undefined, { sensitivity: 'base' });
};
