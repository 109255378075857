type Stage = 'local' | 'test' | 'dev' | 'staging' | 'prod';
const stage: Stage = (import.meta.env.VITE_APP_STAGE || 'local') as Stage;

const isProd: boolean = import.meta.env.PROD;
const baseURL: string = isProd
  ? import.meta.env.BASE_URL
  : import.meta.env.VITE_APP_DEV_BASE_URL;
enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
}
const defaultPageLimit = 20;
const defaultPropertiesLimit = 10;
const googleMapsApiKey = import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY || '';
const googleMapId = import.meta.env.VITE_APP_GOOGLE_MAP_ID;
const muiLicenseKey = import.meta.env.VITE_MUI_LICENSE_KEY;
const azureStorageAccountName = import.meta.env
  .VITE_APP_AZURE_STORAGE_ACCOUNT_NAME;
const azureConnectionString = import.meta.env.VITE_APP_AZURE_CONNECTION_STRING;
const useAzurite = import.meta.env.VITE_APP_USE_AZURITE;

const defaultMapCenter = {
  lng: -73.983,
  lat: 41.149,
};
const defaultGoogleMap = {
  center: defaultMapCenter,
  zoom: 11,
};

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const DATE_TIME_FORMAT_WITH_SECONDS = `${DATE_FORMAT} ${TIME_FORMAT}:ss`;

export {
  stage,
  ApiVersion,
  baseURL,
  defaultPageLimit,
  defaultPropertiesLimit,
  defaultMapCenter,
  defaultGoogleMap,
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_SECONDS,
  googleMapsApiKey,
  googleMapId,
  muiLicenseKey,
  azureStorageAccountName,
  azureConnectionString,
  useAzurite,
};
