import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { IcFlash } from '@/assets/images';
import { colors } from '@/theme/variables';

import { ModalState } from '../shared/modalState';

type ConfirmAssignmentModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  action: () => void;
};

const ConfirmAssignmentModalContent = ({
  toggleModal,
  action,
}: ConfirmAssignmentModalContentProps) => {
  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stack rowGap={2} flex={1} alignItems="center" fontSize={40} padding={5}>
        <IcFlash width={84} height={84} color={colors.error.main} />
        <Typography component="h3" variant="h6">
          You&apos;ll be assigned to the selected CFS. Do you want to proceed?
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" gap={2} paddingTop={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              toggleModal('confirmAssignment');
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              action();
              toggleModal('confirmAssignment');
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ConfirmAssignmentModalContent;
