import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Divider, Grid, Stack, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';

import { KaseApi, MasterIndexApi } from '@/api';
import {
  DisabledOverlay,
  FormRoot,
  RenderFormField,
  ViewCard,
  FormCard,
} from '@/components';
import { useFilesValidation } from '@/hooks';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import {
  IMasterNameOrganization,
  IMasterNameOrganizationForm,
  IMasterOrganizationRequest,
} from '@/models';
import { uploadFiles } from '@/services';
import { ConfirmTypes, useToastStore } from '@/store';
import { formatPhoneNumber, parsePhoneNumber } from '@/utils';

import { AdditionanInformationForm } from './AdditionanInformationForm';
import {
  InformationTypes,
  InformationValues,
  MasterNameKaseOrganizationFields,
  TabNamesNextPaths,
  TabNamesScreens,
  infoTypeMap,
} from '../data';
import { useMasterNamesContext } from '../MasterNamesContext';

type MasterNameKaseOrganizationFormProps = {
  hideHeader?: boolean;
  isMasterIndex?: boolean;
  onGoBack?: () => void;
};

export const MasterNameKaseOrganizationForm = ({
  hideHeader,
  isMasterIndex,
  ...props
}: MasterNameKaseOrganizationFormProps) => {
  const { kaseId, id: organizationId } = useParams();

  const { updateToast } = useToastStore();
  const theme = useTheme();
  const methods = useForm<IMasterNameOrganizationForm>({
    mode: 'all',
    defaultValues: {
      isVerified: false,
    },
  });

  const { currentMastername, linkedMasterNames } = useMasterNamesContext();
  const { updateCurrentScreen } = useTabLayoutContext();
  const { handleSubmit, watch } = methods;

  const uploadedFileItems = watch('files');
  const hasInvalidFiles = useFilesValidation(uploadedFileItems);

  const onGoBack = () => {
    if (props.onGoBack) {
      return props.onGoBack();
    }
    updateCurrentScreen(TabNamesScreens.NamesList);
  };

  const fetchMasterName = async () => {
    if (organizationId) {
      return MasterIndexApi.getMasterOrganization(organizationId);
    }
  };

  const getItemValue = (item: Record<string, any>) => {
    const allValues = Object.values(item);
    const allKeys = Object.keys(item);

    if (allKeys.includes('phoneNumber')) {
      item.phoneNumber = parsePhoneNumber(item.phoneNumber);
    }
    return allValues.length > 1 ? item : allValues[0];
  };

  const onSubmit = async (values: IMasterNameOrganizationForm) => {
    if (!kaseId && !isMasterIndex) {
      return updateToast({
        type: ConfirmTypes.ERROR,
        open: true,
        message: 'invalid case id',
      });
    }

    const formattedInfoTypeData = Object.values(InformationTypes).reduce(
      (accumulatedInfoType, infoType) => {
        const typeItems = values.informationType.find(
          (valueItem) => valueItem.name === infoType,
        );
        return {
          ...accumulatedInfoType,
          [infoType]: typeItems
            ? typeItems?.items.map((item: Record<string, any>) =>
                getItemValue(item),
              )
            : [],
        };
      },
      {},
    );

    try {
      if (values.files && values.files.length) {
        const uploadedFiles = await uploadFiles(values.files);

        if (!uploadedFiles) return;

        values.files = uploadedFiles;
      }

      const data: IMasterOrganizationRequest = {
        ...values,
        ...formattedInfoTypeData,
        address: values.address?._id,
      };
      let result: AxiosResponse<IMasterNameOrganization, any>;
      if (isMasterIndex) {
        if (data._id) {
          result = await MasterIndexApi.updateMasterOrganization(data);
        } else {
          result = await MasterIndexApi.createMasterOrganization(data);
        }
      } else {
        result = await KaseApi.saveMasterOrganization(data);
        if (!result.data?._id) {
          return updateToast({
            type: ConfirmTypes.ERROR,
            open: true,
            message: `Organization NOT SAVED`,
          });
        }
        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: `Organization saved successfully`,
        });
        const alreadyLined = linkedMasterNames.find(
          (linked) => linked.masterName._id === result.data._id,
        );
        if (!alreadyLined) {
          await KaseApi.addKaseMasterName(String(kaseId), result.data._id);
        }
      }

      onGoBack();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
    return values;
  };

  const getInformationType = (masterName: IMasterNameOrganization) => {
    const informationType = Object.values(InformationTypes).reduce(
      (acc, infoType) => {
        const typeItems = masterName[infoType];
        if (!typeItems?.length) {
          return [...acc];
        }

        const items =
          typeof typeItems[0] === 'object'
            ? [
                ...typeItems.map((typeItem) =>
                  Object.entries(typeItem).reduce(
                    (accumulatedTypes, [key, value]) => {
                      return key === 'phoneNumber'
                        ? {
                            ...accumulatedTypes,
                            [key]: parsePhoneNumber(value),
                          }
                        : { ...accumulatedTypes, [key]: value };
                    },
                    {},
                  ),
                ),
              ]
            : formatFieldNames(typeItems as string[], infoType);

        return typeItems?.length
          ? [
              ...acc,
              {
                name: infoType,
                items,
              },
            ]
          : [...acc];
      },
      [] as Array<Record<string, any>>,
    );
    return informationType;
  };

  const formatFieldNames = (
    typeItems: string[],
    infoType: InformationValues,
  ) => {
    if (!typeItems || typeItems.length === 0) return [];

    return typeItems.map((item) => {
      const itemTypeFields = infoTypeMap[infoType]?.InputFields;
      if (!itemTypeFields || itemTypeFields.length === 0) return {};

      const fieldName = itemTypeFields[0]?.name;
      if (fieldName?.includes('phoneNumber')) {
        item = formatPhoneNumber(item);
      }
      return fieldName ? { [fieldName]: item } : {};
    });
  };

  useEffect(() => {
    const resetForm = (masterName: IMasterNameOrganization) => {
      methods.reset({
        ...masterName,
        phoneNumbers: masterName.phoneNumbers?.map((item) => ({
          phoneNumber: parsePhoneNumber(item.phoneNumber),
          type: item.type,
        })),
        informationType: getInformationType(masterName),
      });
    };

    if (!currentMastername && !organizationId) return;

    if (isMasterIndex && organizationId) {
      fetchMasterName()
        .then((response) => {
          if (!response) return;
          resetForm(response.data as IMasterNameOrganization);
        })
        .catch((error) => {
          updateToast({ open: true, message: error.message });
        });
    } else {
      resetForm(currentMastername?.masterName as IMasterNameOrganization);
    }
  }, [currentMastername, isMasterIndex, organizationId]);
  return (
    <>
      <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterAddName}>
        {!hideHeader && (
          <PageBackHeader
            title={`${currentMastername ? 'Edit' : 'Add'} organization details`}
            goBack={onGoBack}
            style={{
              background: theme.palette.background.default,
              fontWeight: 500,
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem',
            }}
          />
        )}
        <FormProvider {...methods}>
          <FormRoot
            id={isMasterIndex ? 'master-index-form' : undefined}
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              pb: 0,
              '.p-kase-edit &': {
                padding: '20px',
                mt: 0,
                background: theme.palette.background.paper,
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              },
            }}
          >
            {MasterNameKaseOrganizationFields.map(({ key, title, items }) => {
              const CardComponent = isMasterIndex ? FormCard : ViewCard;

              return (
                <CardComponent key={key} title={title} className="grey-title">
                  {items.map(({ grid, ...rest }: any, index: number) => {
                    return (
                      <Grid
                        key={`${key} - ${index}`}
                        xs={12}
                        sm={6}
                        md={3}
                        {...grid}
                        {...rest}
                        item
                      >
                        {rest.name === 'informationTypes' ? (
                          <AdditionanInformationForm />
                        ) : (
                          <RenderFormField {...rest} />
                        )}
                      </Grid>
                    );
                  })}
                </CardComponent>
              );
            })}

            {!isMasterIndex && (
              <Stack sx={{ width: '100%' }}>
                <Divider sx={{ my: 2, width: '100%' }} />
                <Stack
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{
                    my: 3,
                    '.p-kase-edit &': { p: 2 },
                  }}
                >
                  <Button
                    color="inherit"
                    variant="outlined"
                    sx={{ px: 4, mr: 2 }}
                    onClick={onGoBack}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={hasInvalidFiles}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            )}
          </FormRoot>
        </FormProvider>
      </TabAnimationWrapper>
      {!isMasterIndex && <DisabledOverlay />}
    </>
  );
};
