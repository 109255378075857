import { ReactElement } from 'react';

import { Box } from '@mui/material';
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import { EllipsisTooltip } from '@/components';
import {
  excludeValues,
  formatDate,
  sortStrings,
} from '@/components/DataGrid/utils';
import { ASSIGNMENT_STATUS } from '@/constants';
import { AssignmentStatus, IAddress, IUser } from '@/models';
import { getAddressLocation } from '@/services';

const number = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
});

const createdBy = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const { firstName, lastName } = params.row.createdBy as IUser;
    return `${firstName || ''} ${lastName || ''}`;
  },
  sortComparator: (a, b) => sortStrings(a?.fullName, b?.fullName),
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
});

const createdAt = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    formatDate(params.row.reporter.reportTime || params.row.createdAt),
  filterOperators: getGridDateOperators(true).filter(
    excludeValues(['isNotEmpty', 'isEmpty']),
  ),
});

const incidentCodes = (): Partial<GridColDef> => ({
  renderCell: (params: any) => {
    return (
      <Box
        sx={{
          width: params.colDef.width,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <EllipsisTooltip style={{ fontSize: '0.875rem' }}>
          {params.value || ''}
        </EllipsisTooltip>
      </Box>
    );
  },
  minWidth: 260,
  filterable: false,
});

const addressInfo = (): Partial<GridColDef> => ({
  renderCell: (params) => getAddressLocation(params.row.addressInfo?.address),
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'isAnyOf',
      'isNotEmpty',
      'isEmpty',
      'equals',
      'doesNotEqual',
    ]),
  ),
  valueGetter: (value: { address: IAddress }) => {
    return getAddressLocation(value?.address);
  },
  sortComparator: (a, b) => {
    return sortStrings(a, b);
  },
  minWidth: 260,
});

const shortDescription = (): Partial<GridColDef> => ({
  renderCell: (params) => params.formattedValue,
  filterOperators: getGridStringOperators().filter(
    excludeValues(['equals', 'doesNotEqual', 'isAnyOf']),
  ),
  sortComparator: (a, b) => {
    return sortStrings(a, b);
  },
});

const cfsStatus = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    if (params.row?.isClosed) {
      return 'Closed';
    }

    if (params.row?.fireControlled) {
      return 'Fire Controlled';
    }

    if (
      params.row?.isReadyToDispatch &&
      params.row?.assignmentStatus === AssignmentStatus.NEW
    ) {
      return 'Ready for dispatch';
    }

    const status = params?.row?.assignmentStatus as AssignmentStatus;
    return status ? ASSIGNMENT_STATUS[status]?.label : null;
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues(['equals', 'doesNotEqual', 'isAnyOf']),
  ),
  sortComparator: (a, b) => sortStrings(a?.normalized, b?.normalized),
});

const action = (
  actionNode?: (params: GridRenderCellParams) => ReactElement,
): Partial<GridColDef> => ({
  filterable: false,
  sortable: false,
  renderCell: actionNode,
});

export const cfsCols = (
  actionNode?: (params: GridRenderCellParams) => ReactElement,
) => {
  return {
    number,
    createdBy,
    createdAt,
    incidentCodes,
    addressInfo,
    shortDescription,
    cfsStatus,
    action: () => action(actionNode),
  };
};
