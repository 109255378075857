export const IncomingCallDefaultValues = {
  reporter: {
    callerPhoneNumber: '',
  },
};

export enum IncomingCallEvent {
  CREATED = 'CREATED',
  ANSWERED = 'ANSWERED',
}
