import { ReactElement, useMemo } from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  createColumns,
  DataGridColSettings,
} from '@/components/DataGrid/utils';
import { cautionCols } from '@/components/Modals/CautionModal/CautionCols';
import { masterNamesCols } from '@/layouts/TabLayout/MasterNames/MasterNameCols';
import { masterNameNoteCols } from '@/layouts/TabLayout/MasterNames/MasterNameNotesCols';
import { sharedVehicleCols } from '@/layouts/TabLayout/MasterVehicles/SharedVehicleCols';
import { IUser } from '@/models';
import { cfsCols } from '@/pages/CFS/List/CFSCols';
import { agencyEmployeeCols } from '@/pages/Employees/AgencyEmployeeCols';
import { agencyEquipmentCols } from '@/pages/Equipments/AgencyEquipmentCols';
import { agencyInventoryLocationCols } from '@/pages/InventoryLocations/AgencyInventoryLocationsCols';
import { kaseArrestCols } from '@/pages/Kases/KaseArrests/KaseArrestCols';
import { kaseCols } from '@/pages/Kases/KaseList/KaseCols';
import { kasePropertyCols } from '@/pages/Kases/KaseProperty/KasePropertyCols';
import { masterAddressCols } from '@/pages/MasterIndex/Address/MasterAddressCols';
import { masterLogsCols } from '@/pages/MasterIndex/Logs/MasterLogsCols';
import { masterOrganizationCols } from '@/pages/MasterIndex/Organizations/MasterOrganizationsCols';
import { masterPersonsCols } from '@/pages/MasterIndex/Persons/MasterPersonsCols';
import { masterPropertyCols } from '@/pages/MasterIndex/Properties/MasterPropertiesCols';
import { masterVehicleCols } from '@/pages/MasterIndex/Vehicles/MasterVehiclesCols';
import { taskCols } from '@/pages/Tasks/TaskCols';
import { agencyUnitCols } from '@/pages/Units/AgencyUnitCols';
import { agencyVehicleCols } from '@/pages/Vehicles/AgencyVehicleCols';
import { agencyVendorCols } from '@/pages/Vendors/AgencyVendorCols';

type ColumnGenerator = () => Partial<GridColDef>;

type ColumnMap = Record<string, ColumnGenerator>;

type GridType =
  | 'kase'
  | 'kaseVehicle'
  | 'kaseArrests'
  | 'kaseProperties'
  | 'cfs'
  | 'caution'
  | 'task'
  | 'masterNames'
  | 'masterNamesNotes'
  | 'masterPersons'
  | 'masterOrganizations'
  | 'masterVehicles'
  | 'masterProperties'
  | 'masterAddress'
  | 'masterLogs'
  | 'agencyEmployee'
  | 'agencyVehicle'
  | 'agencyEquipment'
  | 'agencyUnit'
  | 'agencyVendor'
  | 'agencyInventoryLocation';

interface GridConfig {
  type: GridType;
  columnSettings: DataGridColSettings[];
  actionsNode?: (params: GridRenderCellParams) => ReactElement;
  navigate?: (path: string) => void;
  onDelete?: (row: any) => void;
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void;
  onView?: (row: any) => void;
  onEdit?: (row: any) => void;
  isReadOnly?: boolean;
  onSetInvolvement?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: any,
  ) => void;
  onGotoNotes?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: any,
  ) => void;
  isKase?: boolean;
  user?: IUser | null;
}

export const useDataGridColumns = ({
  type,
  columnSettings,
  actionsNode,
  navigate,
  onDelete,
  openMenu,
  onView,
  onEdit,
  isReadOnly,
  onSetInvolvement,
  onGotoNotes,
  isKase,
  user,
}: GridConfig): GridColDef[] => {
  const gridSpecificMaps: Record<GridType, ColumnMap> = {
    kase: kaseCols(openMenu),
    kaseVehicle: sharedVehicleCols(openMenu),
    cfs: cfsCols(actionsNode),
    caution: cautionCols(onDelete),
    task: taskCols(openMenu),
    masterNames: masterNamesCols(
      openMenu,
      onSetInvolvement,
      onGotoNotes,
      isKase,
    ),
    masterNamesNotes: masterNameNoteCols(openMenu, user),
    masterPersons: masterPersonsCols(navigate, onDelete),
    masterOrganizations: masterOrganizationCols(navigate, onDelete),
    masterVehicles: masterVehicleCols(navigate, onDelete),
    masterProperties: masterPropertyCols(navigate),
    masterAddress: masterAddressCols(navigate, onDelete),
    masterLogs: masterLogsCols(),
    kaseArrests: kaseArrestCols(onView, onEdit, isReadOnly),
    kaseProperties: kasePropertyCols(openMenu),
    agencyEmployee: agencyEmployeeCols(openMenu),
    agencyVehicle: agencyVehicleCols(openMenu),
    agencyEquipment: agencyEquipmentCols(openMenu),
    agencyUnit: agencyUnitCols(openMenu),
    agencyVendor: agencyVendorCols(openMenu),
    agencyInventoryLocation: agencyInventoryLocationCols(openMenu),
  };

  return useMemo(
    () => createColumns(columnSettings, gridSpecificMaps[type]),
    [
      columnSettings,
      type,
      navigate,
      onDelete,
      openMenu,
      onView,
      onEdit,
      isReadOnly,
    ],
  );
};
