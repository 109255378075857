import { FC, useEffect, useRef, useState } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { CfsApi } from '@/api';
import { IAdditionalInfo } from '@/models';

import { AdditionalInfoInput } from './AdditionalInfoInput';
import { AdditionalInfoItem } from './AdditionalInfoItem';
import { AdditionalInfoList } from './styles';

interface IAdditionalInfoProps {
  cfsId?: string;
  additionalInfoList?: IAdditionalInfo[];
  onSubmit: (value: string, isEditing?: boolean, editingValue?: string) => void;
  classes?: { additionalInfoList: string };
  setNewAdditionalInfo: (data: IAdditionalInfo[]) => void;
  isFirstResponderView?: boolean;
  disableSendMessage?: boolean;
}

export const AdditionalInfoForm: FC<IAdditionalInfoProps> = ({
  cfsId,
  additionalInfoList = [],
  onSubmit,
  classes,
  setNewAdditionalInfo,
  isFirstResponderView,
  disableSendMessage,
}) => {
  const infoListBottomRef = useRef<HTMLDivElement>(null);
  const [editingValue, setEditingValue] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Display the last message into the view port as default
  useEffect(() => {
    if (infoListBottomRef.current) {
      const observer = new ResizeObserver(() => {
        infoListBottomRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });

      observer.observe(infoListBottomRef.current);
      return () => observer.disconnect();
    }
  }, [additionalInfoList.length]);

  const updateAdditionalInfo = () => {
    CfsApi.update(cfsId as string, { additionalInfo: additionalInfoList }).then(
      (res) => {
        if (res.status === 200) {
          setNewAdditionalInfo(res?.data?.additionalInfo);
        }
      },
    );
  };

  const handleEditAdditionalInfo = (additionalInfo: IAdditionalInfo) => {
    setEditingValue(additionalInfo.description);
    setIsEditing(true);
  };

  return (
    <Stack rowGap={2} flex={1} overflow="auto">
      <AdditionalInfoList
        className={classes?.additionalInfoList}
        sx={
          isFirstResponderView
            ? { maxHeight: 'calc(100vh - 194px - 375px)' }
            : { maxHeight: 'calc(100vh - 194px - 598px)' }
        }
      >
        {additionalInfoList.length ? (
          <Box className="content">
            {additionalInfoList.map((item, index) => (
              <AdditionalInfoItem
                cfsId={cfsId}
                key={`${item.createdBy?._id}-${index}`}
                additionalInfo={item}
                updateAdditionalInfos={updateAdditionalInfo}
                onEdit={handleEditAdditionalInfo}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body2">No message to display</Typography>
          </Box>
        )}
        {additionalInfoList.length > 0 && <Box ref={infoListBottomRef} />}
      </AdditionalInfoList>

      <Divider />
      <Box sx={{ flex: 'none' }}>
        {!disableSendMessage && (
          <AdditionalInfoInput
            placeholder={
              isFirstResponderView ? 'Enter message' : 'Enter additional info'
            }
            onSubmit={(value) => {
              onSubmit(value, isEditing, editingValue as string);
              setIsEditing(false);
              setEditingValue(null);
            }}
            initialValue={editingValue as string}
            isEditing={isEditing}
            isFirstResponderView={isFirstResponderView}
          />
        )}
      </Box>
    </Stack>
  );
};
