import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Badge, Button } from '@mui/material';

import { CautionApi } from '@/api';
import { AppModal } from '@/components';
import { WithLoaderProps, withLoader } from '@/hocs';
import { ICaution, ICautionForm } from '@/models';
import { useToastStore } from '@/store';

import CautionDataGrid from './components/CautionDataGrid';
import { CautionRoot } from './styles';

interface CautionModalProps extends WithLoaderProps {
  addressId?: string;
}

interface CautionForm extends ICautionForm {
  customCautionType: string;
}

const CautionModal: FC<CautionModalProps> = ({ addressId }) => {
  const { updateToast } = useToastStore();
  const [cautions, setCautions] = useState<ICaution[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const methods = useForm<CautionForm>({
    mode: 'all',
  });
  const { reset } = methods;

  useEffect(() => {
    fetchData();
  }, [addressId]);

  const fetchData = () => {
    fetchCautions();
  };

  const fetchCautions = async () => {
    try {
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        filter: JSON.stringify({ address: addressId }),
      };
      const res = await CautionApi.getCautions(params);
      setCautions(res.data.results);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    }
  };

  const onClose = () => {
    setOpenModal(false);
    reset();
  };

  return (
    <CautionRoot>
      <Button color="error" onClick={() => setOpenModal(true)}>
        View cautions
        {!!cautions.length && (
          <Badge badgeContent={cautions.length} sx={{ ml: 2, mr: 1 }} />
        )}
      </Button>
      <AppModal open={openModal} title="View cautions" onClose={onClose}>
        <CautionDataGrid addressId={addressId} />
      </AppModal>
    </CautionRoot>
  );
};

export default withLoader(CautionModal);
