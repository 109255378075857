import {
  CSSObject,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { sizes } from '@/theme/variables';

const openedMixin = (theme: Theme): CSSObject => ({
  width: sizes.sidebarOpen,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: sizes.sidebarClosed,
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: sizes.sidebarOpen,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.error.main
      : theme.palette.sideBar.main,
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.error.main
        : theme.palette.sideBar.main,
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.sideBar.main,
  '&.active': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.white,
  },
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.sideBar.main,
  '&.active': {
    color: theme.palette.common.white,
  },
  '& .MuiListItemText-primary': {
    fontSize: 15,
    fontWeight: 500,
  },
}));
