import { TabItem } from '@/layouts/TabLayout';
import { AssignmentStatus } from '@/models';
import { colors } from '@/theme/variables';

export const ASSIGNMENT_STATUS = {
  [AssignmentStatus.NEW]: {
    label: 'New',
    color: colors.error.main,
  },
  [AssignmentStatus.PARTIAL_ASSIGNED]: {
    label: 'Partially assigned',
    color: colors.secondary.main,
  },
  [AssignmentStatus.ASSIGNED]: {
    label: 'Assigned',
    color: colors.primary.main,
  },
};

export const SEVERITY_STATUS = {
  HIGH: colors.error.main,
  MEDIUM: colors.warning.main,
  LOW: colors.secondary.main,
};

export const cfsTabs: TabItem[] = [
  {
    label: 'Names',
    path: 'names',
  },
  {
    label: 'Vehicles',
    path: 'vehicles',
  },
  {
    label: '911 information',
    path: '911-information',
  },
  {
    label: 'Recommended procedure',
    path: 'procedure',
  },
  {
    label: 'Media',
    path: 'media',
  },
  {
    label: 'Logs',
    path: 'history',
  },
];
