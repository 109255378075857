import { Theme } from '@mui/material';
import moment from 'moment';

import { IViewCard } from '@/components';
import { DataGridColSettings } from '@/components/DataGrid/utils';
import { Gender, genderInput, phoneNumberInput } from '@/constants/form_inputs';
import { BloodType, IUserForm, Role } from '@/models';
import { EmployeeDetailsTabRoutes } from '@/routes/RoutePaths';

export const EmployeeTableColumns = [
  'First name',
  'Middle name',
  'Last name',
  'Email',
  'Phone number',
  'Title',
  'Rank',
  'Badge number',
  'Department',
  '',
];

export const EmployeeViewTabs = [
  { label: 'Employee Details', path: EmployeeDetailsTabRoutes.Information },
  { label: 'Device History', path: EmployeeDetailsTabRoutes.Devices },
];

export const employeeDevicesCols = [
  {
    key: 'model',
    label: 'Device type',
  },
  {
    key: 'platform',
    label: 'OS',
  },
  {
    key: 'manufacturer',
    label: 'Device name',
  },
  {
    key: 'lastActive',
    label: 'Last active',
  },
  {
    key: 'appName',
    label: 'Application',
  },
  {
    key: 'appVersion',
    label: 'Application version',
  },
  {
    key: 'deviceStatus',
    label: 'Status',
  },
];

export const employeeDataGridCols: DataGridColSettings[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 180,
    visible: true,
  },
  {
    field: 'middleName',
    headerName: 'Middle Name',
    width: 180,
    visible: true,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 180,
    visible: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    visible: true,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 200,
    visible: true,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 180,
    visible: true,
  },
  {
    field: 'rank',
    headerName: 'Rank',
    width: 180,
    visible: true,
  },
  {
    field: 'badgeNumber',
    headerName: 'Badge Number',
    width: 180,
    visible: true,
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 180,
    visible: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    visible: true,
  },
];

export const EmployeeFormFields = [
  {
    title: 'Employee information',
    items: [
      {
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        rules: {
          required: 'This field is required.',
          minLength: {
            value: 2,
            message: 'Name should be more than 2 characters.',
          },
          maxLength: {
            value: 200,
            message: 'Name should be less than 200 characters.',
          },
          pattern: {
            value: /^[A-Za-z\s.'-]*$/,
            message: 'Invalid name',
          },
        },
      },
      {
        name: 'middleName',
        type: 'text',
        label: 'Middle Name',
        rules: {
          maxLength: {
            value: 50,
            message: 'Middle Name should be less than 50 characters.',
          },
          pattern: {
            value: /^[A-Za-z\s.'-]*$/,
            message: 'Invalid name',
          },
        },
      },
      {
        name: 'lastName',
        type: 'text',
        label: 'Last Name',
        rules: {
          required: 'This field is required.',
          minLength: {
            value: 3,
            message: 'Name should be more than 3 characters.',
          },
          maxLength: {
            value: 200,
            message: 'Name should be less than 200 characters.',
          },
          pattern: {
            value: /^[A-Za-z\s.'-]*$/,
            message: 'Invalid name',
          },
        },
      },
      {
        ...genderInput,
        name: 'profile.gender',
      },
      {
        name: 'profile.birthday',
        type: 'date',
        label: 'Date of birth',
        minDate: moment('01-01-1900'),
        maxDate: moment(),
      },
      {
        name: 'profile.bloodType',
        type: 'autocomplete',
        label: 'Blood type',
        items: [
          {
            label: 'A+',
            value: BloodType.A_POSITIVE,
          },
          {
            label: 'A-',
            value: BloodType.A_NEGATIVE,
          },
          {
            label: 'B+',
            value: BloodType.B_POSITIVE,
          },
          {
            label: 'B-',
            value: BloodType.B_NEGATIVE,
          },
          {
            label: 'O+',
            value: BloodType.O_POSITIVE,
          },
          {
            label: 'O-',
            value: BloodType.O_NEGATIVE,
          },
          {
            label: 'AB+',
            value: BloodType.AB_POSITIVE,
          },
          {
            label: 'AB-',
            value: BloodType.AB_NEGATIVE,
          },
          {
            label: 'Unknown',
            value: BloodType.UNKNOWN,
          },
        ],
        rules: {
          required: 'This field is required.',
        },
      },
      {
        ...phoneNumberInput,
        rules: {
          ...phoneNumberInput.rules,
        },
      },
      {
        name: 'email',
        type: 'email',
        label: 'Email address',
        rules: {
          required: 'This field is required.',
          pattern: {
            value:
              /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, // eslint-disable-line
            message: 'Invalid email',
          },
        },
      },
    ],
  },
  {
    title: 'Address information',
    name: 'profile',
    items: [
      {
        name: 'address',
        type: 'address',
        label: 'Address',
        grid: {
          md: 6,
        },
      },
    ],
  },
  {
    title: 'Emergency contact',
    name: 'profile.emergencyContact',
    items: [
      {
        name: 'name',
        type: 'text',
        label: 'Emergency contact name',
        rules: {
          minLength: {
            value: 3,
            message: 'Name should be more than 3 characters.',
          },
          maxLength: {
            value: 200,
            message: 'Name should be less than 200 characters.',
          },
          pattern: {
            value: /^[A-Za-z\s]*$/,
            message: 'Invalid name',
          },
        },
      },
      {
        ...phoneNumberInput,
        name: 'phoneNumber',
        label: 'Emergency contact phone',
        rules: {
          ...phoneNumberInput.rules,
        },
      },
    ],
  },
  {
    title: 'Employment information',
    name: 'profile.employmentInformation',
    items: [
      {
        name: 'title',
        type: 'text',
        label: 'Title',
        grid: {
          sm: 12,
          md: 3,
        },
        rules: {
          required: 'This field is required.',
        },
      },
      {
        name: 'supervisor',
        type: 'autocomplete',
        label: 'Supervisor',
        clearable: true,
        grid: {
          sm: 12,
          md: 3,
          sx: (theme: Theme) => ({
            [theme.breakpoints.up('md')]: {
              marginRight: '50%',
            },
          }),
        },
      },
      {
        name: 'rank',
        type: 'autocomplete',
        label: 'Rank',
      },
      {
        name: 'badgeNumber',
        type: 'text',
        label: 'Badge number',
        rules: {
          required: 'This field is required.',
        },
      },
      {
        name: 'department',
        type: 'autocomplete',
        label: 'Department',
      },
      {
        name: 'seniority',
        type: 'number',
        label: 'Seniority',
        rules: {
          required: 'This field is required.',
          min: {
            value: 0,
            message: 'Seniority must be at least 0.',
          },
          pattern: {
            value: /^[0-9]+$/,
            message: 'The value must be a whole number',
          },
        },
      },
    ],
  },
  {
    title: 'Additional Information',
    items: [
      {
        name: 'gpsTracker',
        type: 'select',
        label: 'GPS Tracking Equipment',
      },
    ],
  },
];

export const EmployeeViewFields: IViewCard[] = [
  {
    title: 'Employee information',
    key: 'employeeInfo',
    size: { md: 12 },

    items: [
      {
        value: 'firstName',
        label: 'First Name',
      },
      {
        value: 'middleName',
        label: 'Middle Name',
      },
      {
        value: 'lastName',
        label: 'Last Name',
      },
      {
        label: 'Gender',
        value: 'profile.gender',
      },
      {
        value: 'profile.birthday',
        label: 'Date of birth',
      },
      {
        value: 'profile.bloodType',
        label: 'Blood type',
      },
      {
        value: 'phoneNumber',
        label: 'Phone number',
      },
      {
        value: 'email',
        label: 'Email address',
      },
    ],
  },
  {
    title: 'Address information',
    name: 'profile',
    key: 'profile.address',
    size: { md: 6 },
    items: [
      {
        value: 'address',
        label: 'Address ',
      },
    ],
  },
  {
    title: 'Emergency contact',
    name: 'profile.emergencyContact',
    key: 'emergencyContact',
    size: { md: 6 },
    items: [
      {
        value: 'name',
        label: 'Emergency contact name',
      },
      {
        value: 'phoneNumber',
        label: 'Emergency contact phone',
      },
    ],
  },
  {
    title: 'Employment information',
    name: 'profile.employmentInformation',
    key: 'employmentInfo',
    size: { md: 12 },
    items: [
      {
        value: 'title',
        label: 'Title',
      },
      {
        value: 'supervisor.fullName',
        label: 'Supervisor',
      },
      {
        value: 'rank.name',
        label: 'Rank',
      },
      {
        value: 'badgeNumber',
        label: 'Badge number',
      },
      {
        value: 'department.name',
        label: 'Department',
      },
      {
        value: 'seniority',
        label: 'Seniority',
      },
    ],
  },
  {
    title: 'Additional Information',
    key: 'additional_info',
    size: { md: 12 },
    items: [
      {
        value: 'gpsTracker.name',
        label: 'GPS Tracking Equipment',
      },
    ],
  },
  {
    title: 'Certification',
    name: 'profile',
    key: 'certification',
    size: { md: 12 },
    items: [
      {
        value: 'certifications',
        label: 'Certification',
      },
    ],
  },
];

export const CertificateFields = [
  {
    name: 'name',
    type: 'text',
    label: 'Certification',
    rules: {
      required: 'This field is required.',
    },
  },
  {
    name: 'expireDate',
    type: 'date',
    label: 'Expiration date',
    minDate: moment().add(1, 'days'),
  },
  {
    name: 'file.name',
    type: 'text',
    label: 'Certificate file',
    readOnly: true,
    rules: {
      required: 'File is required.',
    },
  },
];

export const EmployeeDefaultValues: IUserForm = {
  email: '',
  phoneNumber: '',
  firstName: '',
  middleName: '',
  lastName: '',
  profile: {
    gender: Gender.UNKNOWN,
    birthday: '',
    bloodType: BloodType.UNKNOWN,
    emergencyContact: {
      name: '',
      phoneNumber: '',
    },
    certifications: [],
    employmentInformation: {
      title: '',
      badgeNumber: '',
      seniority: 0,
    },
  },
  roles: [],
};

export const AgencyRoleItems = [
  {
    label: 'Employee',
    value: Role.EMPLOYEE,
  },
  {
    label: 'Agency admin',
    value: Role.AGENCY_ADMIN,
  },
  {
    label: 'First responder',
    value: Role.FIRST_RESPONDER,
  },
  // POLICE, KASE_APPROVER roles exist only for POLICE type agency
  {
    label: 'Police',
    value: Role.POLICE,
  },
  {
    label: 'Case approver',
    value: Role.KASE_APPROVER,
  },
  {
    label: 'Evidence collector',
    value: Role.EVIDENCE_COLLECTOR,
  },
  {
    label: 'Evidence custodian',
    value: Role.EVIDENCE_CUSTODIAN,
  },
  {
    label: 'Evidence transporter',
    value: Role.EVIDENCE_TRANSPORTER,
  },
];

export const AccountRoleItems = [
  {
    label: 'Account admin',
    value: Role.ACCOUNT_ADMIN,
  },
  {
    label: 'Viewer',
    value: Role.VIEWER,
  },
  {
    label: 'Dispatcher',
    value: Role.DISPATCHER,
  },
];
