import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { IcBell } from '@/assets/images';
import { colors } from '@/theme/variables';

import { ModalState } from '../shared/modalState';

type MoveToQueueAndSelfAssignMocalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  action: () => void;
};

const MoveToQueueAndSelfAssignMocalContent = ({
  toggleModal,
  action,
}: MoveToQueueAndSelfAssignMocalContentProps) => {
  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stack
        rowGap={2}
        flex={1}
        alignItems="center"
        fontSize={40}
        padding={5}
        textAlign="center"
      >
        <IcBell width={84} height={84} color={colors.error.main} />
        <Typography component="h3" variant="h6">
          Moving your current active CFS to the queue and self-assigning the
          selected CFS. Do you want to proceed?
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" gap={2} paddingTop={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              toggleModal('moveToQueueAndAssign');
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              action();
              toggleModal('moveToQueueAndAssign');
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MoveToQueueAndSelfAssignMocalContent;
