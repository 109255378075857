import { Box, IconButton } from '@mui/material';
import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';

import { IcMinus, IcPlus } from '@/assets/images';

import { useStyles } from './sharedMapStyles';

export type ZoomControlProps = {
  position?: ControlPosition;
};

export const ZoomControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
}: ZoomControlProps) => {
  const map = useMap();
  if (!map) return null;

  const updateZoom = (isZoomIn?: boolean) => {
    const currentZoom = map.getZoom() || 1;
    const newZoomLevel = Math.min(
      22,
      Math.max(0, isZoomIn ? currentZoom + 1 : currentZoom - 1),
    );
    map.setZoom(newZoomLevel);
  };

  const classes = useStyles();

  return (
    <MapControl position={position}>
      <Box sx={{ mr: 1, mb: 1 }}>
        <IconButton
          className={`${classes.controllButton} ${classes.topButtonGroup}`}
          onClick={() => updateZoom(true)}
        >
          <IcPlus width={24} height={24} />
        </IconButton>
        <IconButton
          className={`${classes.controllButton} ${classes.bottomButtonGroup}`}
          onClick={() => updateZoom(false)}
        >
          <IcMinus width={24} height={24} />
        </IconButton>
      </Box>
    </MapControl>
  );
};
