import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MasterNameApi } from '@/api';
import { DisabledOverlay } from '@/components/DisabledOverlay';
import { useKaseContext } from '@/hooks';
import { useTabLayoutContext } from '@/layouts/TabLayout';
import { IMasterNamePerson } from '@/models';
import { useToastStore } from '@/store';
import { parsePhoneNumber } from '@/utils';

import { useMasterNamesContext } from '..';
import { MasterAddDriverLicense } from './MasterAddDriverLicense';
import { MasterLookupName } from './MasterLookupName';
import { MasterNameDriverLicense } from './MasterNameDriverLicense';
import { MasterNameDriverLicenseView } from './MasterNameDriverLicenseView';
import MasterNameForm from './MasterNameForm';
import { TabNamesScreens } from '../data';

type MasterAddNameDetailsProps = {
  isMasterIndex?: boolean;
  hideHeader?: boolean;
  onGoBack?: () => void;
};

export const MasterAddNameDetails = ({
  isMasterIndex,
  hideHeader,
  onGoBack,
}: MasterAddNameDetailsProps) => {
  const { updateToast } = useToastStore();
  const { currentMastername, setCurrentMastername } = useMasterNamesContext();
  const { currentScreen } = useTabLayoutContext();
  const { kase } = useKaseContext();
  const { id: personId } = useParams();

  const methods = useForm<IMasterNamePerson & { ssn_prev: string }>({
    defaultValues: {
      firstName: '',
      lastName: '',
      isVerified: false,
      physicalCharacteristics: {
        others: [],
      },
    },
    mode: 'all',
  });

  const fetchMasterNameData = async (id: string) => {
    try {
      const res = await MasterNameApi.getMasterName(id);

      const formData = {
        ...res.data,
        employerInfo: {
          ...res.data.employerInfo,
          phoneNumber: parsePhoneNumber(res.data.employerInfo?.phoneNumber),
        },
        phoneNumbers: res.data.phoneNumbers?.map(({ type, phoneNumber }) => ({
          type,
          phoneNumber: parsePhoneNumber(phoneNumber),
        })),
        ssn_prev: res.data.ssn,
      };

      methods.reset(formData);

      // Set the currentMastername immediately with the fetched data
      setCurrentMastername({
        masterName: res.data,
        relationship: {
          involvement: undefined,
          behavior: 'N/A',
        },
        kaseId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  useEffect(() => {
    if (personId) {
      fetchMasterNameData(personId);
    }
  }, [personId]);

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case TabNamesScreens.MasterLookupName:
        return <MasterLookupName />;
      case TabNamesScreens.MasterNameDriverLicense:
        return currentMastername ? <MasterNameDriverLicense /> : null;
      case TabNamesScreens.MasterAddDriverLicense:
        return currentMastername ? <MasterAddDriverLicense /> : null;
      case TabNamesScreens.MasterNameDriverLicenseView:
        return currentMastername ? <MasterNameDriverLicenseView /> : null;
      default:
        return (
          <FormProvider {...methods}>
            <MasterNameForm
              hideHeader={hideHeader}
              onGoBack={onGoBack}
              isMasterIndex={isMasterIndex}
            />
            {kase && <DisabledOverlay />}
          </FormProvider>
        );
    }
  };

  return renderCurrentScreen();
};
