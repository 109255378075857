import { ReactNode } from 'react';

import { MasterNameApi } from '@/api';
import { TabLayoutContextProvider } from '@/layouts/TabLayout';
import { MasterNamesContextProvider } from '@/layouts/TabLayout/MasterNames';
import { CFSMasterNameInvolvement } from '@/models';

interface MasterIndexPersonBaseProps {
  children: ReactNode;
}

const MasterIndexPersonBase = ({ children }: MasterIndexPersonBaseProps) => {
  return (
    <TabLayoutContextProvider>
      <MasterNamesContextProvider
        nextRoutes={[]}
        handleSubmit={() => {}}
        updateMasterName={(nameId: string, data) => {
          return MasterNameApi.updateMasterName({ _id: nameId, ...data });
        }}
        getMasterName={(nameId: string) => MasterNameApi.getMasterName(nameId)}
        involvementOptions={CFSMasterNameInvolvement}
      >
        {children}
      </MasterNamesContextProvider>
    </TabLayoutContextProvider>
  );
};

export default MasterIndexPersonBase;
