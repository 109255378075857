import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { IEquipment } from '@/models';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.name || '-',
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.type || '-',
});

const manufacturer = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.manufacturer || '-',
});

const model = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.model || '-',
});

const location = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.location || '-',
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.status || '-',
});

const actions = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IEquipment,
  ) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const agencyEquipmentCols = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IEquipment,
  ) => void,
) => {
  return {
    name,
    type,
    manufacturer,
    model,
    location,
    status,
    actions: () => actions(openMenu),
  };
};
