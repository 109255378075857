import { IconButton } from '@mui/material';
import {
  GridColDef,
  getGridDateOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcVisibility } from '@/assets/images';
import { excludeValues } from '@/components/DataGrid/utils';
import { DATE_TIME_FORMAT } from '@/config';
import {
  IPropertyLoggedInfo,
  PropertyInvolvement,
  PropertyInvolvementNames,
  PropertyStatus,
  PropertyStatusList,
  PropertyTypeList,
} from '@/models';

const number = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const typeTag = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const name = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const loggedInfo = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row.loggedInfo?.loggedDate
      ? moment(params.row.loggedInfo?.loggedDate).format(DATE_TIME_FORMAT)
      : '/';
  },
  valueGetter: (params: IPropertyLoggedInfo) => {
    return params.loggedDate ? moment(params.loggedDate) : null;
  },
  sortComparator: (a, b) => {
    const dateA = a ? a.toDate() : null;
    const dateB = b ? b.toDate() : null;

    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return dateA.getTime() - dateB.getTime();
  },
  filterOperators: getGridDateOperators(true),
});

const involvement = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.involvement
      ? PropertyInvolvementNames.get(
          params.row.involvement as PropertyInvolvement,
        )
      : '/',
  filterable: false,
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.type
      ? PropertyTypeList[params.row.type as keyof typeof PropertyTypeList]
      : '/',
  filterable: false,
  valueGetter: (_, row) =>
    PropertyTypeList[row.type as keyof typeof PropertyTypeList],
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.status
      ? PropertyStatusList.get(params.row.status as PropertyStatus)
      : '/',
  filterable: false,
});

const actions = (navigate?: (path: string) => void): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`/master-index/properties/${params.row._id}`);
        }}
      >
        <IcVisibility />
      </IconButton>
    </>
  ),
});

export const masterPropertyCols = (navigate?: (path: string) => void) => {
  return {
    number,
    name,
    typeTag,
    loggedInfo,
    involvement,
    type,
    status,
    actions: () => actions(navigate),
  };
};
