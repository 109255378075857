import { useRef, useState, useEffect, useCallback } from 'react';

const useTooltipActivation = () => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = useCallback(() => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  }, []);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  return { textElementRef, disableTooltipHover: !hoverStatus };
};

export default useTooltipActivation;
