import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { ILicensePlate, IVehicleMake, IVehicleModel } from '@/models';

const vehicleName = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.name || '-',
});

const make = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.make?.name || '-',
  valueGetter: (value: IVehicleMake) => value?.name,
});

const model = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.model?.name || '-',
  valueGetter: (value: IVehicleModel) => value?.name,
});

const year = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.year || '-',
});

const vin = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.vin || '-',
});

const licensePlate = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.licensePlate?.number || '-',
  valueGetter: (value: ILicensePlate) => value?.number,
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.status || '-',
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
): Partial<GridColDef> => ({
  sortable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const agencyVehicleCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
) => {
  return {
    vehicleName,
    make,
    model,
    year,
    vin,
    licensePlate,
    status,
    actions: () => actions(openMenu),
  };
};
