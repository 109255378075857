import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';

import { TableColumn, TableContainer } from '@/components';
import { DATE_FORMAT, TIME_FORMAT } from '@/config';
import { IDevice } from '@/models/device';
import { employeeDevicesCols } from '@/pages/Employees/data';

interface EmployeeDevicesTableProps {
  devices: IDevice[];
}

export const EmployeeDevicesTable = ({
  devices,
}: EmployeeDevicesTableProps) => {
  const renderColumn = (device: IDevice, field: keyof IDevice) => {
    switch (field) {
      case 'model':
        return device.model !== 'n/a' ? 'Mobile Phone' : 'Browser';
      case 'manufacturer':
        return `${device.manufacturer} - ${device.model}`;
      case 'appName':
        // For backwards compatibility, appName is empty for some existing mobile devices
        return device.appName ?? (device.fcmToken ? 'Native' : '');
      case 'lastActive':
        return device.lastActive ? (
          <Stack>
            <Typography variant="inherit" className="narrative-title">
              {moment(device.lastActive).format(DATE_FORMAT)} •{' '}
              {moment(device.lastActive).format(TIME_FORMAT)}
            </Typography>
          </Stack>
        ) : (
          '-'
        );
      default:
        return device[field]?.toString() ?? '-';
    }
  };

  return (
    <Stack spacing={2} sx={{ flex: 1, height: '100%', overflowY: 'auto' }}>
      <TableContainer dontCalculateHeight>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 500, py: '20px', fontSize: 16 }}
                colSpan={7}
              >
                Device login history
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              {employeeDevicesCols.map((field) => (
                <TableCell key={field.key}>{field.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.map((device) => (
              <TableRow
                key={device._id}
                sx={{
                  height: '45px!important;',
                }}
              >
                {employeeDevicesCols.map((field: TableColumn) => {
                  return (
                    <TableCell key={field.key}>
                      {renderColumn(device, field.key as keyof IDevice)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
