import { useReducer } from 'react';

import { GridState, GridAction } from '../types';

const initialState: GridState = {
  rows: [],
  rowCount: 0,
  loading: true,
};

const gridReducer = (state: GridState, action: GridAction): GridState => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        rows: action.payload.rows,
        rowCount: action.payload.rowCount,
        loading: false,
      };
    case 'SET_LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};

export const useGridState = () => {
  return useReducer(gridReducer, initialState);
};
