import { createContext, ReactNode, useEffect, useState } from 'react';

import { UAParser } from 'ua-parser-js';

import { Platform } from '@/models/device';

interface DeviceDataProviderProps {
  children: ReactNode;
}

interface DeviceDataContextValue {
  platform: Platform;
  manufacturer: string;
  version: string;
  model: string;
  appName: string;
  appVersion: string;
}

export const DeviceDataContext = createContext<DeviceDataContextValue | null>(
  null,
);

/**
 * Converts an OS string into our Platform enum.
 * @param osName
 * @returns
 */
const getOsPlatform = (osName?: string): Platform | null => {
  if (!osName) return null;

  const osMappings = new Map<RegExp, Platform>([
    [/iphone/i, Platform.IPHONE_OS],
    [/ipad/i, Platform.IPAD_OS],
    [/ios/i, Platform.IOS],
    [/mac/i, Platform.MAC],
    [/windows/i, Platform.WINDOWS],
    [/win/i, Platform.WINDOWS],
    [/ubuntu/i, Platform.LINUX],
    [/linux/i, Platform.LINUX],
  ]);

  for (const [regex, platform] of osMappings) {
    if (regex.test(osName)) return platform;
  }

  return null;
};

export const DeviceDataProvider = ({ children }: DeviceDataProviderProps) => {
  const [deviceData, setDeviceData] = useState<DeviceDataContextValue | null>(
    null,
  );

  useEffect(() => {
    const info = UAParser(navigator.userAgent);

    const data = {
      platform: getOsPlatform(info.os.name),
      manufacturer: info.device.vendor,
      version: info.os.version,
      appName: info.browser.name,
      appVersion: info.browser.version,
      model: 'n/a', // Impossible to get this information in a browser,
    };

    if (
      data.platform &&
      data.manufacturer &&
      data.version &&
      data.appName &&
      data.appVersion
    ) {
      setDeviceData({
        platform: data.platform,
        manufacturer: data.manufacturer,
        version: data.version,
        appName: data.appName,
        appVersion: data.appVersion,
        model: data.model,
      });
    }
  }, []);

  return (
    <DeviceDataContext.Provider value={deviceData}>
      {children}
    </DeviceDataContext.Provider>
  );
};
