import { FunctionComponent, SVGAttributes } from 'react';

import {
  Ic911Menu,
  IcBell,
  IcBuild,
  IcCall,
  IcEventList,
  IcFolderData,
  IcHelpCircle,
  IcMarker,
  IcNote,
  IcPerson,
  IcSettings,
} from '@/assets/images';

export interface ISideBarItem {
  key: string;
  label?: string;
  icon?: FunctionComponent<SVGAttributes<SVGAElement>>;
  link: string;
  items?: ISideBarItem[];
  submenuEl?: HTMLElement;
  setSubmenuEl?: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
  isActive?: boolean;
}

export const FirstResponderItemsKeys = ['my-cfs', 'map-view'];

export const SidebarItems1: ISideBarItem[] = [
  {
    key: 'my-cfs',
    label: 'MY CFS',
    icon: Ic911Menu,
    link: '/my-cfs',
  },
  {
    key: 'map-view',
    label: 'Map View',
    icon: IcMarker,
    link: '/map-view',
  },
  {
    key: 'agencies',
    label: 'Agencies',
    icon: IcPerson,
    link: '/agencies',
  },
  {
    key: 'cfs',
    label: 'Call For Service',
    icon: IcCall,
    link: '/cfs',
  },
  {
    key: 'cfs-active',
    label: 'Active Dispatch Requests',
    icon: IcBell,
    link: '/cfs-active',
  },
  {
    key: 'kases',
    label: 'Cases',
    icon: IcNote,
    link: '/cases',
  },
  {
    key: 'master-index',
    label: 'Master Index',
    icon: IcFolderData,
    link: '/master-index',
  },
  {
    key: 'tasks',
    label: 'Tasks',
    icon: IcEventList,
    link: '/tasks',
  },
  {
    key: 'tools',
    label: 'Tools',
    icon: IcBuild,
    link: '/tools',
    items: [
      {
        key: 'sex-offenders',
        label: 'Sex Offenders',
        link: '/tools/sex-offenders',
      },
      {
        key: 'cameras',
        label: 'Cameras',
        link: '/tools/cameras',
      },
      {
        key: 'bolo',
        label: 'Bolo',
        link: '/tools/bolo',
      },
      {
        key: 'complaints',
        label: 'Complaints',
        link: '/tools/complaints',
      },
      {
        key: 'permit',
        label: 'Permit',
        link: '/tools/permit',
      },
    ],
  },
];

export const SidebarItems2: ISideBarItem[] = [
  {
    key: 'settings',
    label: 'Settings',
    icon: IcSettings,
    link: '/settings',
  },
  {
    key: 'help',
    label: 'Help',
    icon: IcHelpCircle,
    link: '/help',
  },
];

export enum SwipeMotionDirection {
  Left = 'Left',
  Right = 'Right',
}

export const SwipeAnimates = {
  [SwipeMotionDirection.Left]: {
    initial: { x: '100%' },
    animate: { x: '0' },
  },
  [SwipeMotionDirection.Right]: {
    initial: { x: '-100%' },
    animate: { x: '0' },
  },
};
