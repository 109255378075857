import { useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  styled,
} from '@mui/material';

import { IcArrowDown, IcWarning } from '@/assets/images';
import { ICFS } from '@/models';
import { LiveInformation } from '@/pages/CFS/Edit/components/LiveInformation';
import { HeaderSection } from '@/pages/FirstResponder/components/HeaderSection';
import { ModalState } from '@/pages/FirstResponder/shared/modalState';
import { getAddressLocation } from '@/services';

type CfsDetailsModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  cfs?: ICFS;
  hasCfsAssigned?: boolean;
};

const CfsDetailsModalContent = ({
  toggleModal,
  cfs,
  hasCfsAssigned,
}: CfsDetailsModalContentProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openActionsMenu = Boolean(anchorEl);

  const handleCloseActionsMenu = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getPrimaryReporterFullName = () => {
    if (cfs?.primaryReporter?.firstName && cfs?.primaryReporter?.lastName) {
      return (
        cfs?.primaryReporter?.firstName + ' ' + cfs?.primaryReporter?.lastName
      );
    } else {
      return 'No reporter';
    }
  };

  const StyledMenuItem = styled(MenuItem)(() => ({
    justifyContent: 'flex-start',
  }));

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stack flex={1} padding={2}>
        <HeaderSection
          title={cfs?.shortDescription || 'No Emergency detail for this CFS'}
          subtitle={getAddressLocation(cfs?.addressInfo?.address)}
          phoneNumber={cfs?.primaryReporter?.phoneNumbers?.[0]?.phoneNumber.toString()}
          primaryReporter={getPrimaryReporterFullName()}
          icon={<IcWarning />}
          severity={cfs?.severity}
          hideNavigationButton={true}
        />
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <LiveInformation
            cfsId={cfs?._id}
            isNewWindow={false}
            isFirstResponderView={true}
            isMaxHeight={true}
            disableSendMessage={true}
          />
        </Box>
      </Stack>
      <Stack padding={2}>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="flex-end">
          {!hasCfsAssigned ? (
            <Button
              color="error"
              variant="contained"
              size="large"
              onClick={() => {
                // action();
                toggleModal('cfsDetails');
                toggleModal('confirmAssignment');
              }}
            >
              Assign CFS
            </Button>
          ) : (
            <>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClick}
                  endIcon={<IcArrowDown />}
                >
                  Actions
                </Button>
              </Box>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={openActionsMenu}
                onClose={handleCloseActionsMenu}
              >
                <StyledMenuItem
                  onClick={() => {
                    toggleModal('cfsDetails');
                    toggleModal('closeCfsAndAssign');
                  }}
                >
                  Complete CFS and Assign
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    toggleModal('cfsDetails');
                    toggleModal('moveToQueueAndAssign');
                  }}
                >
                  Move to Queue and Self-Assign
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    toggleModal('cfsDetails');
                    toggleModal('addToQueue');
                  }}
                >
                  Add CFS in queue
                </StyledMenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default CfsDetailsModalContent;
