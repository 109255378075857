import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { ICaution, ICautionForm, ICautionType } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse, IPaginatedListResponse } from './types';

export const CautionApi = {
  getCautions: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICaution>>> => {
    return http.get(`api/${ApiVersion.V1}/cautions/cautions`, { params });
  },
  getPaginatedCautions: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse<ICaution>>> => {
    return http.get(`api/${ApiVersion.V1}/cautions/cautions/list`, { params });
  },
  getCaution: (id: string): Promise<AxiosResponse<ICaution>> => {
    return http.get(`api/${ApiVersion.V1}/cautions/cautions/${id}`);
  },
  getCautionTypes: (): Promise<AxiosResponse<ICautionType[]>> => {
    return http.get(`api/${ApiVersion.V1}/cautions/types`);
  },
  createCaution: (data: ICautionForm): Promise<AxiosResponse<ICaution>> => {
    return http.post(`api/${ApiVersion.V1}/cautions/cautions`, data);
  },
  deleteCaution: (id: string): Promise<AxiosResponse<ICaution>> => {
    return http.delete(`api/${ApiVersion.V1}/cautions/cautions/${id}`);
  },
};
