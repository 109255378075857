import { FC } from 'react';

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Box,
  TablePagination,
} from '@mui/material';
import moment from 'moment';

import { SearchBar } from '@/components';
import { FilterMenu } from '@/components/Modals/UnitShiftListModal/components/LogsFilter';
import { DATE_TIME_FORMAT } from '@/config';
import { IUnit } from '@/models';

interface ShiftLogsTableProps {
  count: number;
  data: any[];
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  rowsPerPage: number;
  page: number;
  units?: IUnit[];
  onFilter?: (page: number, limit: number, filter: any) => void;
}

const columns = ['Created at', 'Event', 'Unit', 'Changed by'];

enum CFSEventMapper {
  CLOSED = 'Closed',
  UNIT_SHIFT_ASSIGNED = 'Assigned',
  UNIT_SHIFT_IS_EN_ROUTE = 'En Route',
  UNIT_SHIFT_IS_ON_SCENE = 'On Scene',
  UNIT_SHIFT_IS_IN_PROGRESS = 'In Progress',
  UNIT_SHIFT_CANCELED = 'Canceled',
  UNIT_SHIFT_COMPLETED = 'Completed',
}

const ShiftLogsTableRow: FC<{ row: any }> = ({ row }) => {
  return (
    <>
      <TableRow sx={{ height: '45px' }}>
        <TableCell>{moment(row?.createdAt).format(DATE_TIME_FORMAT)}</TableCell>
        <TableCell>
          <Box
            sx={{
              backgroundColor: '#F3F4F6',
              padding: '4px 12px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.875rem',
              color: '#1F2937',
              width: '120px',
              height: '32px',
              textAlign: 'center',
            }}
          >
            {CFSEventMapper[row.event as keyof typeof CFSEventMapper]}
          </Box>
        </TableCell>

        <TableCell>{row.data?.new?.unit?.name}</TableCell>
        <TableCell>{row.createdBy?.fullName}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={columns.length} padding="none">
          <Divider />
        </TableCell>
      </TableRow>
    </>
  );
};

export default function UnitShiftLogs({
  count,
  data,
  onPageChange,
  onSearch,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  units,
  onFilter,
}: ShiftLogsTableProps) {
  const onChangeFilter = (filterData: any) => {
    if (onFilter) {
      onFilter(page, 100, filterData);
    }
  };

  return (
    <Stack spacing={2} flex={1} marginBottom="2rem" margin={2}>
      <Box sx={{ width: '100%', marginBottom: '1.5rem', position: 'relative' }}>
        <SearchBar
          onChange={onSearch}
          shouldCancel=""
          sx={{
            width: '92% !important',
            maxWidth: '92% !important',
            backgroundColor: '#FFFFFF',
            '& .MuiOutlinedInput-root': {
              width: '100%',
              backgroundColor: '#FFFFFF',
              paddingRight: '48px',
            },
            '& .MuiInputBase-root': {
              width: '100%',
            },
            '& .MuiInputBase-input': {
              width: '100%',
              padding: '12px 14px',
            },
          }}
          placeholder="Search..."
        />
        <Box
          sx={{
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <FilterMenu onChangeFilter={onChangeFilter} tableData={units} />
        </Box>
      </Box>
      <TableContainer>
        <Table
          sx={{
            minWidth: 500,
            px: 3,
            border: '1px solid rgba(224, 224, 224, 0.4)',
            borderRadius: '12px',
            backgroundColor: '#FFFFFF',
            paddingTop: '0.5rem',
          }}
          aria-label="shift logs table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map((title) => (
                <TableCell
                  key={title}
                  sx={{
                    backgroundColor: '#FFF',
                    fontWeight: 600,
                    fontSize: '1rem',
                    color: '#1F2937',
                    padding: '24px',
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={columns.length}
                padding="none"
                sx={{ backgroundColor: 'transparent' }}
              >
                <Divider />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <ShiftLogsTableRow key={index} row={row} />
            ))}
            {data.length < 1 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  sx={{
                    color: 'grey',
                    borderBottomWidth: 0,
                  }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ overflow: 'none' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => {
            onPageChange(newPage);
          }}
          onRowsPerPageChange={(event) => {
            const newRowsPerPage = parseInt(event.target.value, 10);
            onRowsPerPageChange(newRowsPerPage);
          }}
        />
      </Box>
    </Stack>
  );
}
