import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { ICall } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const IncomingCallsApi = {
  getCalls: async (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ICall>>> => {
    return http.get(`api/${ApiVersion.V1}/incoming-calls`, {
      params,
    });
  },
  updateCallReadStatus: async (
    _id: string,
  ): Promise<AxiosResponse<IListResponse<ICall>>> => {
    return http.patch(`api/${ApiVersion.V1}/incoming-calls/${_id}/read`);
  },
  answerCall: async (_id: string): Promise<AxiosResponse<void>> => {
    return http.get(`api/${ApiVersion.V1}/incoming-calls/okg/answered/${_id}`);
  },
};
