import { Box, IconButton } from '@mui/material';
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
} from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { excludeValues, formatDate } from '@/components/DataGrid/utils';
import {
  IProperty,
  PropertyInvolvementNames,
  PropertyStatusList,
  PropertyTypeList,
} from '@/models';

const number = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const name = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const typeTag = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty', 'isAnyOf']),
  ),
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    PropertyTypeList[params.row.type as keyof typeof PropertyTypeList],
  filterable: false,
  valueGetter: (_, row) =>
    PropertyTypeList[row.type as keyof typeof PropertyTypeList],
});

const involvement = (): Partial<GridColDef> => ({
  renderCell: (params) => PropertyInvolvementNames.get(params.row.involvement),
  filterable: false,
  valueGetter: (_, row) => {
    return PropertyInvolvementNames.get(row.involvement);
  },
});

const dateLogged = (): Partial<GridColDef> => ({
  filterOperators: getGridDateOperators(true),
  renderCell: (params) => formatDate(params.row.loggedInfo?.loggedDate),
  valueGetter: (_, row) => row.loggedInfo?.loggedDate,
});

const loggedBy = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const tempLoggedBy = params.row.loggedInfo?.loggedBy;
    return tempLoggedBy ? tempLoggedBy.fullName : '';
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues(['equals', 'doesNotEqual', 'isAnyOf']),
  ),
  valueGetter: (_, row) => row.loggedInfo?.loggedBy?.fullName,
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => PropertyStatusList.get(params.row.status),
  filterable: false,
});

const loggedAddress = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(excludeValues(['isAnyOf'])),
  renderCell: (params) =>
    params.row.loggedInfo?.loggedAddress?.normalized || '',
  valueGetter: (_, row) => row.loggedInfo?.loggedAddress?.normalized || '',
});

const actions = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    params: IProperty,
  ) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <Box>
      <IconButton onClick={(e) => openMenu?.(e, params.row)}>
        <IcDotSpread />
      </IconButton>
    </Box>
  ),
});

export const kasePropertyCols = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    params: IProperty,
  ) => void,
) => ({
  number,
  name,
  typeTag,
  type,
  involvement,
  dateLogged,
  loggedBy,
  status,
  loggedAddress,
  actions: () => actions(openMenu),
});
