import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { IcBell } from '@/assets/images';
import { colors } from '@/theme/variables';

import { ModalState } from '../shared/modalState';

type CloseCfsAndAssignModalContentProps = {
  toggleModal: (modalName: keyof ModalState) => void;
  action: (accepted: boolean) => void;
};

const CloseCfsAndAssignModalContent = ({
  toggleModal,
  action,
}: CloseCfsAndAssignModalContentProps) => {
  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stack
        rowGap={2}
        flex={1}
        alignItems="center"
        fontSize={40}
        padding={5}
        textAlign="center"
      >
        <IcBell width={84} height={84} color={colors.error.main} />
        <Typography component="h3" variant="h6">
          Assigning this CFS will close your current active shift assignment. Do
          you want to proceed?
        </Typography>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" gap={2} paddingTop={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              toggleModal('closeCfsAndAssign');
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            sx={{ padding: 2 }}
            fullWidth
            onClick={() => {
              action(true);
              toggleModal('closeCfsAndAssign');
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CloseCfsAndAssignModalContent;
