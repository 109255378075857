import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { formatPhoneNumber } from '@/utils';

const firstName = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.firstName || '-',
});

const middleName = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.middleName || '-',
});

const lastName = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.lastName || '-',
});

const email = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.email || '-',
});

const phoneNumber = (): Partial<GridColDef> => ({
  renderCell: (params) => formatPhoneNumber(params.row.phoneNumber) || '-',
});

const title = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.profileInfo?.employmentInformation?.title || '-',
  valueGetter: (_, row) => row.profileInfo?.employmentInformation?.title,
});

const rank = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.profileInfo?.employmentInformation?.rank?.name || '-',
  valueGetter: (_, row) => row.profileInfo?.employmentInformation?.rank?.name,
});

const badgeNumber = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.profileInfo?.employmentInformation?.badgeNumber || '-',
  valueGetter: (_, row) => row.profileInfo?.employmentInformation?.badgeNumber,
});

const department = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.profileInfo?.employmentInformation?.department?.name || '-',
  valueGetter: (_, row) =>
    row.profileInfo?.employmentInformation?.department?.name,
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
): Partial<GridColDef> => ({
  sortable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const agencyEmployeeCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
) => {
  return {
    firstName,
    middleName,
    lastName,
    email,
    phoneNumber,
    title,
    rank,
    badgeNumber,
    department,
    actions: () => actions(openMenu),
  };
};
