import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { IInventoryLocation } from '@/models/inventory.locations';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.name || '-',
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.type?.name || '-',
  valueGetter: (value: IInventoryLocation) => {
    return value?.name;
  },
});

const parent = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.parent?.name || '-',
  valueGetter: (value: IInventoryLocation) => {
    return value?.name;
  },
});

const actions = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IInventoryLocation,
  ) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const agencyInventoryLocationCols = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IInventoryLocation,
  ) => void,
) => {
  return {
    name,
    type,
    parent,
    actions: () => actions(openMenu),
  };
};
