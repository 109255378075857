import moment from 'moment';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/config';

export const getTimeDifference = (
  startTime: moment.Moment,
  originTime: Date,
) => {
  const difference = startTime.diff(moment(originTime));
  // there is an error Uncaught SyntaxError in the browser if i follow the ESLINT linter
  //: The requested module '/node_modules/.vite/deps/moment.js?v=0be6047f' does not provide an export named 'duration'
  // eslint-disable-next-line import/no-named-as-default-member
  return moment.utc(moment.duration(difference).asMilliseconds());
};

export const getFormattedDateTime = (date?: string) => {
  if (!date) return '';
  return moment(date).format(DATE_TIME_FORMAT);
};

export const getFormattedDate = (date?: string, format = DATE_FORMAT) => {
  if (!date) return '';
  return moment(date).format(format);
};
