import { IconButton } from '@mui/material';
import moment from 'moment';

import { IcDotSpread } from '@/assets/images';
import { DATE_FORMAT } from '@/config';

export const masterNameNoteCols = (openMenu: any, user: any) => ({
  description: () => ({
    renderCell: (params: any) => params.row.description,
  }),
  createdBy: () => ({
    renderCell: (params: any) => {
      const { firstName, lastName } = params.row.createdBy;
      return `${firstName || 'no name'} ${lastName || 'no lastName'}`;
    },
  }),
  createdAt: () => ({
    renderCell: (params: any) =>
      moment(params.row.createdAt).format(DATE_FORMAT),
  }),
  cfs: () => ({
    renderCell: (params: any) =>
      params.row.cfs
        ? params.row.cfs.number
        : params.row.kase
        ? params.row.kase.number
        : 'no number',
    valueGetter: (
      _: any,
      row: { cfs: { number: string }; kase: { number: string } },
    ) => (row.cfs ? row.cfs.number : row.kase ? row.kase.number : undefined),
  }),
  actions: () => ({
    renderCell: (params: any) =>
      user?._id === params.row.createdBy._id && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            openMenu(e, params.row);
          }}
        >
          <IcDotSpread />
        </IconButton>
      ),
  }),
});
