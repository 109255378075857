import * as React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled, useTheme } from '@mui/material/styles';
import clsx from 'clsx';

import {
  IcArrowDown,
  IcDotSpread,
  IcMenuOpen,
  IcMenuClose,
} from '@/assets/images';
import { Avatar } from '@/components';
import { useAuthContext } from '@/hooks';
import {
  FirstResponderItemsKeys,
  ISideBarItem,
  SidebarItems2,
} from '@/layouts/data';
import { IAccount, IUser, Role } from '@/models';
import { getAllowedSidebarItems, isTargetRole } from '@/services';
import { useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { ThemeSwitch } from '../ThemeSwitch';
import {
  Drawer,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './styles';
import EnvironmentBadge from '../EnvironmentBadge/EnvironmentBadge';

type ListItemComponentProps = {
  key: string;
  label?: string;
  icon?: React.ElementType;
  open: boolean;
  link?: string;
  onClick?: () => void;
  items?: ISideBarItem[];
};

const ListItemComponent = ({
  key,
  label,
  icon,
  open,
  link,
  onClick,
  items,
  isActive,
}: ListItemComponentProps & { isActive: boolean | string | undefined }) => {
  const navigate = useNavigate();
  const t = useTheme();
  const { pathname } = useLocation();
  const [submenuOpen, setSubmenuOpen] = React.useState(false);

  const [submenuEl, setSubmenuEl] = React.useState<HTMLElement | undefined>();

  const activeLink = pathname;

  const isActiveLink = (l?: string) => {
    return l && (activeLink === l || activeLink.startsWith(`${l}/`));
  };

  const onClickMenuItem = (itemLink?: string) => {
    setSubmenuEl(undefined);
    if (itemLink) navigate(itemLink);
  };

  const menuItems = () => {
    return items?.map((item) => (
      <TooltipTitle
        key={item.key}
        className={clsx(item.isActive && 'active')}
        onClick={() => onClickMenuItem(item.link)}
      >
        {item.label}
      </TooltipTitle>
    ));
  };

  const handleNavItemAction = (e: any) => {
    e.preventDefault();
    if (!items && link) return navigate(link);
    if (items && open) setSubmenuOpen(!submenuOpen);
    if (items && !open) {
      if (submenuEl) setSubmenuEl(undefined);
      else setSubmenuEl(e.currentTarget);
    }
  };

  const TooltipTitle = styled(MenuItem)(({ theme }) => ({
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  }));

  const content = (
    <ListItem
      key={key}
      disablePadding
      sx={{ display: 'block' }}
      onClick={onClick}
    >
      <StyledListItemButton
        onClick={handleNavItemAction}
        className={clsx(isActive && 'active')}
        sx={[
          {
            borderRadius: 2,
            minHeight: 48,
            px: 2.5,
          },
          open
            ? {
                justifyContent: 'initial',
              }
            : {
                justifyContent: 'center',
              },
        ]}
      >
        <StyledListItemIcon
          className={clsx(isActive && 'active')}
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
            },
            open
              ? {
                  mr: 3,
                }
              : {
                  mr: 'auto',
                },
          ]}
        >
          {icon && React.createElement(icon)}
        </StyledListItemIcon>
        <StyledListItemText
          className={clsx(isActive && 'active')}
          primary={label}
          sx={[
            open
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                },
          ]}
        />
        {items && items?.length > 0 ? (
          <IcArrowDown color={isActive ? t.palette.common.white : ''} />
        ) : null}
      </StyledListItemButton>
    </ListItem>
  );

  return (
    <>
      <Tooltip
        title={
          open ? null : items ? (
            menuItems()
          ) : (
            <TooltipTitle className="title">{label}</TooltipTitle>
          )
        }
        placement="right-start"
        components={{ Tooltip: 'div' }}
      >
        {link ? (
          <Link
            to={link}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
            }}
            onClick={(e) => e.preventDefault()}
          >
            {content}
          </Link>
        ) : (
          content
        )}
      </Tooltip>
      {submenuOpen && open && items && (
        <List component="div" disablePadding sx={{ p: 1 }}>
          {items.map((subItem) => (
            <ListItemComponent
              key={subItem.key}
              icon={subItem.icon}
              link={subItem.link}
              label={subItem.label}
              items={subItem.items}
              open={open}
              isActive={isActiveLink(subItem.link)}
            />
          ))}
        </List>
      )}
    </>
  );
};

// if user is first responder and has more than 2 roles we need to have switch for full version
const getHasMultipleViews = (user: IUser) => {
  return isTargetRole(user, Role.FIRST_RESPONDER) && user.roles.length > 2;
};

export default function MainSideBar() {
  const { logout } = useAuthContext();
  const { user, account } = useUserStore();
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [menuEl, setMenuEl] = React.useState<HTMLElement | null>(null);
  const [isFullVersion, setIsFullVersion] = React.useState(true);
  const [hasMultipleViews, setHasMultipleViews] = React.useState(true);
  const allowedSidebarItems = getAllowedSidebarItems(
    user as IUser,
    account as IAccount,
  );

  React.useEffect(() => {
    setHasMultipleViews(getHasMultipleViews(user as IUser));
    if (user && isTargetRole(user, Role.FIRST_RESPONDER)) {
      setIsFullVersion(false);
    } else {
      setIsFullVersion(true);
    }
  }, [user]);

  const { firstResponderSidebarItems, otherAllowedSidebarItems } =
    allowedSidebarItems.reduce(
      (
        acc: {
          firstResponderSidebarItems: ISideBarItem[];
          otherAllowedSidebarItems: ISideBarItem[];
        },
        item,
      ) => {
        if (FirstResponderItemsKeys.includes(item.key)) {
          acc.firstResponderSidebarItems.push(item);
        } else {
          acc.otherAllowedSidebarItems.push(item);
        }
        return acc;
      },
      { firstResponderSidebarItems: [], otherAllowedSidebarItems: [] },
    );

  const openMenu = Boolean(menuEl);

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFullVersion(event.target.checked);
  };

  const isActiveLink = (l?: string) => {
    return l && (pathname === l || pathname.startsWith(`${l}/`));
  };

  const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    },
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={open}>
        <List sx={{ p: 2 }}>
          <EnvironmentBadge open={open} />
          <Divider sx={{ my: 1 }} />
          {firstResponderSidebarItems.map((item) => (
            <div key={item.key}>
              <ListItemComponent
                key={item.key}
                icon={item.icon}
                link={item.link}
                label={item.label}
                items={item.items}
                open={open}
                isActive={isActiveLink(item.link)}
              />
              {item.key === 'cfs-active' && <Divider sx={{ my: 1 }} />}
            </div>
          ))}
          {hasMultipleViews && isFullVersion && <Divider sx={{ my: 1 }} />}
          {isFullVersion &&
            otherAllowedSidebarItems.map((item) => (
              <div key={item.key}>
                <ListItemComponent
                  key={item.key}
                  icon={item.icon}
                  link={item.link}
                  label={item.label}
                  items={item.items}
                  open={open}
                  isActive={isActiveLink(item.link)}
                />
                {item.key === 'cfs-active' && <Divider sx={{ my: 1 }} />}
              </div>
            ))}
        </List>

        <Stack
          sx={{
            justifyContent: 'flex-end',
            flex: 1,
          }}
        >
          <List sx={{ p: 2 }}>
            <ListItemComponent
              key="menu"
              label={open ? 'Collapse navigation' : 'Expand navigation'}
              icon={open ? IcMenuClose : IcMenuOpen}
              open={open}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              isActive={false}
            ></ListItemComponent>
            {SidebarItems2.map((item) => (
              <ListItemComponent
                key={item.key}
                icon={item.icon}
                link={item.link}
                label={item.label}
                items={item.items}
                open={open}
                isActive={isActiveLink(item.link)}
              />
            ))}
          </List>
          <Stack
            p={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Avatar
              onClick={!open ? (e) => setMenuEl(e.currentTarget) : () => {}}
              name={`${user?.firstName} ${user?.lastName}`}
              sx={{
                bgcolor: colors.grey[90],
                borderRadius: 2,
                color: colors.grey[10],
              }}
            />
            {open ? (
              <Stack
                direction="row"
                flex={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack ml={2}>
                  <Typography fontSize={15} fontWeight={500}>
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography fontSize={13} fontWeight={400}>
                    {user?.email}
                  </Typography>
                </Stack>
                <IconButton onClick={(e) => setMenuEl(e.currentTarget)}>
                  <IcDotSpread style={{ rotate: '90deg' }} />
                </IconButton>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
        <StyledMenu
          id="setting-menu"
          anchorEl={menuEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ ml: 2 }}
        >
          {hasMultipleViews && (
            <MenuItem>
              <Stack
                flex={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Full version</Typography>
                <Switch
                  color="error"
                  checked={isFullVersion}
                  onChange={handleSwitchChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </MenuItem>
          )}
          <MenuItem
            sx={{
              height: '50px',
              width: '260px',
              justifyContent: 'flex-start',
            }}
          >
            <ThemeSwitch switchMode="radio" />
          </MenuItem>
          <MenuItem
            sx={{
              height: '50px',
              width: '260px',
              justifyContent: 'flex-start',
            }}
            onClick={logout}
          >
            Log out
          </MenuItem>
        </StyledMenu>
      </Drawer>
    </Box>
  );
}
