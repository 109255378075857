import { FC } from 'react';

import { Box, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

import { IcDeleteTrash, IcEdit } from '@/assets/images';
import { DATE_TIME_FORMAT } from '@/config';
import { IAdditionalInfo } from '@/models';
import { useUserStore } from '@/store';

interface IAdditionalInfoItemProps {
  cfsId?: string;
  additionalInfo: IAdditionalInfo;
  updateAdditionalInfos?: (data: IAdditionalInfo) => void;
  onEdit?: (additionalInfo: IAdditionalInfo) => void;
}

export const AdditionalInfoItem: FC<IAdditionalInfoItemProps> = ({
  additionalInfo,
  updateAdditionalInfos,
  onEdit,
}) => {
  const { user } = useUserStore();
  const isCurrentUser = user?._id === additionalInfo?.createdBy?._id;

  const removeMessage = async () => {
    additionalInfo.isDeleted = true;
    if (updateAdditionalInfos) updateAdditionalInfos(additionalInfo);
  };

  return (
    <Box
      className={`item ${isCurrentUser ? 'current-user' : ''}`}
      sx={{
        width: '100%',
        padding: isCurrentUser ? '0px 8px' : '0px 0px',
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{
          maxWidth: isCurrentUser ? '80%' : '100%',
          marginLeft: isCurrentUser ? 'auto' : '0',
        }}
      >
        {!isCurrentUser && (
          <Tooltip
            title={
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {additionalInfo.createdBy?.firstName || ''}{' '}
                {additionalInfo.createdBy?.lastName || ''}
              </Typography>
            }
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: '#fff',
                color: additionalInfo.createdBy?.roles?.some(
                  (r) => r.role === 'DISPATCHER',
                )
                  ? '#EE3E5C'
                  : '#647787',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
                marginRight: '8px',
                border: '1px solid',
                fontWeight: 600,
              }}
            >
              {additionalInfo.createdBy?.firstName?.charAt(0) || ''}
              {additionalInfo.createdBy?.lastName?.charAt(0) || ''}
            </Box>
          </Tooltip>
        )}

        <Box
          sx={{
            width: '100%',
            minWidth: 0,
            backgroundColor: isCurrentUser ? '#6E838F' : '#f1f1f1',
            color: isCurrentUser ? '#fff' : '#000',
            borderRadius: '6px',
            padding: '12px 16px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              wordBreak: 'break-word',
            }}
          >
            <span
              style={{
                textDecoration: additionalInfo?.isDeleted
                  ? 'line-through'
                  : 'none',
              }}
            >
              {additionalInfo.description}
            </span>
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Tooltip
                title={moment(additionalInfo.createdAt).format(
                  DATE_TIME_FORMAT,
                )}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: isCurrentUser ? 'rgba(255,255,255,0.7)' : '#666',
                  }}
                >
                  {moment(additionalInfo.createdAt).format('h:mm A')}
                </Typography>
              </Tooltip>
              {additionalInfo.isEdited && !additionalInfo.isDeleted && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: isCurrentUser ? 'rgba(255,255,255,0.7)' : '#666',
                  }}
                >
                  Edited
                </Typography>
              )}
              {additionalInfo.isDeleted && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: isCurrentUser ? 'rgba(255,255,255,0.7)' : '#666',
                  }}
                >
                  Deleted
                </Typography>
              )}
            </Stack>

            {!additionalInfo?.isDeleted && isCurrentUser && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  '& svg': {
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    color: isCurrentUser ? '#fff' : '#666',
                  },
                }}
              >
                <Tooltip title="Delete">
                  <IcDeleteTrash onClick={removeMessage} />
                </Tooltip>
                <Tooltip title="Edit">
                  <IcEdit
                    onClick={() => {
                      if (onEdit) {
                        onEdit(additionalInfo);
                      }
                    }}
                  />
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
