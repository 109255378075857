import { useState } from 'react';

import { logger } from '@azure/storage-blob';
import {
  Box,
  FormHelperText,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { AddressApi, MileMarkerApi } from '@/api';
import { IcGoogle, IcMileMarker } from '@/assets/images';
import { IAddress, IAddressForm, IMileMarker, PointType } from '@/models';
import { getAddressLocation } from '@/services';
import { googlePlaceService } from '@/services/service.google';
import { useToastStore } from '@/store';

import { AddressOptionType, AddressSearch } from './AddressSearch';
import { BaseFieldProps } from '../../FormElements';

interface AddressItemProps extends BaseFieldProps {
  name?: string;
  value?: AddressOptionType;
  locationRestriction?: google.maps.LatLngBoundsLiteral;
  updateCurrentAddress: (newAddress: IAddressForm | null) => void;
}

const validateCoordinates = (
  input: string,
): { lat: number; lng: number } | null => {
  // Clean input and handle flexible spacing
  const cleaned = input.replace(/\s+/g, '').trim();
  const parts = cleaned.split(',');

  if (parts.length !== 2) return null;

  const lat = parseFloat(parts[0]);
  const lng = parseFloat(parts[1]);

  if (isNaN(lat) || isNaN(lng)) return null;

  if (lat < -90 || lat > 90 || lng < -180 || lng > 180) return null;

  return { lat, lng };
};

export const AddressItem = (props: AddressItemProps) => {
  const { value, error, locationRestriction, updateCurrentAddress } = props;
  const { updateToast } = useToastStore();
  const [options, setOptions] = useState<AddressOptionType[]>([]);
  const [mileMarkers, setMileMarkers] = useState<IMileMarker[]>([]);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState<'google' | 'milemarker'>('google');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCoordinateSearch = async (coords: {
    lat: number;
    lng: number;
  }) => {
    try {
      setLoading(true);
      const place = await googlePlaceService.geocodeCoordinates(
        coords.lat,
        coords.lng,
      );
      if (place) {
        const option: AddressOptionType = {
          mainText: place.formatted_address || `${coords.lat}, ${coords.lng}`,
          secondaryText: 'Found from coordinates',
          placeId: `coords:${coords.lat},${coords.lng}`,
        };
        setOptions([option]);
        return;
      }

      // If no results found, still show the coordinates as an option
      const option: AddressOptionType = {
        mainText: `${coords.lat}, ${coords.lng}`,
        secondaryText: 'Click to use these coordinates',
        placeId: `coords:${coords.lat},${coords.lng}`,
      };
      setOptions([option]);
    } catch (err) {
      updateToast({ open: true, message: 'Error looking up coordinates' });
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const retrieveFullAddress = async (newValue: AddressOptionType) => {
    try {
      let addressDetails: IAddressForm | null = null;

      if (newValue.placeId === 'mileMarker') {
        const currentMileMarker =
          mileMarkers.find(
            (marker) => marker.addressLabel === newValue.mainText,
          ) || null;
        if (currentMileMarker) {
          addressDetails = {
            pointType: PointType.ROOF_TOP,
            addressNumber: currentMileMarker.addressNumber,
            addressLabel: currentMileMarker.addressLabel,
            completeStreetName: currentMileMarker.completeStreetName,
            state: currentMileMarker.state,
            zipName: currentMileMarker.zipName,
            zipcode: currentMileMarker.zipCode,
            point: {
              type: 'Point',
              coordinates: [
                currentMileMarker.longitude,
                currentMileMarker.latitude,
              ],
            },
          };
        }
      } else if (newValue.placeId?.startsWith('coords:')) {
        const [lat, lng] = newValue.placeId
          .replace('coords:', '')
          .split(',')
          .map(Number);
        const place = await googlePlaceService.geocodeCoordinates(lat, lng);
        if (place) {
          addressDetails = googlePlaceService.getAddressDetails(place);
        } else {
          // If geocoding fails, create a basic address with just the coordinates
          addressDetails = {
            pointType: PointType.ROOF_TOP,
            addressLabel: `${lat}, ${lng}`,
            point: {
              type: 'Point',
              coordinates: [lng, lat],
            },
          };
        }
      } else {
        addressDetails = await googlePlaceService.getPlaceDetails(
          String(newValue.placeId),
        );
      }

      if (addressDetails) {
        addNewAddress(addressDetails);
        updateCurrentAddress(addressDetails);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const addNewAddress = async (newAddress: IAddressForm) => {
    try {
      const res = await AddressApi.createAddress(newAddress);
      updateCurrentAddress(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onSearch = async (inputValue: string) => {
    try {
      if (!inputValue) {
        setOptions([]);
        return;
      }

      if (inputValue === '~') {
        setProvider('milemarker');
      }

      if (inputValue === '*') {
        setProvider('google');
      }

      setLoading(true);
      // Check if input might be coordinates
      const coords = validateCoordinates(inputValue);
      if (coords) {
        await handleCoordinateSearch(coords);
        return;
      }

      if (provider === 'milemarker') {
        const searchParameter = inputValue.replace('~', '');
        const res = await MileMarkerApi.list({
          search: searchParameter,
        });
        const newOptions = res.data.map((marker) => ({
          mainText: marker.addressLabel,
          secondaryText: `${marker.completeStreetName}, ${marker.state}, ${marker.zipCode}`,
          placeId: 'mileMarker',
        }));
        setMileMarkers([...res.data]);
        setOptions(newOptions);
      } else {
        const searchParameter = inputValue.replace('*', '');
        const res = await googlePlaceService.getPlacePredictions(
          searchParameter,
          locationRestriction,
        );
        const newOptions = res.map(({ structured_formatting, place_id }) => ({
          mainText: structured_formatting.main_text,
          secondaryText: structured_formatting.secondary_text,
          placeId: place_id,
        }));
        setOptions(newOptions);
      }
    } catch (err: any) {
      logger.error('Search error:', err);
      updateToast({ open: true, message: err.message });
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (newValue: string | AddressOptionType | null) => {
    if (!!newValue && typeof newValue !== 'string') {
      retrieveFullAddress(newValue);
    }
  };

  const handleInputChange = (newValue: string) => {
    if (newValue.length > 0) {
      onSearch(newValue);
    } else {
      setMileMarkers([]);
      setOptions([]);
      updateCurrentAddress(null);
    }
  };

  const getInputText = (option: AddressOptionType | string) => {
    if (typeof option === 'string') {
      return option;
    }

    const { mainText, secondaryText } = option || {};
    if (mainText && secondaryText) {
      return `${mainText} ${secondaryText}`;
    }

    return getAddressLocation(option as IAddress);
  };

  const checkOptionEqualToValue = (
    option: string | AddressOptionType,
    newValue: string | AddressOptionType,
  ) => {
    if (typeof option === 'string' || typeof newValue === 'string') {
      return option === newValue;
    }
    return `${option.mainText}` === `${newValue?.addressLabel}`;
  };

  const renderOption = (option: AddressOptionType) => {
    const { mainText, secondaryText } = option;
    const theme = useTheme();
    return (
      <Stack sx={{ ml: 1 }}>
        <Typography variant="subtitle2" color={theme.palette.text.primary}>
          {mainText}
        </Typography>
        <Typography variant="body2">{secondaryText}</Typography>
      </Stack>
    );
  };

  return (
    <Stack>
      <InputLabel>Address</InputLabel>
      <Box sx={{ position: 'relative' }}>
        <AddressSearch
          filterOptions={(filterOptions) => filterOptions}
          options={options}
          value={value}
          onChange={(_, selected) => {
            handleChange(selected);
          }}
          handleInputChange={handleInputChange}
          getOptionLabel={getInputText}
          renderOption={renderOption}
          isOptionEqualToValue={checkOptionEqualToValue}
          loading={loading}
        />
        <IconButton
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: 30,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {provider === 'google' ? <IcGoogle /> : <IcMileMarker />}
        </IconButton>
        <Menu
          id="provider-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ textAlign: 'left' }}
        >
          <MenuItem
            sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
            onClick={() => {
              setProvider('google');
              if (value) {
                updateCurrentAddress(null);
              }
              handleClose();
            }}
          >
            <IcGoogle />
            <Typography sx={{ marginLeft: 2 }}>Google - Default (*)</Typography>
          </MenuItem>
          <MenuItem
            sx={{ justifyContent: 'flex-start' }}
            onClick={() => {
              setProvider('milemarker');
              if (value) {
                updateCurrentAddress(null);
              }
              handleClose();
            }}
          >
            <IcMileMarker />
            <Typography sx={{ marginLeft: 2 }}>Mile Marker (~)</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <FormHelperText error>{error}</FormHelperText>
    </Stack>
  );
};
