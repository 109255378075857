import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcVisibility, IcPencil } from '@/assets/images';
import { DATE_FORMAT } from '@/config';
import { ArrestStatus, ArrestType, IArrestee } from '@/models';
import { ArrestStatusLabel, ArrestTypeLabel } from '@/pages/Kases/data';

const number = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.number || '-',
});

const masterName = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row.fullName || '-';
  },
  valueGetter: (_, row) => row.fullName,
});

const arrestDate = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.arrestDate
      ? moment(params.row.arrestDate).format(DATE_FORMAT)
      : '-',
});

const arrestType = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    ArrestTypeLabel[params.row.arrestType as ArrestType] || '-',
  valueGetter: (_, row) => ArrestTypeLabel[row.arrestType as ArrestType],
});

const arrestStatus = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    ArrestStatusLabel[params.row.arrestStatus as ArrestStatus] || '-',
  valueGetter: (_, row) => ArrestStatusLabel[row.arrestStatus as ArrestStatus],
});

const actions = (
  onView: ((arrestee: IArrestee) => void) | undefined,
  onEdit: ((arrestee: IArrestee) => void) | undefined,
  isReadOnly: boolean | undefined,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          onView?.(params.row);
        }}
      >
        <IcVisibility />
      </IconButton>
      {!isReadOnly && (
        <IconButton
          aria-label="edit"
          onClick={(e) => {
            e.stopPropagation();
            onEdit?.(params.row);
          }}
        >
          <IcPencil />
        </IconButton>
      )}
    </>
  ),
});

export const kaseArrestCols = (
  onView?: (arrestee: IArrestee) => void,
  onEdit?: (arrestee: IArrestee) => void,
  isReadOnly?: boolean,
) => {
  return {
    number,
    masterName,
    arrestDate,
    arrestType,
    arrestStatus,
    actions: () => actions(onView, onEdit, isReadOnly),
  };
};
