import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Divider, Grid } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';

import { CautionApi } from '@/api';
import { ConfirmationDialog, DataGrid, RenderFormField } from '@/components';
import { useDataGridColumns } from '@/components/DataGrid/hooks/useDataGridCols';
import { DataGridColSettings } from '@/components/DataGrid/utils';
import {
  cautionDataGridCols,
  cautionFormFields,
} from '@/components/Modals/CautionModal/data';
import { CautionFormRoot } from '@/components/Modals/CautionModal/styles';
import { LocalStoragePrefix } from '@/constants';
import { ICaution, ICautionForm, ICautionType } from '@/models';
import { useToastStore, useUserStore } from '@/store';
import { getLocalStorageItem } from '@/utils';

interface CautionForm extends ICautionForm {
  customCautionType: string;
}

interface CautionGridProps {
  addressId?: string;
}

const CautionDataGrid: FC<CautionGridProps> = ({ addressId }) => {
  const { user } = useUserStore();
  const { updateToast } = useToastStore();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [cautionTypes, setCautionTypes] = useState<ICautionType[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const methods = useForm<CautionForm>({ mode: 'all' });
  const { handleSubmit, watch, reset } = methods;
  const currentType = watch('type');

  const [columnSettings, setColumnSettings] = useState<DataGridColSettings[]>(
    () =>
      getLocalStorageItem({
        key: String(user?._id),
        prefix: `caution-${LocalStoragePrefix.TableSetting}`,
        shouldParse: true,
      }) || cautionDataGridCols,
  );

  const handleDelete = async (id: string) => {
    try {
      await CautionApi.deleteCaution(id);
      setRefreshKey((prev) => prev + 1);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onSubmit = async (values: CautionForm) => {
    try {
      await CautionApi.createCaution({
        comment: values.comment,
        type: values.type !== 'Other' ? values.type : values.customCautionType,
        address: addressId || '',
      });
      setRefreshKey((prev) => prev + 1);
      reset();

      if (values.type === 'Other') {
        await fetchCautionTypes();
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const cautionTypeItems = cautionTypes.map((cautionType) => ({
    label: cautionType.name,
    value: cautionType.name,
  }));

  const fetchCautionTypes = async () => {
    try {
      const res = await CautionApi.getCautionTypes();
      setCautionTypes([...res.data, { name: 'Other' }]);
    } catch (err: any) {
      updateToast({ open: err, message: err.message });
    }
  };

  const fetchCautions = useCallback(
    async (
      page: number,
      limit: number,
      filter: GridFilterModel | string,
    ): Promise<{ rows: ICaution[]; totalCount: number }> => {
      try {
        const filterObj =
          typeof filter === 'string' && filter
            ? JSON.parse(filter)
            : (filter as GridFilterModel) || {
                items: [],
                quickFilterValues: [],
              };

        const params = {
          page: page | 1,
          limit: limit | 100,
          filter: JSON.stringify({
            items: [
              {
                field: 'address',
                operator: 'equals',
                value: addressId,
              },
              ...(filterObj.items || []),
            ],
            logicOperator: 'and',
            quickFilterValues: filterObj.quickFilterValues || [],
            quickFilterLogicOperator: 'and',
          }),
          sort: JSON.stringify({ createdAt: -1 }),
        };

        const response = await CautionApi.getPaginatedCautions(params);
        const { data } = response;
        return {
          rows: data.docs,
          totalCount: data.totalDocs,
        };
      } catch (err: any) {
        updateToast({ open: true, message: err.message });
        return { rows: [], totalCount: 0 };
      }
    },
    [addressId, updateToast],
  );

  const columns = useDataGridColumns({
    type: 'caution',
    columnSettings,
    onDelete: (id: string) => {
      setSelectedId(id);
      setShowConfirm(true);
    },
  });

  useEffect(() => {
    fetchCautionTypes();
  }, [addressId]);

  return (
    <Box>
      <FormProvider {...methods}>
        <CautionFormRoot onSubmit={handleSubmit(onSubmit)}>
          <Grid columnSpacing={2} flex={1} container>
            {cautionFormFields.map((field) => {
              if (currentType !== 'Other' && field.name === 'customCautionType')
                return null;

              return (
                <Grid
                  key={field.name}
                  xs={
                    currentType === 'Other' && field.name === 'comment'
                      ? 12
                      : field.styles.xs
                  }
                  item
                >
                  <RenderFormField {...field} items={cautionTypeItems} />
                </Grid>
              );
            })}
          </Grid>
          <Button
            variant="contained"
            type="submit"
            color="error"
            size="large"
            fullWidth
            sx={{ mt: 2.5, ml: 2, flex: 0 }}
          >
            Add
          </Button>
        </CautionFormRoot>
        <Divider />
        <Box height={350}>
          <DataGrid
            key={refreshKey}
            name="caution"
            fetchData={fetchCautions}
            columnSettings={columnSettings}
            setColumnSettings={setColumnSettings}
            columns={columns}
            hideFilter
          />
        </Box>
      </FormProvider>
      <ConfirmationDialog
        title="Delete Caution?"
        description="Warning! You are about to delete this caution, are you sure?"
        confirmText="Delete"
        open={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
          setSelectedId('');
        }}
        onConfirm={() => {
          if (selectedId) handleDelete(selectedId);
          setShowConfirm(false);
        }}
      />
    </Box>
  );
};

export default CautionDataGrid;
