import { ChangeEvent, KeyboardEvent, useState, useEffect } from 'react';

import { IconButton } from '@mui/material';

import { IcArrowCircleRight, IcLocationIcon } from '@/assets/images';

import { Input } from '../../FormElements/Input';

interface AdditionalInfoInputProps {
  label?: string;
  placeholder?: string;
  onSubmit: (value: string, isEditing: boolean) => void;
  initialValue?: string;
  isEditing?: boolean;
  isFirstResponderView?: boolean;
}

export const AdditionalInfoInput = (props: AdditionalInfoInputProps) => {
  const {
    onSubmit,
    initialValue = '',
    isEditing = false,
    isFirstResponderView,
    ...rest
  } = props;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value) onSubmit(value, isEditing);
    if (!isEditing) {
      setValue('');
    }
  };

  const sendInputIcon = () => {
    if (isFirstResponderView) {
      return (
        <IconButton onClick={handleSubmit} disabled={!value}>
          <IcLocationIcon />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={handleSubmit} disabled={!value}>
        <IcArrowCircleRight />
      </IconButton>
    );
  };

  return (
    <Input
      name="additionalInfo"
      value={value}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      endAdornment={sendInputIcon()}
      {...rest}
      sx={{ width: '100%', paddingRight: 0.25, lineHeight: '24px' }}
      fullWidth
    />
  );
};
