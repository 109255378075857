import { useState } from 'react';

import { GridPaginationModel } from '@mui/x-data-grid-pro';

export const useGridPagination = (initialPageSize?: number) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | undefined>(initialPageSize);

  const handlePageChange = ({
    page: newPage,
    pageSize: newPageSize,
  }: GridPaginationModel) => {
    setPage(newPage + 1);
    setPageSize(newPageSize);
  };

  return {
    page,
    pageSize,
    handlePageChange,
  };
};
