import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserApi } from '@/api';
import { Page } from '@/components';
import { IUser } from '@/models';
import { IDevice } from '@/models/device';
import { EmployeeDevicesTable } from '@/pages/Employees/components/EmployeeDevicesTable';
import { useToastStore } from '@/store';

export const EmployeeDevices = () => {
  const { employeeId } = useParams();
  const { updateToast } = useToastStore();

  const [devices, setDevices] = useState<IDevice[]>([]);
  const [currentEmployee, setCurrentEmployee] = useState<IUser>();

  const fetchEmployeeData = async () => {
    try {
      const res = await UserApi.getOne(employeeId || '');
      setCurrentEmployee(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const fetchDeviceData = async () => {
    if (!employeeId) {
      return;
    }

    const response = await UserApi.getUserDevices(employeeId, {});
    setDevices(response.data.results);
  };

  useEffect(() => {
    if (employeeId) fetchEmployeeData();
  }, [employeeId]);

  useEffect(() => {
    fetchDeviceData();
  }, [employeeId]);

  return (
    <Page
      title={`Employee - ${currentEmployee?.firstName} ${currentEmployee?.lastName} - ${devices.length} devices`}
    >
      <EmployeeDevicesTable devices={devices} />
    </Page>
  );
};
