import {
  ArrestStatus,
  ArrestType,
  AssaultHomicideCircumstance,
  AttemptIndicator,
  BiasMotivation,
  KaseStatus,
  CriminalActivityGangInformationType,
  JustifiableHomicideCircumstance,
  JuvenileReleaseStatus,
  LarcenyType,
  LocationType,
  MethodEntry,
  OffenderUsedComputerOrHandheldDevice,
  VictimRelationship,
  WeaponForce,
} from '@/models';
import { colors } from '@/theme/variables';

export const AttemptIndicatorLabel = {
  [AttemptIndicator.ATTEMPTED]: 'Attempted',
  [AttemptIndicator.COMPLETED]: 'Completed',
};

export const LarcenyTypeLabel = {
  [LarcenyType.POCKET_PICKING]: 'Pocket-Picking',
  [LarcenyType.PURSE_SNATCHING]: 'Purse-Snatching',
  [LarcenyType.SHOPLIFTING]: 'Shoplifting',
  [LarcenyType.THEFT_FROM_BUILDING]: 'Theft from Building',
  [LarcenyType.THEFT_FROM_COIN_OPERATED_MACHINE]:
    'Theft from Coin-Operated Machine or Device',
  [LarcenyType.THEFT_FROM_MOTOR_VEHICLE]:
    'Theft from Motor Vehicle (except Theft of Motor Vehicle Parts or Accessories)',
  [LarcenyType.THEFT_OF_MOTOR_VEHICLE_PARTS]:
    'Theft of Motor Vehicle Parts/Accessories',
  [LarcenyType.THEFT_FROM_MAILBOX]: 'Theft from Mailbox',
  [LarcenyType.ALL_OTHER_LARCENY_OFFENSES]: 'All Other Larceny Offenses',
  [LarcenyType.MOTOR_VEHICLE_THEFT]: 'Motor Vehicle Theft',
  [LarcenyType.NOT_APPLICABLE]: 'Not applicable',
  [LarcenyType.NOT_REPORTED]: 'Not reported',
  [LarcenyType.UNKNOWN]: 'Unknown',
};

export const LocationTypeLabel = {
  [LocationType.SINGLE_FAMILY_HOME]: 'Single Family Home',
  [LocationType.MULTIPLE_DWELLING]: 'Multiple Dwelling',
  [LocationType.RESIDENTIAL_FACILITY]: 'Residential Facility',
  [LocationType.OTHER_RESIDENTIAL]: 'Other Residential',
  [LocationType.GARAGE_SHED]: 'Garage/Shed',
  [LocationType.SHELTER]: 'Shelter (Mission/Homeless)',
  [LocationType.TRANSIT_FACILITY]: 'Transit Facility',
  [LocationType.GOVERNMENT_OFFICE]: 'Government Office',
  [LocationType.SCHOOL]: 'School',
  [LocationType.COLLEGE]: 'College',
  [LocationType.CHURCH]: 'Church',
  [LocationType.HOSPITAL]: 'Hospital',
  [LocationType.JAIL_PRISON]: 'Jail/Prison',
  [LocationType.PARKING_GARAGES]: 'Parking Garage',
  [LocationType.OTHER_PUBLIC_ACCESS_BUILDING]: 'Other Public Access Building',
  [LocationType.COMMUNITY_CENTER]: 'Community Center',
  [LocationType.DAYCARE_FACILITY]: 'Daycare Facility',
  [LocationType.AUTO_SHOP]: 'Auto Shop',
  [LocationType.FINANCIAL_INSTITUTION]: 'Financial Institution',
  [LocationType.BARBER_BEAUTY_SHOP]: 'Barber/Beauty Shop',
  [LocationType.HOTEL_MOTEL]: 'Hotel/Motel',
  [LocationType.DRY_CLEANERS_LAUNDRY]: 'Dry Cleaners/Laundry',
  [LocationType.PROFESSIONAL_OFFICE]: 'Professional Office',
  [LocationType.DOCTOR_OFFICE]: "Doctor's Office",
  [LocationType.OTHER_BUSINESS_OFFICE]: 'Other Business Office',
  [LocationType.AMUSEMENT_CENTER]: 'Amusement Center',
  [LocationType.RENTAL_STORAGE_FACILITY]: 'Rental Storage Facility',
  [LocationType.OTHER_COMMERCIAL_SERVICE_LOCATION]:
    'Other Commercial Service Location',
  [LocationType.ATM_SEPARATE_FROM_BANK]: 'ATM Separate from Bank',
  [LocationType.BAR]: 'Bar',
  [LocationType.BUY_SELL_TRADE_SHOP]: 'Buy/Sell/Trade Shop',
  [LocationType.RESTAURANT]: 'Restaurant',
  [LocationType.GAS_STATION]: 'Gas Station',
  [LocationType.AUTO_SALES_LOT]: 'Auto Sales Lot',
  [LocationType.JEWELRY_STORE]: 'Jewelry Store',
  [LocationType.CLOTHING_STORE]: 'Clothing Store',
  [LocationType.DRUG_STORE]: 'Drug Store',
  [LocationType.LIQUOR_STORE]: 'Liquor Store',
  [LocationType.SHOPPING_MALL]: 'Shopping Mall',
  [LocationType.SPORTING_GOODS]: 'Sporting Goods',
  [LocationType.GROCERY_SUPERMARKET]: 'Grocery/Supermarket',
  [LocationType.VARIETY_CONVENIENCE_STORE]: 'Variety/Convenience Store',
  [LocationType.DEPARTMENT_DISCOUNT_STORE]: 'Department/Discount Store',
  [LocationType.OTHER_RETAIL_STORE]: 'Other Retail Store',
  [LocationType.FACTORY_MILL_PLANT]: 'Factory/Mill/Plant',
  [LocationType.OTHER_BUILDING]: 'Other Building',
  [LocationType.YARD]: 'Yard',
  [LocationType.CONSTRUCTION_SITE]: 'Construction Site',
  [LocationType.LAKE_WATERWAY]: 'Lake/Waterway',
  [LocationType.FIELD_WOODS]: 'Field/Woods',
  [LocationType.STREET]: 'Street',
  [LocationType.PARKING_LOT]: 'Parking Lot',
  [LocationType.PARK_PLAYGROUND]: 'Park/Playground',
  [LocationType.CEMETERY]: 'Cemetery',
  [LocationType.PUBLIC_TRANSIT_VEHICLE]: 'Public Transit Vehicle',
  [LocationType.OTHER_OUTSIDE_LOCATION]: 'Other Outside Location',
  [LocationType.REST_AREA]: 'Rest Area',
  [LocationType.CAMP_CAMPGROUND]: 'Camp/Campground',
  [LocationType.TRIBAL_LANDS]: 'Tribal Lands',
  [LocationType.CYBERSPACE]: 'Cyberspace',
  [LocationType.ABANDONED_CONDEMNED_STRUCTURE]: 'Abandoned/Condemned Structure',
  [LocationType.AREA_STADIUM_FAIRGROUNDS_COLISEUM]:
    'Arena/Stadium/Fairgrounds/Coliseum',
  [LocationType.DOCK_WHARF_FREIGHT_MODAL_TERMINAL]:
    'Dock/Wharf/Freight Terminal/Modal Terminal',
  [LocationType.FARM_FACILITY]: 'Farm Facility',
  [LocationType.GAMBLING_FACILITY_CASINO_RACETRACK]:
    'Gambling Facility/Casino/Racetrack',
  [LocationType.MILITARY_INSTALLATION]: 'Military Installation',
  [LocationType.NOT_REPORTED]: 'NOT REPORTED',
  [LocationType.UNKNOWN]: 'UNKNOWN',
};

export const WeaponForceLabel = {
  [WeaponForce.FIREARM]: 'Firearm',
  [WeaponForce.REVOLVER_DERRINGER_SINGLE_SHOT_PISTOL]:
    'Revolver/Derringer/Single-Shot Pistol',
  [WeaponForce.SEMIAUTOMATIC_HANDGUN]: 'Semiautomatic Handgun',
  [WeaponForce.FULLY_AUTOMATIC_HANDGUN_SUBMACHINE_GUN]:
    'Fully Automatic Handgun/Submachine Gun',
  [WeaponForce.SINGLE_SHOT_PUMP_ACTION_BOLT_ACTION_RIFLE]:
    'Single Shot/Pump Action/Bolt Action Rifle',
  [WeaponForce.SEMIAUTOMATIC_RIFLE]: 'Semi Automatic Rifle',
  [WeaponForce.FULLY_AUTOMATIC_RIFLE_MACHINE_GUN]:
    'Fully Automatic Rifle/Machine Gun',
  [WeaponForce.SHOTGUN]: 'Shotgun',
  [WeaponForce.LMITATION_FIREARM]: 'Imitation Firearm',
  [WeaponForce.SIMULATED_FIREARM]: 'Simulated Firearm',
  [WeaponForce.KNIFE_CUTTING_INSTRUMENT]: 'Knife/Cutting Instrument',
  [WeaponForce.BLUNT_OBJECT]: 'Blunt Object',
  [WeaponForce.MOTOR_VEHICLE_VESSEL]: 'Motor Vehicle/Vessel',
  [WeaponForce.PERSONAL_WEAPONS]: 'Personal Weapons',
  [WeaponForce.POISONS]: 'Poison',
  [WeaponForce.EXPLOSIVES]: 'Explosives',
  [WeaponForce.FIRE_INCENDIARY_DEVICE]: 'Fire/Incendiary Device',
  [WeaponForce.DRUG_NARCOTICS_SLEEPING_PILLS]: 'Drug/Narcotics/Sleeping Pills',
  [WeaponForce.OTHER_WEAPON]: 'Other Weapon',
  [WeaponForce.ASPHYXIATION]: 'Asphyxiation',
  [WeaponForce.NOT_APPLICABLE]: 'None/Not Applicable',
  [WeaponForce.UNKNOWN]: 'Unknown',
};

export const MethodEntryLabel = {
  [MethodEntry.FORCE]: 'Force',
  [MethodEntry.NO_FORCE]: 'No force',
  [MethodEntry.NOT_APPLICABLE]: 'Not applicable',
};

export const AssaultHomicideCircumstanceLabel = {
  [AssaultHomicideCircumstance.ARGUMENT]: 'Argument',
  [AssaultHomicideCircumstance.ASSAULT_ON_LAW_ENFORCEMENT_OFFICER]:
    'Assault on Law Enforcement Officer(s)',
  [AssaultHomicideCircumstance.DISPUTE_AMONG_DRUG_DEALERS]:
    'Dispute Among Drug Dealers',
  [AssaultHomicideCircumstance.DRUG_BUYER_DEALER_DISPUTE]:
    'Drug Buyer-Dealer Dispute',
  [AssaultHomicideCircumstance.ROBBERY_THEFT_FROM_DRUG_DEALER]:
    'Robbery/Theft from Drug Dealer',
  [AssaultHomicideCircumstance.DISPUTE_BETWEEN_DRUG_DEALER_AND_WITNESS_COMPLAINANT]:
    'Dispute Between Drug Dealer and Witness/Complainant ',
  [AssaultHomicideCircumstance.OTHER_DRUG_RELATED]: 'Other Drug-Related',
  [AssaultHomicideCircumstance.GANGLAND]: 'Gangland',
  [AssaultHomicideCircumstance.JUVENILE_GANG]: 'Juvenile Gang',
  [AssaultHomicideCircumstance.DOMESTIC_VIOLENCE]: 'Domestic Violence',
  [AssaultHomicideCircumstance.MERCY_KILLING]: 'Mercy Killing',
  [AssaultHomicideCircumstance.OTHER_FELONY_INVOLVED]:
    'Other Felony Involved  ',
  [AssaultHomicideCircumstance.OTHER_CIRCUMSTANCES]: 'Other Circumstances',
  [AssaultHomicideCircumstance.UNKNOWN_CIRCUMSTANCES]: 'Unknown Circumstances',
  [AssaultHomicideCircumstance.CHILD_PLAYING_WITH_WEAPON]:
    'Child Playing with Weapon',
  [AssaultHomicideCircumstance.GUN_CLEANING_ACCIDENT]: 'Gun Cleaning Accident',
  [AssaultHomicideCircumstance.HUNTING_ACCIDENT]: 'Hunting Accident',
  [AssaultHomicideCircumstance.OTHER_NEGLIGENT_WEAPON_HANDLING]:
    'Other Negligent Weapon Handling',
  [AssaultHomicideCircumstance.NEGLIGENT_VEHICULAR_KILLINGS]:
    'Negligent Vehicular Killings',
  [AssaultHomicideCircumstance.OTHER_NEGLIGENT_KILLINGS]:
    'Other Negligent Killings',
  [AssaultHomicideCircumstance.NOT_APPLICABLE]: 'Not Applicable',
};

export const JustifiableHomicideCircumstanceLabel = {
  [JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_THAT_OFFICER_KILLED_CRIMINAL]:
    'Criminal Attacked Police Officer and That Officer Killed Criminal',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_ANOTHER_POLICE_OFFICER]:
    'Criminal Attacked Police Officer and Criminal Killed by Another Police Officer',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN]:
    'Criminal Attacked Civilian',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME]:
    'Criminal Attempted Flight from Crime',
  [JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME]:
    'Criminal Killed in Commission of Crime',
  [JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST]:
    'Criminal Resisted Arrest  ',
  [JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION]:
    'Unable to Determine/Not Enough Information',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN_AND_KILLED_BY_PC]:
    'Criminal Attacked Civilian and Killed by PC',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME_AND_KILLED_BY_PC]:
    'Criminal Attempted Flight from Crime  and Killed by PC',
  [JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME_AND_KILLED_BY_PC]:
    'Criminal Killed in Commission of Crime and Killed by PC',
  [JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST_AND_KILLED_BY_PC]:
    'Criminal Resisted Arrest and Killed by PC',
  [JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION_KILLED_BY_PC]:
    'Unable to Determine/Not Enough Information',
  [JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_CIVILIAN_AIDING_POLICE]:
    'Criminal Attacked Police Officer and Criminal Killed by Civilian Aiding Police',
  [JustifiableHomicideCircumstance.NOT_APPLICABLE]: 'Not Applicable',
};

export const OffenderUsedComputerOrHandheldDeviceLabel = {
  [OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_NOT_USED]:
    'Computer/handheld device not used to commit offense',
  [OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_USED]:
    'Offender used computer/handheld device to commit offense',
  [OffenderUsedComputerOrHandheldDevice.NOT_REPORTED]: 'Not reported',
};

export const CriminalActivityGangInformationTypeLabel = {
  [CriminalActivityGangInformationType.SIMPLE_GROSS_NEGLECT]:
    'Simple/Gross Neglect',
  [CriminalActivityGangInformationType.BUYING_RECEIVING]: 'Buying/Receiving',
  [CriminalActivityGangInformationType.CULTIVATING_MANUFACTURING_PUBLISHING]:
    'Cultivating/Manufacturing/Publishing',
  [CriminalActivityGangInformationType.DISTRIBUTION_SELLING]:
    'Distribution/Selling',
  [CriminalActivityGangInformationType.EXPLOITING_CHILDREN]:
    'Exploiting Children',
  [CriminalActivityGangInformationType.ORGANIZED_ABUSE]: 'Organized Abuse',
  [CriminalActivityGangInformationType.INTENTIONAL_ABUSE_TORTURE]:
    'Intentional Abuse/Torture',
  [CriminalActivityGangInformationType.OPERATING_PROMOTING_ASSISTING]:
    'Operating/Promoting/Assisting',
  [CriminalActivityGangInformationType.POSSESSING_CONCEALING]:
    'Possessing/Concealing',
  [CriminalActivityGangInformationType.ANIMAL_SEXUAL_ABUSE]:
    'Animal Sexual Abuse',
  [CriminalActivityGangInformationType.TRANSPORTING_TRANSMITTING_IMPORTING]:
    'Transporting/Transmitting/Importing',
  [CriminalActivityGangInformationType.USING_CONSUMING]: 'Using/Consuming',
  [CriminalActivityGangInformationType.NOT_APPLICABLE]: 'Not Applicable',
  [CriminalActivityGangInformationType.JUVENILE_GANG]: 'Juvenile Gang',
  [CriminalActivityGangInformationType.OTHER_GANG]: 'Other Gang',
  [CriminalActivityGangInformationType.UNKNOWN]: 'None/Unknown',
};

export const BiasMotivationLabel = {
  [BiasMotivation.ANTI_WHITE]: 'Anti-White',
  [BiasMotivation.ANTI_BLACK_AFRICAN_AMERICAN]: 'Anti-Black/African American',
  [BiasMotivation.ANTI_AMERICAN_INDIAN_ALASKA_NATIVE]:
    'Anti-American Indian/Alaskan Native',
  [BiasMotivation.ANTI_ASIAN]: 'Anti-Asian',
  [BiasMotivation.ANTI_MULTIPLE_RACES_GROUP]: 'Anti-Multiple Races (Group)',
  [BiasMotivation.ANTI_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER]:
    'Anti-Native Hawaiian/Pacific Islander',
  [BiasMotivation.ANTI_RELIGIOUS_PRACTICE]:
    'Anti-Religious Practice (Generally)',
  [BiasMotivation.ANTI_JEWISH]: 'Anti-Jewish',
  [BiasMotivation.ANTI_ROMAN_CATHOLIC]: 'Anti-Roman Catholic',
  [BiasMotivation.ANTI_PROTESTANT]: 'Anti-Protestant',
  [BiasMotivation.ANTI_ISLAMIC]: 'Anti-Islamic (Muslim)',
  [BiasMotivation.ANTI_MULTIPLE_RELIGIONS_GROUP]:
    'Anti-Multiple Religions (Group)',
  [BiasMotivation.ANTI_ATHEISM_AGNOSTICISM]: 'Anti-Atheism/Agnosticism',
  [BiasMotivation.ANTI_MORMON]: 'Anti-Mormon',
  [BiasMotivation.ANTI_OTHER_RELIGION]: 'Anti-Other Religion',
  [BiasMotivation.ANTI_JEHOVAH_WITNESS]: 'Anti-Jehovah’s Witness',
  [BiasMotivation.ANTI_EASTERN_ORTHODOX]:
    'Anti-Eastern Orthodox (Russian/Greek/Other)',
  [BiasMotivation.ANTI_OTHER_CHRISTIAN]: 'Anti-Other Christian',
  [BiasMotivation.ANTI_BUDDHIST]: 'Anti-Buddhist',
  [BiasMotivation.ANTI_HINDU]: 'Anti-Hindu',
  [BiasMotivation.ANTI_SIKH]: 'Anti-Sikh',
  [BiasMotivation.ANTI_HISPANIC_LATINO]: 'Anti-Hispanic/Latino',
  [BiasMotivation.ANTI_ARAB]: 'Anti-Arab',
  [BiasMotivation.ANTI_OTHER_RACE_ETHNICITY_ANCESTRY]:
    'Anti-Other Ethnicity/National Origin/Ancestry',
  [BiasMotivation.ANTI_NOT_HISPANIC_LATINO]: 'Anti-Not Hispanic/Latino',
  [BiasMotivation.ANTI_OTHER_ORIGIN]: 'Anti-Other Origin',
  [BiasMotivation.ANTI_GAY]: 'Anti-Gay',
  [BiasMotivation.ANTI_LESBIAN]: 'Anti-Lesbian',
  [BiasMotivation.ANTI_LESBIAN_GAY_BISEXUAL_TRANSGENDER]:
    'Anti-Lesbian/Gay/Bisexual/Transgender (Mixed Group)',
  [BiasMotivation.ANTI_HETEROSEXUAL]: 'Anti-Heterosexual',
  [BiasMotivation.ANTI_BISEXUAL]: 'Anti-Bisexual',
  [BiasMotivation.ANTI_OLD_AGE]: 'Anti-Age (60 years old or more)',
  [BiasMotivation.ANTI_PHYSICAL_DISABILITY]: 'Anti-Physical Disability',
  [BiasMotivation.ANTI_MENTAL_DISABILITY]: 'Anti-Mental Disability',
  [BiasMotivation.ANTI_MALE]: 'Anti-Male',
  [BiasMotivation.ANTI_FEMALE]: 'Anti-Female',
  [BiasMotivation.ANTI_TRANSGENDER]: 'Anti-Transgender',
  [BiasMotivation.ANTI_GENDER_NON_CONFORMING]: 'Anti-Gender Non-Conforming',
  [BiasMotivation.NOT_APPLICABLE]: 'No Bias/Not Applicable',
  [BiasMotivation.NOT_REPORTED_BY_AGENCY]: 'Not Reported by Agency',
  [BiasMotivation.UNKNOWN]: 'Motivation of Perpetrator(s) Unknown',
};

export const KaseStatusList = {
  [KaseStatus.OPEN]: {
    label: 'Open',
    bgColor: colors.warning.main,
    color: colors.white,
  },
  [KaseStatus.WAITING_FOR_SUPERVISOR]: {
    label: 'Waiting for approval',
    bgColor: colors.primary.main,
    color: colors.white,
  },
  [KaseStatus.APPROVED_BY_SUPERVISOR]: {
    label: 'Approved',
    bgColor: colors.secondary.main,
    color: colors.white,
  },
  [KaseStatus.REJECTED]: {
    label: 'Rejected',
    bgColor: colors.error.main,
    color: colors.white,
  },
  [KaseStatus.ARCHIVED]: {
    label: 'Archived',
    bgColor: colors.itemGrey,
    color: colors.grey[80],
  },
};

export const VictimRelationshipLabel = {
  [VictimRelationship.SPOUSE]: 'Spouse',
  [VictimRelationship.FORMER_INTIMATE_PARTNER]: 'Former Intimate Partner',
  [VictimRelationship.PARENT]: 'Parent',
  [VictimRelationship.SIBLING]: 'Sibling',
  [VictimRelationship.CHILD]: 'Child',
  [VictimRelationship.GRANDPARENT]: 'Grandparent',
  [VictimRelationship.GRANDCHILD]: 'Grandchild',
  [VictimRelationship.IN_LAW]: 'In-Law',
  [VictimRelationship.STEPPARENT]: 'Stepparent',
  [VictimRelationship.STEPCHILD]: 'Stepchild',
  [VictimRelationship.STEP_SIBLING]: 'Stepsibling',
  [VictimRelationship.OTHER_FAMILY]: 'Other Family',
  [VictimRelationship.CURRENT_BOYFRIEND_GIRLFRIEND]:
    'Current Boyfriend/Girlfriend',
  [VictimRelationship.CHILD_OF_INTIMATE_PARTNER]: 'Child of Intimate Partner',
  [VictimRelationship.CURRENT_SAME_SEX_RELATIONSHIP]:
    'Current Same-Sex Relationship',
  [VictimRelationship.EX_SPOUSE]: 'Ex-Spouse',
  [VictimRelationship.ACQUAINTANCE]: 'Acquaintance',
  [VictimRelationship.FRIEND]: 'Baby/Child in care of babysitter',
  [VictimRelationship.NEIGHBOR]: 'Employee',
  [VictimRelationship.BABY_CHILD]: 'Employer',
  [VictimRelationship.EMPLOYEE]: 'Friend',
  [VictimRelationship.EMPLOYER]: 'Neighbor',
  [VictimRelationship.OTHERWISE_KNOWN]: 'Otherwise Known',
  [VictimRelationship.RELATIONSHIP_UNKNOWN]: 'Relationship Unknown',
  [VictimRelationship.STRANGER]: 'Stranger',
  [VictimRelationship.VICTIM_WAS_OFFENDER]: 'Victim Was Offender',
  [VictimRelationship.NOT_APPLICABLE]: 'Not Applicable',
  [VictimRelationship.NOT_REPORTED]: 'Not Reported',
};

export const ArrestTypeLabel = {
  [ArrestType.COMPLAINT]: 'Complaint',
  [ArrestType.CRIME_IN_PROGRESS]: 'Crime in Progress',
  [ArrestType.COURT_SUMMONS]: 'Court Summons',
  [ArrestType.ORDER_OF_PROTECTION]: 'Order of Protection',
  [ArrestType.OTHER]: 'Other',
};

export const ArrestStatusLabel = {
  [ArrestStatus.HELD]: 'Held',
  [ArrestStatus.APPEARANCE_TICKET]: 'Appearance Ticket',
  [ArrestStatus.ROR]: 'ROR',
  [ArrestStatus.CASH_BAIL]: 'Cash Bail',
  [ArrestStatus.BAIL_BOND]: 'Bail Bond',
  [ArrestStatus.POLICE_BAIL]: 'Police Bail',
  [ArrestStatus.RELEASED_TO_THIRD_PARTY]: 'Released to Third Party',
  [ArrestStatus.NOT_REPORTED]: 'Not Reported',
  [ArrestStatus.UNKNOWN]: 'Unknown',
};

export const JuvenileReleaseStatusLabel = {
  [JuvenileReleaseStatus.HANDLED_WITHIN_DEPARTMENT]:
    'Handled Within Department',
  [JuvenileReleaseStatus.REFERRED_TO_CRIMINAL_FAMILY_COURT_PROBATION_INTAKE]:
    'Referred to Criminal Court, Juvenile/Family Court, or Probation Intake',
  [JuvenileReleaseStatus.DIVERTED_TO_COUNSELING_TREATMENT_PROGRAM]:
    'Diverted to Counseling/Treatment Program',
  [JuvenileReleaseStatus.NOT_APPLICABLE]: 'Not Applicable',
};

export const AttemptIndicatorSelectItems = [
  {
    label: AttemptIndicatorLabel[AttemptIndicator.ATTEMPTED],
    value: AttemptIndicator.ATTEMPTED,
  },
  {
    label: AttemptIndicatorLabel[AttemptIndicator.COMPLETED],
    value: AttemptIndicator.COMPLETED,
  },
];

export const LarcenyTypeSelectItems = [
  {
    label: `${LarcenyType.POCKET_PICKING} = ${
      LarcenyTypeLabel[LarcenyType.POCKET_PICKING]
    }`,
    value: LarcenyType.POCKET_PICKING,
  },
  {
    label: `${LarcenyType.PURSE_SNATCHING} = ${
      LarcenyTypeLabel[LarcenyType.PURSE_SNATCHING]
    }`,
    value: LarcenyType.PURSE_SNATCHING,
  },
  {
    label: `${LarcenyType.SHOPLIFTING} = ${
      LarcenyTypeLabel[LarcenyType.SHOPLIFTING]
    }`,
    value: LarcenyType.SHOPLIFTING,
  },
  {
    label: `${LarcenyType.THEFT_FROM_BUILDING} = ${
      LarcenyTypeLabel[LarcenyType.THEFT_FROM_BUILDING]
    }`,
    value: LarcenyType.THEFT_FROM_BUILDING,
  },
  {
    label: `${LarcenyType.THEFT_FROM_COIN_OPERATED_MACHINE} = ${
      LarcenyTypeLabel[LarcenyType.THEFT_FROM_COIN_OPERATED_MACHINE]
    }`,
    value: LarcenyType.THEFT_FROM_COIN_OPERATED_MACHINE,
  },
  {
    label: `${LarcenyType.THEFT_FROM_MOTOR_VEHICLE} = ${
      LarcenyTypeLabel[LarcenyType.THEFT_FROM_MOTOR_VEHICLE]
    }`,
    value: LarcenyType.THEFT_FROM_MOTOR_VEHICLE,
  },
  {
    label: `${LarcenyType.THEFT_OF_MOTOR_VEHICLE_PARTS} = ${
      LarcenyTypeLabel[LarcenyType.THEFT_OF_MOTOR_VEHICLE_PARTS]
    }`,
    value: LarcenyType.THEFT_OF_MOTOR_VEHICLE_PARTS,
  },
  {
    label: `${LarcenyType.THEFT_FROM_MAILBOX} = ${
      LarcenyTypeLabel[LarcenyType.THEFT_FROM_MAILBOX]
    }`,
    value: LarcenyType.THEFT_FROM_MAILBOX,
  },
  {
    label: `${LarcenyType.ALL_OTHER_LARCENY_OFFENSES} = ${
      LarcenyTypeLabel[LarcenyType.ALL_OTHER_LARCENY_OFFENSES]
    }`,
    value: LarcenyType.ALL_OTHER_LARCENY_OFFENSES,
  },
  {
    label: `${LarcenyType.MOTOR_VEHICLE_THEFT} = ${
      LarcenyTypeLabel[LarcenyType.MOTOR_VEHICLE_THEFT]
    }`,
    value: LarcenyType.MOTOR_VEHICLE_THEFT,
  },
  {
    label: `${LarcenyType.NOT_APPLICABLE} = ${
      LarcenyTypeLabel[LarcenyType.NOT_APPLICABLE]
    }`,
    value: LarcenyType.NOT_APPLICABLE,
  },
  {
    label: `${LarcenyType.NOT_REPORTED} = ${
      LarcenyTypeLabel[LarcenyType.NOT_REPORTED]
    }`,
    value: LarcenyType.NOT_REPORTED,
  },
  {
    label: `${LarcenyType.UNKNOWN} = ${LarcenyTypeLabel[LarcenyType.UNKNOWN]}`,
    value: LarcenyType.UNKNOWN,
  },
];

export const LocationTypeSelectItems = [
  {
    group: 'RESIDENTIAL STRUCTURES',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.SINGLE_FAMILY_HOME} = ${
      LocationTypeLabel[LocationType.SINGLE_FAMILY_HOME]
    }`,
    value: LocationType.SINGLE_FAMILY_HOME,
  },
  {
    label: `${LocationType.MULTIPLE_DWELLING} = ${
      LocationTypeLabel[LocationType.MULTIPLE_DWELLING]
    }`,
    value: LocationType.MULTIPLE_DWELLING,
  },
  {
    label: `${LocationType.RESIDENTIAL_FACILITY} = ${
      LocationTypeLabel[LocationType.RESIDENTIAL_FACILITY]
    }`,
    value: LocationType.RESIDENTIAL_FACILITY,
  },
  {
    label: `${LocationType.OTHER_RESIDENTIAL} = ${
      LocationTypeLabel[LocationType.OTHER_RESIDENTIAL]
    }`,
    value: LocationType.OTHER_RESIDENTIAL,
  },
  {
    label: `${LocationType.GARAGE_SHED} = ${
      LocationTypeLabel[LocationType.GARAGE_SHED]
    }`,
    value: LocationType.GARAGE_SHED,
  },
  {
    label: `${LocationType.SHELTER} = ${
      LocationTypeLabel[LocationType.SHELTER]
    }`,
    value: LocationType.SHELTER,
  },
  {
    group: 'PUBLIC ACCESS BUILDINGS',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.TRANSIT_FACILITY} = ${
      LocationTypeLabel[LocationType.TRANSIT_FACILITY]
    }`,
    value: LocationType.TRANSIT_FACILITY,
  },
  {
    label: `${LocationType.GOVERNMENT_OFFICE} = ${
      LocationTypeLabel[LocationType.GOVERNMENT_OFFICE]
    }`,
    value: LocationType.GOVERNMENT_OFFICE,
  },
  {
    label: `${LocationType.SCHOOL} = ${LocationTypeLabel[LocationType.SCHOOL]}`,
    value: LocationType.SCHOOL,
  },
  {
    label: `${LocationType.COLLEGE} = ${
      LocationTypeLabel[LocationType.COLLEGE]
    }`,
    value: LocationType.COLLEGE,
  },
  {
    label: `${LocationType.CHURCH} = ${LocationTypeLabel[LocationType.CHURCH]}`,
    value: LocationType.CHURCH,
  },
  {
    label: `${LocationType.HOSPITAL} = ${
      LocationTypeLabel[LocationType.HOSPITAL]
    }`,
    value: LocationType.HOSPITAL,
  },
  {
    label: `${LocationType.JAIL_PRISON} = ${
      LocationTypeLabel[LocationType.JAIL_PRISON]
    }`,
    value: LocationType.JAIL_PRISON,
  },
  {
    label: `${LocationType.PARKING_GARAGES} = ${
      LocationTypeLabel[LocationType.PARKING_GARAGES]
    }`,
    value: LocationType.PARKING_GARAGES,
  },
  {
    label: `${LocationType.OTHER_PUBLIC_ACCESS_BUILDING} = ${
      LocationTypeLabel[LocationType.OTHER_PUBLIC_ACCESS_BUILDING]
    }`,
    value: LocationType.OTHER_PUBLIC_ACCESS_BUILDING,
  },
  {
    label: `${LocationType.COMMUNITY_CENTER} = ${
      LocationTypeLabel[LocationType.COMMUNITY_CENTER]
    }`,
    value: LocationType.COMMUNITY_CENTER,
  },
  {
    label: `${LocationType.DAYCARE_FACILITY} = ${
      LocationTypeLabel[LocationType.DAYCARE_FACILITY]
    }`,
    value: LocationType.DAYCARE_FACILITY,
  },
  {
    group: 'COMMERCIAL SERVICE LOCATIONS',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.AUTO_SHOP} = ${
      LocationTypeLabel[LocationType.AUTO_SHOP]
    }`,
    value: LocationType.AUTO_SHOP,
  },
  {
    label: `${LocationType.FINANCIAL_INSTITUTION} = ${
      LocationTypeLabel[LocationType.FINANCIAL_INSTITUTION]
    }`,
    value: LocationType.FINANCIAL_INSTITUTION,
  },
  {
    label: `${LocationType.BARBER_BEAUTY_SHOP} = ${
      LocationTypeLabel[LocationType.BARBER_BEAUTY_SHOP]
    }`,
    value: LocationType.BARBER_BEAUTY_SHOP,
  },
  {
    label: `${LocationType.HOTEL_MOTEL} = ${
      LocationTypeLabel[LocationType.HOTEL_MOTEL]
    }`,
    value: LocationType.HOTEL_MOTEL,
  },
  {
    label: `${LocationType.DRY_CLEANERS_LAUNDRY} = ${
      LocationTypeLabel[LocationType.DRY_CLEANERS_LAUNDRY]
    }`,
    value: LocationType.DRY_CLEANERS_LAUNDRY,
  },
  {
    label: `${LocationType.PROFESSIONAL_OFFICE} = ${
      LocationTypeLabel[LocationType.PROFESSIONAL_OFFICE]
    }`,
    value: LocationType.PROFESSIONAL_OFFICE,
  },
  {
    label: `${LocationType.DOCTOR_OFFICE} = ${
      LocationTypeLabel[LocationType.DOCTOR_OFFICE]
    }`,
    value: LocationType.DOCTOR_OFFICE,
  },
  {
    label: `${LocationType.OTHER_BUSINESS_OFFICE} = ${
      LocationTypeLabel[LocationType.OTHER_BUSINESS_OFFICE]
    }`,
    value: LocationType.OTHER_BUSINESS_OFFICE,
  },
  {
    label: `${LocationType.AMUSEMENT_CENTER} = ${
      LocationTypeLabel[LocationType.AMUSEMENT_CENTER]
    }`,
    value: LocationType.AMUSEMENT_CENTER,
  },
  {
    label: `${LocationType.RENTAL_STORAGE_FACILITY} = ${
      LocationTypeLabel[LocationType.RENTAL_STORAGE_FACILITY]
    }`,
    value: LocationType.RENTAL_STORAGE_FACILITY,
  },
  {
    label: `${LocationType.OTHER_COMMERCIAL_SERVICE_LOCATION} = ${
      LocationTypeLabel[LocationType.OTHER_COMMERCIAL_SERVICE_LOCATION]
    }`,
    value: LocationType.OTHER_COMMERCIAL_SERVICE_LOCATION,
  },
  {
    label: `${LocationType.ATM_SEPARATE_FROM_BANK} = ${
      LocationTypeLabel[LocationType.ATM_SEPARATE_FROM_BANK]
    }`,
    value: LocationType.ATM_SEPARATE_FROM_BANK,
  },
  {
    group: 'COMMERCIAL RETAIL LOCATIONS',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.BAR} = ${LocationTypeLabel[LocationType.BAR]}`,
    value: LocationType.BAR,
  },
  {
    label: `${LocationType.BUY_SELL_TRADE_SHOP} = ${
      LocationTypeLabel[LocationType.BUY_SELL_TRADE_SHOP]
    }`,
    value: LocationType.BUY_SELL_TRADE_SHOP,
  },
  {
    label: `${LocationType.RESTAURANT} = ${
      LocationTypeLabel[LocationType.RESTAURANT]
    }`,
    value: LocationType.RESTAURANT,
  },
  {
    label: `${LocationType.GAS_STATION} = ${
      LocationTypeLabel[LocationType.GAS_STATION]
    }`,
    value: LocationType.GAS_STATION,
  },
  {
    label: `${LocationType.AUTO_SALES_LOT} = ${
      LocationTypeLabel[LocationType.AUTO_SALES_LOT]
    }`,
    value: LocationType.AUTO_SALES_LOT,
  },
  {
    label: `${LocationType.JEWELRY_STORE} = ${
      LocationTypeLabel[LocationType.JEWELRY_STORE]
    }`,
    value: LocationType.JEWELRY_STORE,
  },
  {
    label: `${LocationType.CLOTHING_STORE} = ${
      LocationTypeLabel[LocationType.CLOTHING_STORE]
    }`,
    value: LocationType.CLOTHING_STORE,
  },
  {
    label: `${LocationType.DRUG_STORE} = ${
      LocationTypeLabel[LocationType.DRUG_STORE]
    }`,
    value: LocationType.DRUG_STORE,
  },
  {
    label: `${LocationType.LIQUOR_STORE} = ${
      LocationTypeLabel[LocationType.LIQUOR_STORE]
    }`,
    value: LocationType.LIQUOR_STORE,
  },
  {
    label: `${LocationType.SHOPPING_MALL} = ${
      LocationTypeLabel[LocationType.SHOPPING_MALL]
    }`,
    value: LocationType.SHOPPING_MALL,
  },
  {
    label: `${LocationType.SPORTING_GOODS} = ${
      LocationTypeLabel[LocationType.SPORTING_GOODS]
    }`,
    value: LocationType.SPORTING_GOODS,
  },
  {
    label: `${LocationType.GROCERY_SUPERMARKET} = ${
      LocationTypeLabel[LocationType.GROCERY_SUPERMARKET]
    }`,
    value: LocationType.GROCERY_SUPERMARKET,
  },
  {
    label: `${LocationType.VARIETY_CONVENIENCE_STORE} = ${
      LocationTypeLabel[LocationType.VARIETY_CONVENIENCE_STORE]
    }`,
    value: LocationType.VARIETY_CONVENIENCE_STORE,
  },
  {
    label: `${LocationType.DEPARTMENT_DISCOUNT_STORE} = ${
      LocationTypeLabel[LocationType.DEPARTMENT_DISCOUNT_STORE]
    }`,
    value: LocationType.DEPARTMENT_DISCOUNT_STORE,
  },
  {
    label: `${LocationType.OTHER_RETAIL_STORE} = ${
      LocationTypeLabel[LocationType.OTHER_RETAIL_STORE]
    }`,
    value: LocationType.OTHER_RETAIL_STORE,
  },
  {
    label: `${LocationType.FACTORY_MILL_PLANT} = ${
      LocationTypeLabel[LocationType.FACTORY_MILL_PLANT]
    }`,
    value: LocationType.FACTORY_MILL_PLANT,
  },
  {
    label: `${LocationType.OTHER_BUILDING} = ${
      LocationTypeLabel[LocationType.OTHER_BUILDING]
    }`,
    value: LocationType.OTHER_BUILDING,
  },
  {
    group: 'OUTSIDE',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.YARD} = ${LocationTypeLabel[LocationType.YARD]}`,
    value: LocationType.YARD,
  },
  {
    label: `${LocationType.CONSTRUCTION_SITE} = ${
      LocationTypeLabel[LocationType.CONSTRUCTION_SITE]
    }`,
    value: LocationType.CONSTRUCTION_SITE,
  },
  {
    label: `${LocationType.LAKE_WATERWAY} = ${
      LocationTypeLabel[LocationType.LAKE_WATERWAY]
    }`,
    value: LocationType.LAKE_WATERWAY,
  },
  {
    label: `${LocationType.FIELD_WOODS} = ${
      LocationTypeLabel[LocationType.FIELD_WOODS]
    }`,
    value: LocationType.FIELD_WOODS,
  },
  {
    label: `${LocationType.STREET} = ${LocationTypeLabel[LocationType.STREET]}`,
    value: LocationType.STREET,
  },
  {
    label: `${LocationType.PARKING_LOT} = ${
      LocationTypeLabel[LocationType.PARKING_LOT]
    }`,
    value: LocationType.PARKING_LOT,
  },
  {
    label: `${LocationType.PARK_PLAYGROUND} = ${
      LocationTypeLabel[LocationType.PARK_PLAYGROUND]
    }`,
    value: LocationType.PARK_PLAYGROUND,
  },
  {
    label: `${LocationType.CEMETERY} = ${
      LocationTypeLabel[LocationType.CEMETERY]
    }`,
    value: LocationType.CEMETERY,
  },
  {
    label: `${LocationType.PUBLIC_TRANSIT_VEHICLE} = ${
      LocationTypeLabel[LocationType.PUBLIC_TRANSIT_VEHICLE]
    }`,
    value: LocationType.PUBLIC_TRANSIT_VEHICLE,
  },
  {
    label: `${LocationType.OTHER_OUTSIDE_LOCATION} = ${
      LocationTypeLabel[LocationType.OTHER_OUTSIDE_LOCATION]
    }`,
    value: LocationType.OTHER_OUTSIDE_LOCATION,
  },
  {
    label: `${LocationType.REST_AREA} = ${
      LocationTypeLabel[LocationType.REST_AREA]
    }`,
    value: LocationType.REST_AREA,
  },
  {
    label: `${LocationType.CAMP_CAMPGROUND} = ${
      LocationTypeLabel[LocationType.CAMP_CAMPGROUND]
    }`,
    value: LocationType.CAMP_CAMPGROUND,
  },
  {
    group: 'OTHER',
    label: '',
    value: '',
  },
  {
    label: `${LocationType.TRIBAL_LANDS} = ${
      LocationTypeLabel[LocationType.TRIBAL_LANDS]
    }`,
    value: LocationType.TRIBAL_LANDS,
  },
  {
    label: `${LocationType.CYBERSPACE} = ${
      LocationTypeLabel[LocationType.CYBERSPACE]
    }`,
    value: LocationType.CYBERSPACE,
  },
  {
    label: `${LocationType.ABANDONED_CONDEMNED_STRUCTURE} = ${
      LocationTypeLabel[LocationType.ABANDONED_CONDEMNED_STRUCTURE]
    }`,
    value: LocationType.ABANDONED_CONDEMNED_STRUCTURE,
  },
  {
    label: `${LocationType.AREA_STADIUM_FAIRGROUNDS_COLISEUM} = ${
      LocationTypeLabel[LocationType.AREA_STADIUM_FAIRGROUNDS_COLISEUM]
    }`,
    value: LocationType.AREA_STADIUM_FAIRGROUNDS_COLISEUM,
  },
  {
    label: `${LocationType.DOCK_WHARF_FREIGHT_MODAL_TERMINAL} = ${
      LocationTypeLabel[LocationType.DOCK_WHARF_FREIGHT_MODAL_TERMINAL]
    }`,
    value: LocationType.DOCK_WHARF_FREIGHT_MODAL_TERMINAL,
  },
  {
    label: `${LocationType.FARM_FACILITY} = ${
      LocationTypeLabel[LocationType.FARM_FACILITY]
    }`,
    value: LocationType.FARM_FACILITY,
  },
  {
    label: `${LocationType.GAMBLING_FACILITY_CASINO_RACETRACK} = ${
      LocationTypeLabel[LocationType.GAMBLING_FACILITY_CASINO_RACETRACK]
    }`,
    value: LocationType.GAMBLING_FACILITY_CASINO_RACETRACK,
  },
  {
    label: `${LocationType.MILITARY_INSTALLATION} = ${
      LocationTypeLabel[LocationType.MILITARY_INSTALLATION]
    }`,
    value: LocationType.MILITARY_INSTALLATION,
  },
  {
    label: `${LocationType.NOT_REPORTED} = ${
      LocationTypeLabel[LocationType.NOT_REPORTED]
    }`,
    value: LocationType.NOT_REPORTED,
  },
  {
    label: `${LocationType.UNKNOWN} = ${
      LocationTypeLabel[LocationType.UNKNOWN]
    }`,
    value: LocationType.UNKNOWN,
  },
];

const WeaponForceSelectItems = [
  {
    label: `${WeaponForce.FIREARM} = ${WeaponForceLabel[WeaponForce.FIREARM]}`,
    value: WeaponForce.FIREARM,
  },
  {
    label: `${WeaponForce.REVOLVER_DERRINGER_SINGLE_SHOT_PISTOL} = ${
      WeaponForceLabel[WeaponForce.REVOLVER_DERRINGER_SINGLE_SHOT_PISTOL]
    }`,
    value: WeaponForce.REVOLVER_DERRINGER_SINGLE_SHOT_PISTOL,
  },
  {
    label: `${WeaponForce.SEMIAUTOMATIC_HANDGUN} = ${
      WeaponForceLabel[WeaponForce.SEMIAUTOMATIC_HANDGUN]
    }`,
    value: WeaponForce.SEMIAUTOMATIC_HANDGUN,
  },
  {
    label: `${WeaponForce.FULLY_AUTOMATIC_HANDGUN_SUBMACHINE_GUN} = ${
      WeaponForceLabel[WeaponForce.FULLY_AUTOMATIC_HANDGUN_SUBMACHINE_GUN]
    }`,
    value: WeaponForce.FULLY_AUTOMATIC_HANDGUN_SUBMACHINE_GUN,
  },
  {
    label: `${WeaponForce.SINGLE_SHOT_PUMP_ACTION_BOLT_ACTION_RIFLE} = ${
      WeaponForceLabel[WeaponForce.SINGLE_SHOT_PUMP_ACTION_BOLT_ACTION_RIFLE]
    }`,
    value: WeaponForce.SINGLE_SHOT_PUMP_ACTION_BOLT_ACTION_RIFLE,
  },
  {
    label: `${WeaponForce.SEMIAUTOMATIC_RIFLE} = ${
      WeaponForceLabel[WeaponForce.SEMIAUTOMATIC_RIFLE]
    }`,
    value: WeaponForce.SEMIAUTOMATIC_RIFLE,
  },
  {
    label: `${WeaponForce.FULLY_AUTOMATIC_RIFLE_MACHINE_GUN} = ${
      WeaponForceLabel[WeaponForce.FULLY_AUTOMATIC_RIFLE_MACHINE_GUN]
    }`,
    value: WeaponForce.FULLY_AUTOMATIC_RIFLE_MACHINE_GUN,
  },
  {
    label: `${WeaponForce.SHOTGUN} = ${WeaponForceLabel[WeaponForce.SHOTGUN]}`,
    value: WeaponForce.SHOTGUN,
  },
  {
    label: `${WeaponForce.LMITATION_FIREARM} = ${
      WeaponForceLabel[WeaponForce.LMITATION_FIREARM]
    }`,
    value: WeaponForce.LMITATION_FIREARM,
  },
  {
    label: `${WeaponForce.SIMULATED_FIREARM} = ${
      WeaponForceLabel[WeaponForce.SIMULATED_FIREARM]
    }`,
    value: WeaponForce.SIMULATED_FIREARM,
  },
  {
    label: `${WeaponForce.KNIFE_CUTTING_INSTRUMENT} = ${
      WeaponForceLabel[WeaponForce.KNIFE_CUTTING_INSTRUMENT]
    }`,
    value: WeaponForce.KNIFE_CUTTING_INSTRUMENT,
  },
  {
    label: `${WeaponForce.BLUNT_OBJECT} = ${
      WeaponForceLabel[WeaponForce.BLUNT_OBJECT]
    }`,
    value: WeaponForce.BLUNT_OBJECT,
  },
  {
    label: `${WeaponForce.MOTOR_VEHICLE_VESSEL} = ${
      WeaponForceLabel[WeaponForce.MOTOR_VEHICLE_VESSEL]
    }`,
    value: WeaponForce.MOTOR_VEHICLE_VESSEL,
  },
  {
    label: `${WeaponForce.PERSONAL_WEAPONS} = ${
      WeaponForceLabel[WeaponForce.PERSONAL_WEAPONS]
    }`,
    value: WeaponForce.PERSONAL_WEAPONS,
  },
  {
    label: `${WeaponForce.POISONS} = ${WeaponForceLabel[WeaponForce.POISONS]}`,
    value: WeaponForce.POISONS,
  },
  {
    label: `${WeaponForce.EXPLOSIVES} = ${
      WeaponForceLabel[WeaponForce.EXPLOSIVES]
    }`,
    value: WeaponForce.EXPLOSIVES,
  },
  {
    label: `${WeaponForce.FIRE_INCENDIARY_DEVICE} = ${
      WeaponForceLabel[WeaponForce.FIRE_INCENDIARY_DEVICE]
    }`,
    value: WeaponForce.FIRE_INCENDIARY_DEVICE,
  },
  {
    label: `${WeaponForce.DRUG_NARCOTICS_SLEEPING_PILLS} = ${
      WeaponForceLabel[WeaponForce.DRUG_NARCOTICS_SLEEPING_PILLS]
    }`,
    value: WeaponForce.DRUG_NARCOTICS_SLEEPING_PILLS,
  },
  {
    label: `${WeaponForce.OTHER_WEAPON} = ${
      WeaponForceLabel[WeaponForce.OTHER_WEAPON]
    }`,
    value: WeaponForce.OTHER_WEAPON,
  },
];

export const OffenseWeaponForceSelectItems = [
  ...WeaponForceSelectItems,
  {
    label: `${WeaponForce.ASPHYXIATION} = ${
      WeaponForceLabel[WeaponForce.ASPHYXIATION]
    }`,
    value: WeaponForce.ASPHYXIATION,
  },
  {
    label: `${WeaponForce.NOT_APPLICABLE} = ${
      WeaponForceLabel[WeaponForce.NOT_APPLICABLE]
    }`,
    value: WeaponForce.NOT_APPLICABLE,
  },
  {
    label: `${WeaponForce.UNKNOWN} = ${WeaponForceLabel[WeaponForce.UNKNOWN]}`,
    value: WeaponForce.UNKNOWN,
  },
];

export const ArresteeWeaponForceSelectItems = [
  ...WeaponForceSelectItems,
  {
    label: `${WeaponForce.NOT_APPLICABLE} = ${
      WeaponForceLabel[WeaponForce.NOT_APPLICABLE]
    }`,
    value: WeaponForce.NOT_APPLICABLE,
  },
];

export const MethodEntrySelectItems = [
  {
    label: `${MethodEntry.FORCE} = ${MethodEntryLabel[MethodEntry.FORCE]}`,
    value: MethodEntry.FORCE,
  },
  {
    label: `${MethodEntry.NO_FORCE} = ${
      MethodEntryLabel[MethodEntry.NO_FORCE]
    }`,
    value: MethodEntry.NO_FORCE,
  },
  {
    label: `${MethodEntry.NOT_APPLICABLE} = ${
      MethodEntryLabel[MethodEntry.NOT_APPLICABLE]
    }`,
    value: MethodEntry.NOT_APPLICABLE,
  },
];

export const AssaultHomicideCircumstance09A13ASelectItems = [
  {
    label: `${AssaultHomicideCircumstance.ARGUMENT} = ${
      AssaultHomicideCircumstanceLabel[AssaultHomicideCircumstance.ARGUMENT]
    }`,
    value: AssaultHomicideCircumstance.ARGUMENT,
  },
  {
    label: `${
      AssaultHomicideCircumstance.ASSAULT_ON_LAW_ENFORCEMENT_OFFICER
    } = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.ASSAULT_ON_LAW_ENFORCEMENT_OFFICER
      ]
    }`,
    value: AssaultHomicideCircumstance.ASSAULT_ON_LAW_ENFORCEMENT_OFFICER,
  },
  {
    label: `${AssaultHomicideCircumstance.DISPUTE_AMONG_DRUG_DEALERS} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.DISPUTE_AMONG_DRUG_DEALERS
      ]
    }`,
    value: AssaultHomicideCircumstance.DISPUTE_AMONG_DRUG_DEALERS,
  },
  {
    label: `${AssaultHomicideCircumstance.DRUG_BUYER_DEALER_DISPUTE} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.DRUG_BUYER_DEALER_DISPUTE
      ]
    }`,
    value: AssaultHomicideCircumstance.DRUG_BUYER_DEALER_DISPUTE,
  },
  {
    label: `${AssaultHomicideCircumstance.ROBBERY_THEFT_FROM_DRUG_DEALER} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.ROBBERY_THEFT_FROM_DRUG_DEALER
      ]
    }`,
    value: AssaultHomicideCircumstance.ROBBERY_THEFT_FROM_DRUG_DEALER,
  },
  {
    label: `${
      AssaultHomicideCircumstance.DISPUTE_BETWEEN_DRUG_DEALER_AND_WITNESS_COMPLAINANT
    } = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance
          .DISPUTE_BETWEEN_DRUG_DEALER_AND_WITNESS_COMPLAINANT
      ]
    }`,
    value:
      AssaultHomicideCircumstance.DISPUTE_BETWEEN_DRUG_DEALER_AND_WITNESS_COMPLAINANT,
  },
  {
    label: `${AssaultHomicideCircumstance.OTHER_DRUG_RELATED} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.OTHER_DRUG_RELATED
      ]
    }`,
    value: AssaultHomicideCircumstance.OTHER_DRUG_RELATED,
  },
  {
    label: `${AssaultHomicideCircumstance.GANGLAND} = ${
      AssaultHomicideCircumstanceLabel[AssaultHomicideCircumstance.GANGLAND]
    }`,
    value: AssaultHomicideCircumstance.GANGLAND,
  },
  {
    label: `${AssaultHomicideCircumstance.JUVENILE_GANG} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.JUVENILE_GANG
      ]
    }`,
    value: AssaultHomicideCircumstance.JUVENILE_GANG,
  },
  {
    label: `${AssaultHomicideCircumstance.DOMESTIC_VIOLENCE} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.DOMESTIC_VIOLENCE
      ]
    }`,
    value: AssaultHomicideCircumstance.DOMESTIC_VIOLENCE,
  },
  {
    label: `${AssaultHomicideCircumstance.MERCY_KILLING} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.MERCY_KILLING
      ]
    }`,
    value: AssaultHomicideCircumstance.MERCY_KILLING,
  },
  {
    label: `${AssaultHomicideCircumstance.OTHER_FELONY_INVOLVED} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.OTHER_FELONY_INVOLVED
      ]
    }`,
    value: AssaultHomicideCircumstance.OTHER_FELONY_INVOLVED,
  },
  {
    label: `${AssaultHomicideCircumstance.OTHER_CIRCUMSTANCES} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.OTHER_CIRCUMSTANCES
      ]
    }`,
    value: AssaultHomicideCircumstance.OTHER_CIRCUMSTANCES,
  },
  {
    label: `${AssaultHomicideCircumstance.UNKNOWN_CIRCUMSTANCES} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.UNKNOWN_CIRCUMSTANCES
      ]
    }`,
    value: AssaultHomicideCircumstance.UNKNOWN_CIRCUMSTANCES,
  },
];

export const AssaultHomicideCircumstance09BSelectItems = [
  {
    label: `${AssaultHomicideCircumstance.CHILD_PLAYING_WITH_WEAPON} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.CHILD_PLAYING_WITH_WEAPON
      ]
    }`,
    value: AssaultHomicideCircumstance.CHILD_PLAYING_WITH_WEAPON,
  },
  {
    label: `${AssaultHomicideCircumstance.GUN_CLEANING_ACCIDENT} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.GUN_CLEANING_ACCIDENT
      ]
    }`,
    value: AssaultHomicideCircumstance.GUN_CLEANING_ACCIDENT,
  },
  {
    label: `${AssaultHomicideCircumstance.HUNTING_ACCIDENT} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.HUNTING_ACCIDENT
      ]
    }`,
    value: AssaultHomicideCircumstance.HUNTING_ACCIDENT,
  },
  {
    label: `${AssaultHomicideCircumstance.OTHER_NEGLIGENT_WEAPON_HANDLING} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.OTHER_NEGLIGENT_WEAPON_HANDLING
      ]
    }`,
    value: AssaultHomicideCircumstance.OTHER_NEGLIGENT_WEAPON_HANDLING,
  },
  {
    label: `${AssaultHomicideCircumstance.NEGLIGENT_VEHICULAR_KILLINGS} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.NEGLIGENT_VEHICULAR_KILLINGS
      ]
    }`,
    value: AssaultHomicideCircumstance.NEGLIGENT_VEHICULAR_KILLINGS,
  },
  {
    label: `${AssaultHomicideCircumstance.OTHER_NEGLIGENT_KILLINGS} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.OTHER_NEGLIGENT_KILLINGS
      ]
    }`,
    value: AssaultHomicideCircumstance.OTHER_NEGLIGENT_KILLINGS,
  },
  {
    label: `${AssaultHomicideCircumstance.NOT_APPLICABLE} = ${
      AssaultHomicideCircumstanceLabel[
        AssaultHomicideCircumstance.NOT_APPLICABLE
      ]
    }`,
    value: AssaultHomicideCircumstance.NOT_APPLICABLE,
  },
];

export const AssaultHomicideCircumstanceSelectItems = [
  ...AssaultHomicideCircumstance09A13ASelectItems,
  ...AssaultHomicideCircumstance09BSelectItems,
];

export const JustifiableHomicideCircumstanceSelectItems = [
  {
    group: 'CRIMINAL KILLED BY POLICE OFFICER',
    label: '',
    value: '',
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_THAT_OFFICER_KILLED_CRIMINAL
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_ATTACKED_POLICE_OFFICER_AND_THAT_OFFICER_KILLED_CRIMINAL
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_THAT_OFFICER_KILLED_CRIMINAL,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_ANOTHER_POLICE_OFFICER
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_ANOTHER_POLICE_OFFICER
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_ANOTHER_POLICE_OFFICER,
  },
  {
    label: `${JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN} =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN
      ]
    }`,
    value: JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME
    } =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME
      ]
    }`,
    value: JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME
    } =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME,
  },
  {
    label: `${JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST} =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST
      ]
    }`,
    value: JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION,
  },
  {
    group: 'CRIMINAL KILLED BY PRIVATE CITIZEN',
    label: '',
    value: '',
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN_AND_KILLED_BY_PC
    } =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_ATTACKED_CIVILIAN_AND_KILLED_BY_PC
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_CIVILIAN_AND_KILLED_BY_PC,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME_AND_KILLED_BY_PC
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME_AND_KILLED_BY_PC
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_ATTEMPTED_FLIGHT_FROM_CRIME_AND_KILLED_BY_PC,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME_AND_KILLED_BY_PC
    } =${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME_AND_KILLED_BY_PC
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_KILLED_IN_COMMISSION_OF_CRIME_AND_KILLED_BY_PC,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST_AND_KILLED_BY_PC
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_RESISTED_ARREST_AND_KILLED_BY_PC
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_RESISTED_ARREST_AND_KILLED_BY_PC,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_CIVILIAN_AIDING_POLICE
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_CIVILIAN_AIDING_POLICE
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.CRIMINAL_ATTACKED_POLICE_OFFICER_AND_CRIMINAL_KILLED_BY_CIVILIAN_AIDING_POLICE,
  },
  {
    label: `${
      JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION_KILLED_BY_PC
    } = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance
          .UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION_KILLED_BY_PC
      ]
    }`,
    value:
      JustifiableHomicideCircumstance.UNABLE_TO_DETERMINE_NOT_ENOUGH_INFORMATION_KILLED_BY_PC,
  },
  {
    label: `${JustifiableHomicideCircumstance.NOT_APPLICABLE} = ${
      JustifiableHomicideCircumstanceLabel[
        JustifiableHomicideCircumstance.NOT_APPLICABLE
      ]
    }`,
    value: JustifiableHomicideCircumstance.NOT_APPLICABLE,
  },
];

export const OffenderUsedComputerOrHandheldDeviceSelectItems = [
  {
    label: `${
      OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_NOT_USED
    } = ${
      OffenderUsedComputerOrHandheldDeviceLabel[
        OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_NOT_USED
      ]
    }`,
    value:
      OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_NOT_USED,
  },
  {
    label: `${
      OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_USED
    } = ${
      OffenderUsedComputerOrHandheldDeviceLabel[
        OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_USED
      ]
    }`,
    value: OffenderUsedComputerOrHandheldDevice.COMPUTER_HANDHELD_DEVICE_USED,
  },
  {
    label: `${OffenderUsedComputerOrHandheldDevice.NOT_REPORTED} = ${
      OffenderUsedComputerOrHandheldDeviceLabel[
        OffenderUsedComputerOrHandheldDevice.NOT_REPORTED
      ]
    }`,
    value: OffenderUsedComputerOrHandheldDevice.NOT_REPORTED,
  },
];

export const CriminalActivitySelectItems = [
  {
    group: 'CRIMINAL ACTIVITY',
    label: '',
    value: '',
  },
  {
    label: `${CriminalActivityGangInformationType.SIMPLE_GROSS_NEGLECT} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.SIMPLE_GROSS_NEGLECT
      ]
    }`,
    value: CriminalActivityGangInformationType.SIMPLE_GROSS_NEGLECT,
  },
  {
    label: `${CriminalActivityGangInformationType.BUYING_RECEIVING} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.BUYING_RECEIVING
      ]
    }`,
    value: CriminalActivityGangInformationType.BUYING_RECEIVING,
  },
  {
    label: `${
      CriminalActivityGangInformationType.CULTIVATING_MANUFACTURING_PUBLISHING
    } = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.CULTIVATING_MANUFACTURING_PUBLISHING
      ]
    }`,
    value:
      CriminalActivityGangInformationType.CULTIVATING_MANUFACTURING_PUBLISHING,
  },
  {
    label: `${CriminalActivityGangInformationType.DISTRIBUTION_SELLING} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.DISTRIBUTION_SELLING
      ]
    }`,
    value: CriminalActivityGangInformationType.DISTRIBUTION_SELLING,
  },
  {
    label: `${CriminalActivityGangInformationType.EXPLOITING_CHILDREN} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.EXPLOITING_CHILDREN
      ]
    }`,
    value: CriminalActivityGangInformationType.EXPLOITING_CHILDREN,
  },
  {
    label: `${CriminalActivityGangInformationType.ORGANIZED_ABUSE} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.ORGANIZED_ABUSE
      ]
    }`,
    value: CriminalActivityGangInformationType.ORGANIZED_ABUSE,
  },
  {
    label: `${
      CriminalActivityGangInformationType.INTENTIONAL_ABUSE_TORTURE
    } = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.INTENTIONAL_ABUSE_TORTURE
      ]
    }`,
    value: CriminalActivityGangInformationType.INTENTIONAL_ABUSE_TORTURE,
  },
  {
    label: `${
      CriminalActivityGangInformationType.OPERATING_PROMOTING_ASSISTING
    } = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.OPERATING_PROMOTING_ASSISTING
      ]
    }`,
    value: CriminalActivityGangInformationType.OPERATING_PROMOTING_ASSISTING,
  },
  {
    label: `${CriminalActivityGangInformationType.POSSESSING_CONCEALING} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.POSSESSING_CONCEALING
      ]
    }`,
    value: CriminalActivityGangInformationType.POSSESSING_CONCEALING,
  },
  {
    label: `${CriminalActivityGangInformationType.ANIMAL_SEXUAL_ABUSE} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.ANIMAL_SEXUAL_ABUSE
      ]
    }`,
    value: CriminalActivityGangInformationType.ANIMAL_SEXUAL_ABUSE,
  },
  {
    label: `${
      CriminalActivityGangInformationType.TRANSPORTING_TRANSMITTING_IMPORTING
    } = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.TRANSPORTING_TRANSMITTING_IMPORTING
      ]
    }`,
    value:
      CriminalActivityGangInformationType.TRANSPORTING_TRANSMITTING_IMPORTING,
  },
  {
    label: `${CriminalActivityGangInformationType.USING_CONSUMING} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.USING_CONSUMING
      ]
    }`,
    value: CriminalActivityGangInformationType.USING_CONSUMING,
  },
  {
    label: `${CriminalActivityGangInformationType.NOT_APPLICABLE} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.NOT_APPLICABLE
      ]
    }`,
    value: CriminalActivityGangInformationType.NOT_APPLICABLE,
  },
];

export const GangInformationSelectItems = [
  {
    group: 'GANG INFORMATION',
    label: '',
    value: '',
  },
  {
    label: `${CriminalActivityGangInformationType.JUVENILE_GANG} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.JUVENILE_GANG
      ]
    }`,
    value: CriminalActivityGangInformationType.JUVENILE_GANG,
  },
  {
    label: `${CriminalActivityGangInformationType.OTHER_GANG} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.OTHER_GANG
      ]
    }`,
    value: CriminalActivityGangInformationType.OTHER_GANG,
  },
  {
    label: `${CriminalActivityGangInformationType.UNKNOWN} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.UNKNOWN
      ]
    }`,
    value: CriminalActivityGangInformationType.UNKNOWN,
  },
  {
    label: `${CriminalActivityGangInformationType.NOT_APPLICABLE} = ${
      CriminalActivityGangInformationTypeLabel[
        CriminalActivityGangInformationType.NOT_APPLICABLE
      ]
    }`,
    value: CriminalActivityGangInformationType.NOT_APPLICABLE,
  },
];

export const BiasMotivationSelectItems = [
  {
    group: 'RACIAL BIAS',
    label: '',
    value: '',
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_WHITE],
    value: BiasMotivation.ANTI_WHITE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_BLACK_AFRICAN_AMERICAN],
    value: BiasMotivation.ANTI_BLACK_AFRICAN_AMERICAN,
  },
  {
    label:
      BiasMotivationLabel[BiasMotivation.ANTI_AMERICAN_INDIAN_ALASKA_NATIVE],
    value: BiasMotivation.ANTI_AMERICAN_INDIAN_ALASKA_NATIVE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_ASIAN],
    value: BiasMotivation.ANTI_ASIAN,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_MULTIPLE_RACES_GROUP],
    value: BiasMotivation.ANTI_MULTIPLE_RACES_GROUP,
  },
  {
    label:
      BiasMotivationLabel[
        BiasMotivation.ANTI_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER
      ],
    value: BiasMotivation.ANTI_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER,
  },
  {
    group: 'RELIGIOUS BIAS',
    label: '',
    value: '',
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_RELIGIOUS_PRACTICE],
    value: BiasMotivation.ANTI_RELIGIOUS_PRACTICE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_JEWISH],
    value: BiasMotivation.ANTI_JEWISH,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_ROMAN_CATHOLIC],
    value: BiasMotivation.ANTI_ROMAN_CATHOLIC,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_PROTESTANT],
    value: BiasMotivation.ANTI_PROTESTANT,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_ISLAMIC],
    value: BiasMotivation.ANTI_ISLAMIC,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_MULTIPLE_RELIGIONS_GROUP],
    value: BiasMotivation.ANTI_MULTIPLE_RELIGIONS_GROUP,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_ATHEISM_AGNOSTICISM],
    value: BiasMotivation.ANTI_ATHEISM_AGNOSTICISM,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_MORMON],
    value: BiasMotivation.ANTI_MORMON,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_OTHER_RELIGION],
    value: BiasMotivation.ANTI_OTHER_RELIGION,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_JEHOVAH_WITNESS],
    value: BiasMotivation.ANTI_JEHOVAH_WITNESS,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_EASTERN_ORTHODOX],
    value: BiasMotivation.ANTI_EASTERN_ORTHODOX,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_OTHER_CHRISTIAN],
    value: BiasMotivation.ANTI_OTHER_CHRISTIAN,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_BUDDHIST],
    value: BiasMotivation.ANTI_BUDDHIST,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_HINDU],
    value: BiasMotivation.ANTI_HINDU,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_SIKH],
    value: BiasMotivation.ANTI_SIKH,
  },
  {
    group: 'ETHNICITY/NATIONAL ORIGIN/ANCESTRY BIAS',
    label: '',
    value: '',
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_HISPANIC_LATINO],
    value: BiasMotivation.ANTI_HISPANIC_LATINO,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_ARAB],
    value: BiasMotivation.ANTI_ARAB,
  },
  {
    label:
      BiasMotivationLabel[BiasMotivation.ANTI_OTHER_RACE_ETHNICITY_ANCESTRY],
    value: BiasMotivation.ANTI_OTHER_RACE_ETHNICITY_ANCESTRY,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_NOT_HISPANIC_LATINO],
    value: BiasMotivation.ANTI_NOT_HISPANIC_LATINO,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_OTHER_ORIGIN],
    value: BiasMotivation.ANTI_OTHER_ORIGIN,
  },
  {
    group: 'SEXUAL BIAS',
    label: '',
    value: '',
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_GAY],
    value: BiasMotivation.ANTI_GAY,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_LESBIAN],
    value: BiasMotivation.ANTI_LESBIAN,
  },
  {
    label:
      BiasMotivationLabel[BiasMotivation.ANTI_LESBIAN_GAY_BISEXUAL_TRANSGENDER],
    value: BiasMotivation.ANTI_LESBIAN_GAY_BISEXUAL_TRANSGENDER,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_HETEROSEXUAL],
    value: BiasMotivation.ANTI_HETEROSEXUAL,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_BISEXUAL],
    value: BiasMotivation.ANTI_BISEXUAL,
  },
  {
    group: 'OTHER',
    label: '',
    value: '',
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_OLD_AGE],
    value: BiasMotivation.ANTI_OLD_AGE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_PHYSICAL_DISABILITY],
    value: BiasMotivation.ANTI_PHYSICAL_DISABILITY,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_MENTAL_DISABILITY],
    value: BiasMotivation.ANTI_MENTAL_DISABILITY,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_MALE],
    value: BiasMotivation.ANTI_MALE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_FEMALE],
    value: BiasMotivation.ANTI_FEMALE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_TRANSGENDER],
    value: BiasMotivation.ANTI_TRANSGENDER,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.ANTI_GENDER_NON_CONFORMING],
    value: BiasMotivation.ANTI_GENDER_NON_CONFORMING,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.NOT_APPLICABLE],
    value: BiasMotivation.NOT_APPLICABLE,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.NOT_REPORTED_BY_AGENCY],
    value: BiasMotivation.NOT_REPORTED_BY_AGENCY,
  },
  {
    label: BiasMotivationLabel[BiasMotivation.UNKNOWN],
    value: BiasMotivation.UNKNOWN,
  },
];

export const VictimRelationshipSelectItems = [
  {
    group: 'Within Family',
    label: '',
    value: '',
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.SPOUSE],
    value: VictimRelationship.SPOUSE,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.FORMER_INTIMATE_PARTNER],
    value: VictimRelationship.FORMER_INTIMATE_PARTNER,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.PARENT],
    value: VictimRelationship.PARENT,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.SIBLING],
    value: VictimRelationship.SIBLING,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.CHILD],
    value: VictimRelationship.CHILD,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.GRANDPARENT],
    value: VictimRelationship.GRANDPARENT,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.GRANDCHILD],
    value: VictimRelationship.GRANDCHILD,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.IN_LAW],
    value: VictimRelationship.IN_LAW,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.STEPPARENT],
    value: VictimRelationship.STEPPARENT,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.STEPCHILD],
    value: VictimRelationship.STEPCHILD,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.STEP_SIBLING],
    value: VictimRelationship.STEP_SIBLING,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.OTHER_FAMILY],
    value: VictimRelationship.OTHER_FAMILY,
  },
  {
    label:
      VictimRelationshipLabel[VictimRelationship.CURRENT_BOYFRIEND_GIRLFRIEND],
    value: VictimRelationship.CURRENT_BOYFRIEND_GIRLFRIEND,
  },
  {
    label:
      VictimRelationshipLabel[VictimRelationship.CHILD_OF_INTIMATE_PARTNER],
    value: VictimRelationship.CHILD_OF_INTIMATE_PARTNER,
  },
  {
    label:
      VictimRelationshipLabel[VictimRelationship.CURRENT_SAME_SEX_RELATIONSHIP],
    value: VictimRelationship.CURRENT_SAME_SEX_RELATIONSHIP,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.EX_SPOUSE],
    value: VictimRelationship.EX_SPOUSE,
  },
  {
    group: 'Outside family but known to victim',
    label: '',
    value: '',
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.ACQUAINTANCE],
    value: VictimRelationship.ACQUAINTANCE,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.FRIEND],
    value: VictimRelationship.FRIEND,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.NEIGHBOR],
    value: VictimRelationship.NEIGHBOR,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.BABY_CHILD],
    value: VictimRelationship.BABY_CHILD,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.EMPLOYEE],
    value: VictimRelationship.EMPLOYEE,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.EMPLOYER],
    value: VictimRelationship.EMPLOYER,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.OTHERWISE_KNOWN],
    value: VictimRelationship.OTHERWISE_KNOWN,
  },
  {
    group: 'Not known to victim',
    label: '',
    value: '',
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.RELATIONSHIP_UNKNOWN],
    value: VictimRelationship.RELATIONSHIP_UNKNOWN,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.STRANGER],
    value: VictimRelationship.STRANGER,
  },
  {
    group: 'Other',
    label: '',
    value: '',
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.VICTIM_WAS_OFFENDER],
    value: VictimRelationship.VICTIM_WAS_OFFENDER,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.NOT_APPLICABLE],
    value: VictimRelationship.NOT_APPLICABLE,
  },
  {
    label: VictimRelationshipLabel[VictimRelationship.NOT_REPORTED],
    value: VictimRelationship.NOT_REPORTED,
  },
];

export const ArrestTypeSelectItems = [
  {
    label: `${ArrestType.COMPLAINT} = ${ArrestTypeLabel[ArrestType.COMPLAINT]}`,
    value: ArrestType.COMPLAINT,
  },
  {
    label: `${ArrestType.CRIME_IN_PROGRESS} = ${
      ArrestTypeLabel[ArrestType.CRIME_IN_PROGRESS]
    }`,
    value: ArrestType.CRIME_IN_PROGRESS,
  },
  {
    label: `${ArrestType.COURT_SUMMONS} = ${
      ArrestTypeLabel[ArrestType.COURT_SUMMONS]
    }`,
    value: ArrestType.COURT_SUMMONS,
  },
  {
    label: `${ArrestType.ORDER_OF_PROTECTION} = ${
      ArrestTypeLabel[ArrestType.ORDER_OF_PROTECTION]
    }`,
    value: ArrestType.ORDER_OF_PROTECTION,
  },
  {
    label: `${ArrestType.OTHER} = ${ArrestTypeLabel[ArrestType.OTHER]}`,
    value: ArrestType.OTHER,
  },
];

export const ArrestStatusSelectItems = [
  {
    label: `${ArrestStatus.HELD} = ${ArrestStatusLabel[ArrestStatus.HELD]}`,
    value: ArrestStatus.HELD,
  },
  {
    label: `${ArrestStatus.APPEARANCE_TICKET} = ${
      ArrestStatusLabel[ArrestStatus.APPEARANCE_TICKET]
    }`,
    value: ArrestStatus.APPEARANCE_TICKET,
  },
  {
    label: `${ArrestStatus.ROR} = ${ArrestStatusLabel[ArrestStatus.ROR]}`,
    value: ArrestStatus.ROR,
  },
  {
    label: `${ArrestStatus.CASH_BAIL} = ${
      ArrestStatusLabel[ArrestStatus.CASH_BAIL]
    }`,
    value: ArrestStatus.CASH_BAIL,
  },
  {
    label: `${ArrestStatus.BAIL_BOND} = ${
      ArrestStatusLabel[ArrestStatus.BAIL_BOND]
    }`,
    value: ArrestStatus.BAIL_BOND,
  },
  {
    label: `${ArrestStatus.POLICE_BAIL} = ${
      ArrestStatusLabel[ArrestStatus.POLICE_BAIL]
    }`,
    value: ArrestStatus.POLICE_BAIL,
  },
  {
    label: `${ArrestStatus.RELEASED_TO_THIRD_PARTY} = ${
      ArrestStatusLabel[ArrestStatus.RELEASED_TO_THIRD_PARTY]
    }`,
    value: ArrestStatus.RELEASED_TO_THIRD_PARTY,
  },
  {
    label: `${ArrestStatus.NOT_REPORTED} = ${
      ArrestStatusLabel[ArrestStatus.NOT_REPORTED]
    }`,
    value: ArrestStatus.NOT_REPORTED,
  },
  {
    label: `${ArrestStatus.UNKNOWN} = ${
      ArrestStatusLabel[ArrestStatus.UNKNOWN]
    }`,
    value: ArrestStatus.UNKNOWN,
  },
];

export const JuvenileReleaseStatusSelectItems = [
  {
    label: `${JuvenileReleaseStatus.HANDLED_WITHIN_DEPARTMENT} = ${
      JuvenileReleaseStatusLabel[
        JuvenileReleaseStatus.HANDLED_WITHIN_DEPARTMENT
      ]
    }`,
    value: JuvenileReleaseStatus.HANDLED_WITHIN_DEPARTMENT,
  },
  {
    label: `${
      JuvenileReleaseStatus.REFERRED_TO_CRIMINAL_FAMILY_COURT_PROBATION_INTAKE
    } = ${
      JuvenileReleaseStatusLabel[
        JuvenileReleaseStatus.REFERRED_TO_CRIMINAL_FAMILY_COURT_PROBATION_INTAKE
      ]
    }`,
    value:
      JuvenileReleaseStatus.REFERRED_TO_CRIMINAL_FAMILY_COURT_PROBATION_INTAKE,
  },
  {
    label: `${
      JuvenileReleaseStatus.DIVERTED_TO_COUNSELING_TREATMENT_PROGRAM
    } = ${
      JuvenileReleaseStatusLabel[
        JuvenileReleaseStatus.DIVERTED_TO_COUNSELING_TREATMENT_PROGRAM
      ]
    }`,
    value: JuvenileReleaseStatus.DIVERTED_TO_COUNSELING_TREATMENT_PROGRAM,
  },
  {
    label: `${JuvenileReleaseStatus.NOT_APPLICABLE} = ${
      JuvenileReleaseStatusLabel[JuvenileReleaseStatus.NOT_APPLICABLE]
    }`,
    value: JuvenileReleaseStatus.NOT_APPLICABLE,
  },
];
