import { IconButton } from '@mui/material';
import { GridColDef, getGridStringOperators } from '@mui/x-data-grid-pro';

import { IcVisibility, IcPencil, IcDeleteTrash } from '@/assets/images';
import { excludeValues } from '@/components/DataGrid/utils';
import { IMasterVehicle, VehicleTypeList } from '@/models';

const licensePlate = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row.licensePlate?.number || '/';
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
  valueGetter: (_, row) => row.licensePlate?.number,
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.type
      ? VehicleTypeList[params.row.type as keyof typeof VehicleTypeList]
      : '/',
  filterable: false,
});

const actions = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterVehicle) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}`);
        }}
      >
        <IcVisibility />
      </IconButton>
      <IconButton
        aria-label="edit"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}/edit`);
        }}
      >
        <IcPencil />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(params.row);
        }}
      >
        <IcDeleteTrash />
      </IconButton>
    </>
  ),
});

export const masterVehicleCols = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterVehicle) => void,
) => {
  return {
    licensePlate,
    type,
    actions: () => actions(navigate, onDelete),
  };
};
