import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Tab } from '@mui/material';

import { UserApi } from '@/api';
import { Page, PageHeader, Tabs } from '@/components';
import { EmployeeViewTabs } from '@/pages/Employees/data';
import { EmployeeDetailsTabRoutes } from '@/routes/RoutePaths';

const getTitle = (path: string): string => {
  switch (path) {
    case EmployeeDetailsTabRoutes.Information:
      return 'View Employee';
    case EmployeeDetailsTabRoutes.Devices:
      return 'Device History';
    default:
      return '';
  }
};

export const EmployeeDetailsPage = () => {
  const { agencyId, employeeId } = useParams();
  const [showDeviceTab, setShowDeviceTab] = useState(false);

  const fetchDeviceData = async () => {
    if (!employeeId) {
      return;
    }

    const response = await UserApi.getUserDevices(employeeId, {});
    const res = response.data;

    if (res.count > 0) {
      setShowDeviceTab(true);
    }
  };

  useEffect(() => {
    fetchDeviceData();
  }, [employeeId]);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const paths = pathname.split('/');

  const activeTab = paths[paths.length - 1];

  return (
    <Page>
      <PageHeader
        onBack={() =>
          navigate(`/agencies/${agencyId}/employees`, { replace: true })
        }
        title={getTitle(activeTab)}
      ></PageHeader>
      <Tabs
        value={activeTab}
        onChange={(_e, newValue: string) => navigate(newValue)}
      >
        {EmployeeViewTabs.map(
          ({ path, label }) =>
            (showDeviceTab || path === 'information') && (
              <Tab key={path} label={label} value={path} />
            ),
        )}
      </Tabs>
      <Outlet />
    </Page>
  );
};
