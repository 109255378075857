import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { IUnit, UnitStatus } from '@/models';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.name || '-',
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.type?.name || '-',
  valueGetter: (value: IUnit) => {
    return value.name;
  },
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.status || '-',
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: IUnit) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => {
    if (params.row.status === UnitStatus.ON_DUTY) {
      return null;
    }
    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          openMenu?.(e, params.row);
        }}
      >
        <IcDotSpread />
      </IconButton>
    );
  },
});

export const agencyUnitCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: IUnit) => void,
) => {
  return {
    name,
    type,
    status,
    actions: () => actions(openMenu),
  };
};
