import { FC, MouseEvent, useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Divider, Menu, Stack, styled } from '@mui/material';

import { IcFilter } from '@/assets/images';
import {
  NarrowButton,
  RenderFormField,
  ActiveFilterIndicator,
} from '@/components';

interface IShiftLogsFilter {
  event: string;
  unit: string;
  createdAt?: string;
  updatedAt?: string;
}

interface IFilterMenuProps {
  onChangeFilter: (value: IShiftLogsFilter) => void;
  tableData: Array<any> | undefined;
}

const FormRoot = styled('form')(() => ({
  padding: 16,
  minWidth: 300,
  '& .divider': {
    marginBottom: 16,
  },
}));

const initialFilterValue: IShiftLogsFilter = {
  event: '',
  unit: '',
  createdAt: '',
  updatedAt: '',
};

export const FilterMenu: FC<IFilterMenuProps> = ({
  onChangeFilter,
  tableData,
}) => {
  const methods = useForm<IShiftLogsFilter>({
    defaultValues: initialFilterValue,
    mode: 'all',
  });

  const { handleSubmit, reset } = methods;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [uniqueUnits, setUniqueUnits] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    const units = [...new Set(tableData?.map((row) => row.name))].map(
      (unit) => {
        const rowData = tableData?.find((row) => row.name === unit);
        return {
          label: unit,
          value: rowData?._id,
        };
      },
    );
    setUniqueUnits(units);
  }, [tableData]);

  const filterMenuFormFields = [
    {
      name: 'event',
      type: 'autocomplete',
      label: 'Event',
      items: [
        { label: 'On Scene', value: 'On Scene' },
        { label: 'Assigned', value: 'Assigned' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Canceled', value: 'Canceled' },
      ],
    },
    {
      name: 'unit',
      type: 'autocomplete',
      label: 'Unit',
      items: uniqueUnits,
    },
  ];

  const openActionMenu = Boolean(anchorEl);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCancel = () => {
    handleCloseMenu();
    reset();
  };

  const onClear = () => {
    reset();
    onChangeFilter(initialFilterValue);
    setShowFilterDot(false);
  };

  const onSubmit: SubmitHandler<IShiftLogsFilter> = (data) => {
    onChangeFilter(data);

    const isFilterDirty = Object.values(data).some((value) => !!value);
    setShowFilterDot(isFilterDirty);
    handleCloseMenu();
  };

  return (
    <>
      <NarrowButton
        variant="contained"
        color="inherit"
        onClick={handleOpenMenu}
        sx={{ position: 'relative' }}
      >
        <IcFilter />
        {showFilterDot && <ActiveFilterIndicator />}
      </NarrowButton>

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <FormProvider {...methods}>
          <FormRoot onSubmit={handleSubmit(onSubmit)}>
            {filterMenuFormFields.map((field) => (
              <RenderFormField
                key={field.name}
                {...field}
                sx={{ marginBottom: 2 }}
              />
            ))}

            <Divider className="divider" sx={{ marginTop: '1rem' }} />

            <Stack flexDirection="row" columnGap={2}>
              <Button
                type="button"
                variant="contained"
                color="inherit"
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                type="button"
                variant="text"
                color="inherit"
                onClick={onClear}
              >
                Clear
              </Button>

              <Button type="submit" variant="contained" color="error">
                Apply
              </Button>
            </Stack>
          </FormRoot>
        </FormProvider>
      </Menu>
    </>
  );
};
