import {
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';

import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { MasterNameApi } from '@/api';
import {
  IMasterName,
  IMasterNameOrganization,
  IMasterNamePerson,
  MasterNameType,
} from '@/models';
import { getAddressLocation } from '@/services';
import { useToastStore } from '@/store';
import { colors } from '@/theme/variables';

interface IMasterNameAutocompleteProps {
  excludedMasterNames?: Array<IMasterNamePerson | IMasterNameOrganization>;
  noResults?: ReactNode;
  disabled?: boolean;
  onChange: (value: IMasterName) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  filterByType?: MasterNameType | null;
  addNewAction?: () => void;
  key?: number;
}

export const MasterNameAutocomplete = ({
  excludedMasterNames = [],
  noResults,
  onChange,
  onKeyUp,
  disabled = false,
  filterByType,
  addNewAction,
  key,
}: IMasterNameAutocompleteProps) => {
  const [searchText, setSearchText] = useState('');
  const [masterNames, setMasterNames] = useState<
    Array<
      | IMasterNamePerson
      | IMasterNameOrganization
      | { _id: string; name: string }
    >
  >([]);

  const { updateToast } = useToastStore();

  useEffect(() => {
    fetchMasterNames();
  }, []);

  const fetchMasterNames = async (search?: string) => {
    try {
      const res = await MasterNameApi.getMasterNames({
        search,
        type: filterByType as string,
      });
      setMasterNames(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err });
    }
  };

  const handleInputChange = useDebouncedCallback(
    (_: SyntheticEvent, v: string) => {
      setSearchText(v);
      fetchMasterNames(v);
    },
    500,
  );

  return (
    <>
      <Autocomplete
        key={key}
        autoHighlight
        fullWidth
        disabled={disabled}
        onInputChange={handleInputChange}
        options={masterNames}
        noOptionsText={noResults}
        filterOptions={(allMasterNames) => {
          const filtered = allMasterNames.reduce((acc, item) => {
            const found = (excludedMasterNames || []).find((excludedItem) => {
              return excludedItem?._id === item._id;
            });
            return found ||
              (item as IMasterNameOrganization).name?.includes('Unknown') ||
              (item as IMasterNamePerson).firstName?.includes('Unknown')
              ? acc
              : [...acc, item];
          }, [] as Array<IMasterNamePerson | IMasterNameOrganization | { _id: string; name: string }>);
          return filtered.length > 0
            ? [...filtered, { _id: 'add-new', name: '+ Add new' }]
            : [];
        }}
        onChange={(_, v) => {
          if (v && v._id !== 'add-new') onChange(v as IMasterName);
        }}
        getOptionLabel={(option) =>
          option._id === 'add-new' ? '' : option.name
        }
        renderOption={(
          props,
          option: IMasterName | { _id: string; name: string },
        ) => {
          if (option._id === 'add-new' && addNewAction) {
            return (
              <Stack justifyContent="flex-end" width="100%">
                <Button
                  onClick={() => addNewAction()}
                  variant="text"
                  color="error"
                >
                  + Add new
                </Button>
              </Stack>
            );
          }
          return (
            <Box
              component="li"
              {...props}
              key={option._id}
              flexDirection="column"
              flexWrap="wrap"
              sx={{
                flexDirection: 'row',
                borderBottom: `1px solid ${colors.grey[10]}`,
                flexWrap: 'wrap',
                m: 1,
                height: '60px',
              }}
            >
              <p style={{ width: '100%', margin: 0 }}>
                <strong style={{ margin: 0 }}>{option.name}</strong>
              </p>
              {'address' in option && (
                <p style={{ margin: 0 }}>
                  {getAddressLocation(option.address)}
                </p>
              )}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={searchText}
            placeholder="Search names..."
            onKeyUp={(e) => {
              if (onKeyUp) {
                onKeyUp(e);
              }
            }}
            sx={{
            '& .MuiInputBase-root': {
              width: '100% !important',
            },
          }}
            fullWidth
          />
        )}
        disableClearable={true}
      />
    </>
  );
};
