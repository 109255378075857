import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { AxiosResponse } from 'axios';

import { IPaginatedListResponse } from '@/api/types';
import { ICFSMasterVehicle, IKaseMasterVehicle } from '@/models';

export interface TabItem {
  path: string;
  label: string;
}

interface MasterVehicleContextProviderProps extends PropsWithChildren {
  nextRoutes: string[];
  getLinkedVehicles?: () => Promise<
    AxiosResponse<(ICFSMasterVehicle | IKaseMasterVehicle)[]>
  >;
  getPaginatedLinkedVehicles?: () => Promise<
    AxiosResponse<
      IPaginatedListResponse<ICFSMasterVehicle | IKaseMasterVehicle>
    >
  >;
  addLinkedVehicles?: (
    id: string,
  ) => Promise<AxiosResponse<ICFSMasterVehicle | IKaseMasterVehicle>>;
  deleteLinkedVehicle?: (id: string) => Promise<AxiosResponse>;
  getOwners?: () => void;
}

interface MasterVehicleContextValues
  extends Omit<
    MasterVehicleContextProviderProps,
    'children' | 'addLinkedVehicles' | 'deleteLinkedVehicle'
  > {
  linkedMasterVehicles: (ICFSMasterVehicle | IKaseMasterVehicle)[];
  currentVehicleId?: string;
  updateLinkedMasterVehicles: (
    newVehicles: (ICFSMasterVehicle | IKaseMasterVehicle)[],
  ) => void;
  updateCurrentVehicleId: (newVehicleId?: string) => void;
  handleAddLinkedVehicle: (vehicleId: string) => Promise<void>;
  handleDeleteLinkedVehicle: (vehicleId: string) => Promise<void>;
}

const SharedVehicleContext = createContext<
  MasterVehicleContextValues | undefined
>(undefined);

export function useMasterVehicleContext() {
  const context = useContext(SharedVehicleContext);
  if (!context) {
    throw new Error(
      'useMasterVehicleContext must be used within a MasterVehicleContextProvider',
    );
  }
  return context;
}

export function MasterVehicleContextProvider({
  children,
  addLinkedVehicles,
  deleteLinkedVehicle,
  ...rest
}: MasterVehicleContextProviderProps) {
  const [currentVehicleId, setCurrentVehicleId] = useState<
    string | undefined
  >();
  const [linkedMasterVehicles, setLinkedMasterVehicles] = useState<
    (ICFSMasterVehicle | IKaseMasterVehicle)[]
  >([]);

  const handleAddLinkedVehicle = async (id: string) => {
    if (addLinkedVehicles) {
      const res = await addLinkedVehicles(id);
      setLinkedMasterVehicles((v) => [...v, res.data]);
    }
  };

  const handleDeleteLinkedVehicle = async (id: string) => {
    if (deleteLinkedVehicle) {
      await deleteLinkedVehicle(id);
      const updatedLinkedVehicles = linkedMasterVehicles.filter(
        ({ masterVehicle }) => masterVehicle._id !== id,
      );
      setLinkedMasterVehicles(updatedLinkedVehicles);
    }
  };

  return (
    <SharedVehicleContext.Provider
      value={{
        currentVehicleId,
        linkedMasterVehicles,
        updateLinkedMasterVehicles: setLinkedMasterVehicles,
        updateCurrentVehicleId: setCurrentVehicleId,
        handleAddLinkedVehicle,
        handleDeleteLinkedVehicle,
        ...rest,
      }}
    >
      {children}
    </SharedVehicleContext.Provider>
  );
}
