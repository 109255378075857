import { useEffect, useRef, useState } from 'react';

import { CfsApi } from '@/api';
import { ICallerLocation, ICFS } from '@/models';
import { useUserStore } from '@/store';

export const useRapidSos = (cfs: ICFS | null) => {
  const { account } = useUserStore();
  const [locations, setLocations] = useState<ICallerLocation[]>([]);
  const isNotFound = useRef(false);

  const fetchCallerLocations = async () => {
    if (!cfs?._id || isNotFound.current) {
      return;
    }

    const isDifferingReporter =
      cfs.reporter?.callerPhoneNumber !== cfs.reporter?.contactPhoneNumber;

    // Do not fetch rapidsos data for differing reporters unless the flag is
    // set per account basis.
    if (
      isDifferingReporter &&
      !account?.settings?.features?.isEnabledRapidSOSForDifferingReporter
    ) {
      return;
    }

    try {
      const apiResult = await CfsApi.getCfsCallerLocations(cfs._id);
      if (
        apiResult.status === 200 &&
        apiResult.data &&
        apiResult.data.length > 0
      ) {
        const newLocations = apiResult.data;

        setLocations((currentLocations) => {
          if (currentLocations.length === 0) {
            return newLocations;
          } else if (
            currentLocations[currentLocations.length - 1].created_time
          ) {
            const lastNewLocation = newLocations[newLocations.length - 1];
            const lastLocation = currentLocations[currentLocations.length - 1];

            if (lastLocation.created_time !== lastNewLocation.created_time) {
              return [...currentLocations, ...apiResult.data];
            }
          }

          return currentLocations;
        });
      }
    } catch (err: any) {
      if (err.status === 404) {
        isNotFound.current = true;
      }
    }
  };

  useEffect(() => {
    if (cfs?.reporter?.callerPhoneNumber && !cfs.isClosed) {
      const t = setInterval(() => fetchCallerLocations(), 5000);
      return () => clearInterval(t);
    }
  }, [cfs?.reporter?.callerPhoneNumber, cfs?.isClosed]);

  if (cfs === null) {
    return {};
  }

  return {
    locations,
  };
};
