import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/config';

const type = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row?.type.name || '-',
  valueGetter: (_, row) => row.type?.name,
});

const comment = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row?.comment || '-',
});

const createdAt = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    moment(params.row?.createdAt).format(DATE_TIME_FORMAT) || '-',
});

const createdBy = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    `${params.row?.createdBy?.firstName} ${params.row?.createdBy?.lastName}` ||
    '-',
  valueGetter: (_, row) => row?.createdBy?.firstName + row?.createdBy?.lastName,
});

const actions = (handleDelete?: (id: string) => void): Partial<GridColDef> => ({
  sortable: false,
  renderCell: (params) => (
    <Button
      variant="text"
      color="error"
      onClick={(e) => {
        e.stopPropagation();
        handleDelete?.(params.row._id);
      }}
    >
      Delete
    </Button>
  ),
});

export const cautionCols = (handleDelete?: (id: string) => void) => {
  return {
    type,
    comment,
    createdAt,
    createdBy,
    actions: () => actions(handleDelete),
  };
};
