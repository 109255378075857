import { FC } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Badge,
  Typography,
  styled,
} from '@mui/material';

import { IcMinus, IcPlus } from '@/assets/images';
import { colors } from '@/theme/variables';

interface UnitAccordionProps extends AccordionProps {
  title: string;
  badge: number;
  value: string;
  currentValue: string | null;
  handleExpand: (value: string) => void;
}

const Root = styled(Accordion)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  '&.Mui-expanded': {
    flex: 1,
    margin: 0,
  },
  '&:before': {
    height: 0,
  },

  '& .MuiAccordionSummary-root': {
    borderTop: `1px solid ${colors.grey[10]}`,
  },
  '& .MuiAccordionSummary-content': {
    '&.Mui-expanded': {
      margin: '12px 0px',
    },

    '& .unit-accordion-title': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },

  '& .MuiCollapse-entered': {
    display: 'flex',
    flex: 1,
  },

  '& .MuiCollapse-wrapperInner': {
    display: 'flex',
  },

  '& .MuiAccordion-region': {
    display: 'flex',
    flex: 1,
  },

  '& .MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 15,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },

  '& .MuiBadge-badge': {
    right: -25,
    top: 12,
    padding: 10,
    aspectRatio: 1,
  },
}));

export const UnitAccordion: FC<UnitAccordionProps> = (props) => {
  const { title, badge, value, currentValue, handleExpand, children } = props;
  const isExpanded = value === currentValue;

  return (
    <Root expanded={isExpanded} onChange={() => handleExpand(value)} square>
      <AccordionSummary
        expandIcon={
          isExpanded ? <IcMinus /> : <IcPlus color={colors.error.main} />
        }
      >
        <Typography className="unit-accordion-title">{title}</Typography>
        <Badge badgeContent={badge} />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Root>
  );
};
