import { useCallback } from 'react';

import {
  GridColumnResizeParams,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';

import { LocalStoragePrefix } from '@/constants';
import { setLocalStorageItem } from '@/utils';

import { DataGridColSettings } from '../utils';

export const useGridColumns = (
  columnSettings: DataGridColSettings[],
  setColumnSettings: (settings: DataGridColSettings[]) => void,
  name: string,
  userId: string,
  hiddenColumnCount: number,
) => {
  const handleColumnChange = useCallback(
    (column: { oldIndex: number; targetIndex: number }) => {
      const newColumnOrder = [...columnSettings];
      const [movedColumn] = newColumnOrder.splice(
        column.oldIndex - hiddenColumnCount,
        1,
      );

      newColumnOrder.splice(
        column.targetIndex - hiddenColumnCount,
        0,
        movedColumn,
      );

      setColumnSettings(newColumnOrder);
      setLocalStorageItem({
        prefix: `${name}-${LocalStoragePrefix.TableSetting}`,
        key: userId,
        value: newColumnOrder,
      });
    },
    [columnSettings, userId, name, setColumnSettings, hiddenColumnCount],
  );

  const handleColumnVisibility = useCallback(
    (model: Record<string, boolean>) => {
      const updatedSettings = columnSettings.map((column) => ({
        ...column,
        visible: model[column.field],
      }));

      setColumnSettings(updatedSettings);
      setLocalStorageItem({
        prefix: `${name}-${LocalStoragePrefix.TableSetting}`,
        key: userId,
        value: updatedSettings,
      });
    },
    [columnSettings, userId, name, setColumnSettings],
  );

  const handleColumnWidthChange = useCallback(
    ({ colDef }: GridColumnResizeParams) => {
      const updatedSettings = columnSettings.map((column) => ({
        ...column,
        width:
          column.headerName === colDef.headerName ? colDef.width : column.width,
      }));

      setColumnSettings(updatedSettings);
      setLocalStorageItem({
        prefix: `${name}-${LocalStoragePrefix.TableSetting}`,
        key: userId,
        value: updatedSettings,
      });
    },
    [columnSettings, userId, name, setColumnSettings],
  );

  const convertToKeyVisibilityMap = useCallback(
    (items: DataGridColSettings[]): GridColumnVisibilityModel => {
      return items.reduce(
        (acc, column) => ({
          ...acc,
          [column.field]: column.visible,
        }),
        {} as GridColumnVisibilityModel,
      );
    },
    [],
  );

  return {
    handleColumnChange,
    handleColumnVisibility,
    handleColumnWidthChange,
    convertToKeyVisibilityMap,
  };
};
