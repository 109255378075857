import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { IVendor, IVendorContact, IVendorType } from '@/models/vendors';
import { formatPhoneNumber } from '@/utils';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.name || '-',
});

const type = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.type?.name || '-',
  valueGetter: (value: IVendorType) => {
    return value?.name;
  },
});

const contacts = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const contact = params.row.contacts?.find(
      (c: IVendorContact) => c.isPrimary,
    );
    return contact ? contact.name : '-';
  },
  valueGetter: (params: IVendorContact[]) => {
    return params?.find((c: IVendorContact) => c.isPrimary)?.name;
  },
});

const email = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.email || '-',
});

const phoneNumber = (): Partial<GridColDef> => ({
  renderCell: (params) => formatPhoneNumber(params.row.phoneNumber) || '-',
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row.isActive ? 'Active' : 'Inactive';
  },
  valueGetter: (_, row) => {
    return row?.isActive;
  },
});

const actions = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IVendor,
  ) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const agencyVendorCols = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IVendor,
  ) => void,
) => {
  return {
    name,
    type,
    contacts,
    email,
    phoneNumber,
    status,
    actions: () => actions(openMenu),
  };
};
