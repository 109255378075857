import { createElement, useCallback, useEffect, useState } from 'react';

import { debounce, useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid-pro';
import { useChannel } from 'ably/react';

import { IcArrowDown, IcArrowUp } from '@/assets/images';
import {
  DataGridToolbar,
  DataGridToolbarProps,
  NoDataError,
} from '@/components';
import { useGridColumns } from '@/components/DataGrid/hooks/useGridColumns';
import { useGridPagination } from '@/components/DataGrid/hooks/useGridPagination';
import { useGridState } from '@/components/DataGrid/hooks/useGridState';
import { DataGridProps } from '@/components/DataGrid/types';
import { deepEqual } from '@/components/DataGrid/utils';
import { useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { dataGridStyles } from './styles';

export const DataGrid = ({
  columns,
  fetchData,
  columnSettings,
  setColumnSettings,
  name,
  channelName,
  cta,
  initialPageSize,
  detailPanelContent,
  hideFilter,
  hideQuickFilter,
  hiddenColumnCount = 0,
  ...props
}: DataGridProps) => {
  const { user } = useUserStore();
  const [filter, setFilter] = useState<string>('');

  const [state, dispatch] = useGridState();

  const { page, pageSize, handlePageChange } =
    useGridPagination(initialPageSize);

  const {
    handleColumnChange,
    handleColumnVisibility,
    handleColumnWidthChange,
    convertToKeyVisibilityMap,
  } = useGridColumns(
    columnSettings,
    setColumnSettings,
    name,
    user?._id as string,
    hiddenColumnCount,
  );

  const fetchDataHandler = useCallback(async () => {
    if (!pageSize) return;
    dispatch({ type: 'SET_LOADING' });

    const { rows, totalCount } = await fetchData(page, pageSize, filter);
    dispatch({
      type: 'SET_DATA',
      payload: { rows, rowCount: totalCount },
    });
  }, [page, pageSize, fetchData, filter]);

  const onFilterChange = (model: GridFilterModel) => {
    const currentFilter = filter ? JSON.parse(filter) : null;
    let shouldUpdate = false;

    if (
      !model.items.length ||
      model.items.length <= currentFilter?.items?.length
    ) {
      shouldUpdate = true;
    }

    const changedItems = model.items.filter(
      (item, i) => !deepEqual(item, currentFilter?.items?.[i]),
    );

    changedItems.forEach((item) => {
      if (
        item.value ||
        item.operator === 'isEmpty' ||
        item.operator === 'isNotEmpty'
      ) {
        shouldUpdate = true;
      }
    });

    if (
      model.quickFilterValues?.length ||
      (currentFilter?.quickFilterValues.length !==
        model.quickFilterValues?.length &&
        currentFilter)
    ) {
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      setFilter(JSON.stringify(model));
    }
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) =>
      detailPanelContent ? createElement(detailPanelContent, { row }) : null,
    [detailPanelContent],
  );

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getTogglableColumns = useCallback(
    (c: GridColDef[]) =>
      c
        .filter(
          (column) =>
            column.field !== '__detail_panel_toggle__' &&
            column.field !== '__check__',
        )
        .map((column) => column.field),
    [],
  );

  useChannel(
    channelName || '',
    debounce(() => {
      if (channelName) {
        fetchDataHandler();
      }
    }, 500),
  );

  useEffect(() => {
    fetchDataHandler();
  }, [fetchDataHandler]);

  const theme = useTheme();

  return (
    <DataGridPro
      rows={state.rows}
      rowHeight={36}
      rowCount={state.rowCount || 0}
      getRowId={(row) => row._id}
      columns={columns}
      pagination
      paginationMode="server"
      hideFooterPagination={false}
      autoPageSize
      filterMode="server"
      filterDebounceMs={800}
      disableColumnMenu
      loading={state.loading}
      slots={{
        toolbar: DataGridToolbar,
        noRowsOverlay: NoDataError,
        detailPanelExpandIcon: detailPanelContent
          ? () => <IcArrowDown color={colors.error.main} />
          : undefined,
        detailPanelCollapseIcon: detailPanelContent
          ? () => <IcArrowUp color={colors.error.main} />
          : undefined,
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
        columnsManagement: {
          getTogglableColumns,
        },
        toolbar: {
          cta,
          hideFilter,
          hideQuickFilter,
        } as DataGridToolbarProps,
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize,
          },
        },
      }}
      disableRowSelectionOnClick
      onFilterModelChange={onFilterChange}
      onPaginationModelChange={handlePageChange}
      onColumnOrderChange={handleColumnChange}
      onColumnVisibilityModelChange={handleColumnVisibility}
      columnVisibilityModel={convertToKeyVisibilityMap(columnSettings)}
      onColumnWidthChange={handleColumnWidthChange}
      getDetailPanelContent={
        detailPanelContent ? getDetailPanelContent : undefined
      }
      getDetailPanelHeight={
        detailPanelContent ? getDetailPanelHeight : undefined
      }
      {...props}
      sx={{ ...dataGridStyles(theme), ...props.sx }}
    />
  );
};
