import { Theme } from '@mui/material';

import { colors } from '@/theme/variables';

export const toolbarStyles = (theme: Theme) => ({
  GridToolbarQuickFilter: {
    background: 'transparent',
    '.MuiInputBase-root': {
      background: `${theme.palette.background.paper} !important`,
    },
  },
  GridFilterPanel: {
    '&. MuiDataGrid-panel': {
      position: 'absolute',
      right: 0,
      top: 40,
      background: theme.palette.background.paper,
      zIndex: 1,
      '& .MuiInputBase-root': {
        width: '50px',
      },
    },
  },
});

export const dataGridStyles = (theme: Theme) => ({
  borderRadius: '8px',
  borderColor: 'transparent',
  '& .MuiDataGrid-main': {
    background: theme.palette.background.paper,
    padding: '0 10px',
  },
  '& .MuiDataGrid-filler': {
    background: theme.palette.background.paper,
    borderBottom: '0px solid transparent !important',
  },
  '& .MuiDataGrid-cell': {
    borderTopColor: colors.grey[10],
  },
  '& .MuiDataGrid-cell:focus': { outline: 'none' },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '& .MuiStack-root': { background: theme.palette.background.paper },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid  ${colors.grey[10]}`,
    borderBottom: '0px solid transparent !important',
  },
  '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiTablePagination-root': { background: theme.palette.background.paper },
  '& .MuiInputBase-root': {
    height: 'fit-content',
  },
  '& .MuiDataGrid-toolbarQuickFilter': { padding: 0, height: '38px' },
  '& .MuiDataGrid-detailPanel': { backgroundColor: colors.white },
  '& .MuiDataGrid-scrollbarFiller': { display: 'none' },
});
