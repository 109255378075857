import { FC, ReactNode } from 'react';

import { MotionProps, motion } from 'framer-motion';

interface AnimationWrapperProps extends MotionProps {
  children: ReactNode;
  initial?: any;
  animate?: any;
  exit?: any;
  transition?: any;
}

export const AnimationWrapper: FC<AnimationWrapperProps> = ({
  children,
  initial = { x: '100%' },
  animate = { x: '0' },
  exit = { x: '-100%', opacity: 0 },
  transition = { ease: 'easeIn', duration: 0.2 },
  ...rest
}) => {
  return (
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
        zIndex: 3,
      }}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
