import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RegularBreakpoints, Stack } from '@mui/material';

import { KaseApi } from '@/api';
import {
  FileGrid,
  IViewCard,
  IViewItem,
  ViewCard,
  ViewItem,
} from '@/components';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { IMasterName } from '@/models';
import { useToastStore } from '@/store';
import { flattenObject } from '@/utils';

import { useMasterNamesContext } from '..';
import {
  MasterNameViewFields,
  MasterOrganizationViewFields,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';

type MasterNameOrganizationViewProps = {
  hideHeader?: boolean;
  isMasterIndex?: boolean;
};

export const MasterNameOrganizationView = ({
  hideHeader,
}: MasterNameOrganizationViewProps) => {
  const { updateToast } = useToastStore();
  const { currentMastername } = useMasterNamesContext();
  const [currentName, setCurrentName] = useState<IMasterName>();
  const { updateCurrentScreen } = useTabLayoutContext();

  const { id: organizationId } = useParams();

  const flattenNameObj = flattenObject({
    ...currentName,
  });

  useEffect(() => {
    if (currentMastername?.masterName._id) fetchCurrentMastername();
  }, [currentMastername?.masterName._id]);

  useEffect(() => {
    if (organizationId) {
      fetchCurrentMastername();
    }
  }, [organizationId]);

  const fetchCurrentMastername = async () => {
    try {
      const res = organizationId
        ? await KaseApi.getMasterOrganization(organizationId)
        : await KaseApi.getMasterOrganization(
            String(currentMastername?.masterName._id),
          );
      setCurrentName(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.NamesList);
  };

  const renderItems =
    (items: IViewItem[], size?: RegularBreakpoints) =>
    (row: Record<string, any>) =>
      items?.map((item: IViewItem) => {
        let value = item.value ? row[item.value as string] : row;
        if (item.parser) {
          value = item.parser(value);
        }

        return (
          <ViewItem
            key={item.label}
            md={12}
            {...item}
            {...size}
            value={value}
          />
        );
      });

  const renderViewCardContent = ({
    key,
    items,
    size,
    multiple,
  }: IViewCard & { multiple?: string }) => {
    if (key === 'photos') {
      return (
        <ViewItem sx={{ mt: -4 }} md={8}>
          <FileGrid files={flattenNameObj.files} />
        </ViewItem>
      );
    }
    const rowRenderer = renderItems(items, size);

    if (multiple) {
      const values = flattenNameObj[multiple];
      return values?.map(rowRenderer);
    }

    return rowRenderer(flattenNameObj);
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.nameList}>
      {!hideHeader && (
        <PageBackHeader title="View Organization" goBack={onGoBack} />
      )}
      <Stack
        overflow="auto"
        sx={{
          '.p-kase-edit &': { p: 2.5, borderRadius: 2 },
        }}
      >
        {MasterOrganizationViewFields.map((data, index) => (
          <ViewCard
            key={data.key}
            title={data.title}
            showDivider={index !== MasterNameViewFields.length - 1}
          >
            {renderViewCardContent(data)}
          </ViewCard>
        ))}
      </Stack>
    </TabAnimationWrapper>
  );
};
