import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { IAccount, IUser, IUserReq } from '@/models';
import { IDevice } from '@/models/device';

import { http } from './http';
import { IGetParams, IListResponse, IPaginatedListResponse } from './types';

export const UserApi = {
  get: async (params: IGetParams): Promise<AxiosResponse<IListResponse>> => {
    return http.get(`api/${ApiVersion.V1}/users/`, { params });
  },
  list: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IPaginatedListResponse>> => {
    return http.get(`api/${ApiVersion.V1}/users/list`, { params });
  },
  getUserDevices: (
    userId: string,
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IDevice>>> => {
    return http.get(`api/${ApiVersion.V1}/users/${userId}/devices`, { params });
  },
  getOne: async (id: string): Promise<AxiosResponse<IUser>> => {
    return http.get(`api/${ApiVersion.V1}/users/${id}`);
  },
  getNearByUsersFromHQ: async (): Promise<AxiosResponse<IUser[]>> => {
    return http.get(`api/${ApiVersion.V1}/users/nearby-from-hq`);
  },
  create: async (data: IUserReq): Promise<AxiosResponse<IUser>> => {
    return http.post(`api/${ApiVersion.V1}/users/`, data);
  },
  update: async ({ _id, ...data }: IUserReq): Promise<AxiosResponse<IUser>> => {
    return http.put(`api/${ApiVersion.V1}/users/${_id}`, data);
  },
  getCurrentUser: async (): Promise<AxiosResponse<IUser>> => {
    return http.get(`api/${ApiVersion.V2}/users/me`);
  },
  getMyAccount: async (): Promise<AxiosResponse<IAccount>> => {
    return http.get(`api/${ApiVersion.V1}/accounts/me`);
  },
  sendVerificationEmail: async (userId: string): Promise<AxiosResponse> => {
    return http.post(
      `api/${ApiVersion.V1}/users/${userId}/send-verification-email`,
    );
  },
  toggleLoginStatus: async (id: string): Promise<AxiosResponse<IUser>> => {
    return http.patch(`api/${ApiVersion.V1}/users/${id}/login-status`);
  },
  updateRoles: async ({
    id,
    ...data
  }: {
    id: string;
    roles: {
      role: string;
      agency?: string;
    }[];
  }): Promise<AxiosResponse<IAccount>> => {
    return http.patch(`api/${ApiVersion.V1}/users/${id}/roles`, data);
  },
  getAblyToken: async (): Promise<AxiosResponse> => {
    return http.post(`api/${ApiVersion.V1}/users/ably-token-request`);
  },
};
