import { FC } from 'react';

import { RefreshOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps';

import { useStyles } from './sharedMapStyles';

interface ResetMapControlProps {
  onReset: () => void;
  position?: ControlPosition;
}

export const ResetMapControl: FC<ResetMapControlProps> = ({
  onReset,
  position = ControlPosition.RIGHT_BOTTOM,
}) => {
  const classes = useStyles();

  return (
    <MapControl position={position}>
      <Box sx={{ mr: 1, mb: 1 }}>
        <IconButton onClick={onReset} className={`${classes.controllButton}`}>
          <RefreshOutlined sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    </MapControl>
  );
};
