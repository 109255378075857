import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { CssBaseline, Stack, styled, useTheme } from '@mui/material';

import { useAuthContext } from '@/hooks';
import useShowIncomingCalls from '@/hooks/useShowIncomingCalls';
import { useUserStore } from '@/store';
import { sizes } from '@/theme/variables';

import { IncomingCall } from './components/IncomingCall';
import MainSideBar from './components/SideBar/MainSideBar';

const MainLayoutRoot = styled(Stack)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  flexDirection: 'row',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,

  '& main': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
  },

  '& .main': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
    padding: '0px 20px 15px',
    overflow: 'hidden',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      paddingLeft: sizes.mainPadding.md,
      paddingRight: sizes.mainPadding.md,
      paddingBottom: sizes.mainPadding.md,
    },
  },
  '& .fullscreen': {
    padding: 0,
  },
}));

type MainLayoutProps = {
  isFullScreen?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = ({ isFullScreen = false }) => {
  const theme = useTheme();
  const { user } = useUserStore();
  const { accessToken, logout } = useAuthContext();
  const showIncomingCalls = useShowIncomingCalls(user);

  useEffect(() => {
    // Check token expiration every 5 minutes.
    const checkTokenExpirationInterval = setInterval(() => {
      if (!accessToken) return;
      const decodedJwt = JSON.parse(atob(accessToken?.split('.')[1]));

      if (decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    }, 300 * 1000);

    return () => {
      clearInterval(checkTokenExpirationInterval);
    };
  }, []);

  return (
    <MainLayoutRoot theme={theme}>
      <CssBaseline />

      {/* Sidebar */}
      <MainSideBar />

      {/* Page view */}
      <main>
        <div className={isFullScreen ? 'main fullscreen' : 'main'}>
          <Outlet />
          {showIncomingCalls && !isFullScreen && <IncomingCall />}
        </div>
      </main>
    </MainLayoutRoot>
  );
};
