import { IAuditModel } from '@/models/base';

export enum Platform {
  IOS = 'iOS', // for newer iOS devices
  IPHONE_OS = 'iPhone OS', // for older iOS devices (including older iPad models)
  IPAD_OS = 'iPadOS', // for iPads using iPadOS 15.0 or higher
  ANDROID = 'Android',
  MAC = 'Mac',
  WINDOWS = 'Windows',
  LINUX = 'Linux',
}

export enum DeviceStatus {
  ACTIVE = 'Active',
  LOGGED_OUT = 'Logged out',
}

export interface IDevice extends IAuditModel {
  uid: string;
  platform: Platform;
  model: string;
  manufacturer: string;
  version: string; // OS version
  appName?: string; // The name of the app, either Native App, or Edge, Chrome, Safari...
  appVersion: string; // Mobile app version
  fcmToken?: string;
  deviceStatus?: DeviceStatus;
  lastActive?: Date;
}

export type DeviceLoginData = Pick<
  IDevice,
  'platform' | 'manufacturer' | 'version' | 'appName' | 'appVersion'
>;
