import { FC, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { AvailableResources, UnitApi } from '@/api';
import { LocalStorageItems } from '@/constants';
import {
  IEquipment,
  ILineLayer,
  IPointLayer,
  IPolygonLayer,
  IResource,
  IUnitType,
  IVehicle,
  ResourceType,
} from '@/models';
import { useToastStore } from '@/store';

import { RenderFormField } from '../../../FormElements/RenderFormFields';
import { UnitResourceFormFields } from '../data';

interface UnitResourceFormProps {
  cfsId?: string;
  unitId?: string;
  agencyId?: string;
  withUnitOnly?: boolean;
  currentUnitType?: IUnitType;
  resources?: AvailableResources;
}

export const UnitResourceForm: FC<UnitResourceFormProps> = ({
  cfsId,
  unitId,
  agencyId,
  currentUnitType,
  withUnitOnly,
  resources: passedResources,
}) => {
  const { updateToast } = useToastStore();
  const [resources, setResources] = useState<AvailableResources>({});
  const { vehicles, users, equipments, polygons, points, lines } = resources;

  const employeeSelectItems = useMemo(() => {
    if (!users) return [];
    return users.map(({ _id, fullName, profile }) => {
      let label = '';
      const { badgeNumber, title } = profile?.employmentInformation || {};
      if (badgeNumber) {
        label += badgeNumber;
      }
      if (fullName) {
        label += label ? ` - ${fullName}` : fullName;
      }
      if (title) {
        label += label ? ` - ${title}` : title;
      }
      return {
        label,
        value: _id,
      };
    });
  }, [users]);

  const getSelectItems = (
    itemList:
      | IEquipment[]
      | IPolygonLayer[]
      | ILineLayer[]
      | IPointLayer[]
      | IVehicle[]
      | undefined,
  ) => {
    if (!itemList) return [];
    return itemList.map(({ _id, name }) => ({
      label: name,
      value: _id,
    }));
  };

  const fetchResources = async () => {
    try {
      if (agencyId) {
        localStorage.setItem(LocalStorageItems.CurrentAgencyId, agencyId);
      }
      const res = await UnitApi.getAvailableResources(
        currentUnitType?._id,
        unitId,
      );

      // Merge fetched resources with passed resources
      const mergedResources = { ...res.data };
      const resourceTypes: (keyof AvailableResources)[] = [
        'vehicles',
        'users',
        'equipments',
        'polygons',
        'points',
        'lines',
      ];

      resourceTypes.forEach((type) => {
        if (passedResources && passedResources[type]) {
          const existingItems = mergedResources[type] || [];
          const additionalItems = passedResources[type] || [];

          // Merge and remove duplicates
          const uniqueItems = [...existingItems, ...additionalItems].filter(
            (item, index, self) =>
              index === self.findIndex((t) => t._id === item._id),
          );

          // Type-safe assignment
          (mergedResources[type] as any) = uniqueItems;
        }
      });

      setResources(mergedResources);

      if (cfsId) {
        localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  useEffect(() => {
    if (currentUnitType) {
      fetchResources();
    }
  }, [currentUnitType, setResources]);

  const getOptions = (resourceType: ResourceType) => {
    switch (resourceType) {
      case ResourceType.VEHICLE:
        return getSelectItems(vehicles);
      case ResourceType.PEOPLE:
        return employeeSelectItems;
      case ResourceType.EQUIPMENT:
        return getSelectItems(equipments);
      case ResourceType.POLYGON_LAYER:
        return getSelectItems(polygons);
      case ResourceType.POINT_LAYER:
        return getSelectItems(points);
      case ResourceType.LINE_LAYER:
        return getSelectItems(lines);
      default:
        return [];
    }
  };

  const validateResourceSelectedQuantity = (
    selectedLength: number,
    min: number,
    max?: number,
    errorLabel?: string,
  ) => {
    if (selectedLength < min) {
      if (max) {
        return `You must assign between ${min} and ${max} ${errorLabel}s to this unit`;
      }
      return min > 1
        ? `You must assign at least ${min} ${errorLabel}s to this unit`
        : `You must assign at least ${min} ${errorLabel} to this unit`;
    }

    if (max && selectedLength > max) {
      return `You must assign between ${min} and ${max} ${errorLabel}s to this unit`;
    }

    return true;
  };

  const equipmentWithSubTypeValidator = (value: string[]) => {
    if (withUnitOnly) return true;

    const filteredValue = value?.filter((item) => {
      const options = getOptions(ResourceType.EQUIPMENT);
      return options.find((option) => option.value === item);
    });

    // We are certain equipment resource items exist if we got to this method, hence the type assertion.
    const allEquipments = (currentUnitType?.resources as IResource[]).filter(
      (eq) => eq.type === ResourceType.EQUIPMENT,
    );

    // Validate each equipment resource item
    for (const equipment of allEquipments) {
      // Out of the selected value (a list of equipment ids) for this field. We need to
      // find the correspending equipment resource, and filter the selected values
      // that are matching the current resource item being validated (by subtype).
      const selectedSubTypeEquipment = filteredValue
        .map((id) => equipments?.find((eq) => eq._id === id))
        .filter(
          (eq): eq is IEquipment =>
            eq !== undefined && eq.type === equipment.subType,
        );

      const validationResult = validateResourceSelectedQuantity(
        selectedSubTypeEquipment.length,
        equipment.min,
        equipment.max,
        equipment.subType?.toLowerCase(),
      );

      // Exit early if the resource item conditions are not met.
      if (validationResult !== true) {
        return validationResult;
      }
    }

    // If we got here it means that all equipment resource items were validated successfully.
    return true;
  };

  const validator = (
    value: string[],
    resource: IResource,
    errorLabel: string,
  ) => {
    const { min, max, type } = resource;
    if (withUnitOnly) return true;

    const filteredValue = value?.filter((item) => {
      const options = getOptions(type);
      return options.find((option) => option.value === item);
    });

    return validateResourceSelectedQuantity(
      filteredValue.length,
      min,
      max,
      errorLabel,
    );
  };

  const filteredTypeResources = useMemo(() => {
    if (!currentUnitType?.resources) return [];

    const uniqueResources = currentUnitType.resources.reduce<IResource[]>(
      (acc, resource) => {
        if (!acc.some((item) => item.type === resource.type)) {
          acc.push(resource);
        }
        return acc;
      },
      [],
    );

    return withUnitOnly
      ? uniqueResources.filter((item) => item.withUnit)
      : uniqueResources;
  }, [currentUnitType, withUnitOnly]);

  return (
    <>
      {filteredTypeResources.map((resourceField) => {
        const formField = UnitResourceFormFields[resourceField.type];
        if (!formField) return null;
        return (
          <Grid key={formField.name} xs={12} {...formField.grid} item>
            <RenderFormField
              {...formField}
              items={getOptions(resourceField.type)}
              rules={{
                validate: (value) =>
                  resourceField.type === ResourceType.EQUIPMENT &&
                  !!resourceField.subType
                    ? equipmentWithSubTypeValidator(value)
                    : validator(value, resourceField, formField.errorLabel),
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};
