import { IconButton } from '@mui/material';
import {
  GridColDef,
  getGridStringOperators,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcVisibility, IcPencil, IcDeleteTrash } from '@/assets/images';
import { excludeValues } from '@/components/DataGrid/utils';
import { DATE_TIME_FORMAT } from '@/config';
import { EthnicOriginList, RaceList } from '@/constants/form_inputs';
import { IMasterNamePerson } from '@/models';
import { getAddressLocation } from '@/services';
import { formatPhoneNumber } from '@/utils';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const { firstName, lastName } = params.row;
    return `${firstName} ${lastName}`;
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'isNotEmpty',
      'isEmpty',
      'isAnyOf',
      'equals',
      'doesNotEqual',
    ]),
  ),
  valueGetter: (_, row) => row.firstName + row.lastName,
});

const address = (): Partial<GridColDef> => ({
  renderCell: (params) => getAddressLocation(params.row.address) || '/',
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'equals', 'doesNotEqual']),
  ),
  valueGetter: (_, row) => getAddressLocation(row.address),
});

const birthday = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.birthday
      ? moment(params.row.birthday).format(DATE_TIME_FORMAT)
      : '/',
  filterOperators: getGridDateOperators().filter(
    excludeValues(['isNotEmpty', 'isEmpty']),
  ),
});

const phone = (): Partial<GridColDef> => ({
  renderCell: ({ row }: { row: IMasterNamePerson }) => {
    if (row.phoneNumbers?.length === 0) return '/';
    return row.phoneNumbers?.map((item) => (
      <div key={item.phoneNumber}>
        {`${formatPhoneNumber(item.phoneNumber)} - ${item.type}`}
      </div>
    ));
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'equals',
      'doesNotEqual',
      'isAnyOf',
      'startsWith',
      'endsWith',
    ]),
  ),
  valueGetter: (_, row) => row.phoneNumbers?.[0]?.phoneNumber,
});

const ethnicity = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    EthnicOriginList[
      params.row.ethnicOrigin as keyof typeof EthnicOriginList
    ] || '/',
  filterable: false,
  valueGetter: (_, row) =>
    EthnicOriginList[row.ethnicOrigin as keyof typeof EthnicOriginList],
});

const race = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    RaceList[params.row.race as keyof typeof RaceList] || '/',
  filterable: false,
});

const ssn = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'equals',
      'doesNotEqual',
      'isAnyOf',
      'startsWith',
      'endsWith',
    ]),
  ),
});

const actions = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterNamePerson) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          navigate?.(`${params.row._id}/view`);
        }}
      >
        <IcVisibility />
      </IconButton>
      <IconButton
        aria-label="edit"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}/edit`);
        }}
      >
        <IcPencil />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(params.row);
        }}
      >
        <IcDeleteTrash />
      </IconButton>
    </>
  ),
});

export const masterPersonsCols = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterNamePerson) => void,
) => {
  return {
    name,
    address,
    birthday,
    phone,
    ethnicity,
    race,
    ssn,
    actions: () => actions(navigate, onDelete),
  };
};
