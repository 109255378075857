import { IconButton } from '@mui/material';
import { GridColDef, getGridStringOperators } from '@mui/x-data-grid-pro';

import { IcVisibility, IcPencil, IcDeleteTrash } from '@/assets/images';
import { excludeValues } from '@/components/DataGrid/utils';
import { IMasterNameOrganization, OrganizationType } from '@/models';
import { getAddressLocation } from '@/services';

const name = (): Partial<GridColDef> => ({
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
});

const address = (): Partial<GridColDef> => ({
  renderCell: (params) => getAddressLocation(params.row.address) || '/',
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'equals', 'doesNotEqual']),
  ),
  valueGetter: (_, row) => getAddressLocation(row.address),
});

const organizationType = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.organizationType
      ? OrganizationType[
          params.row.organizationType as keyof typeof OrganizationType
        ]
      : '/',
  filterable: false,
});

const subAddress = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.address?.subAddress || '/',
  valueGetter: (_, row) => row?.address?.subAddress,
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'equals', 'doesNotEqual']),
  ),
});

const actions = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterNameOrganization) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          navigate?.(`${params.row._id}/view`);
        }}
      >
        <IcVisibility />
      </IconButton>
      <IconButton
        aria-label="edit"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}/edit`);
        }}
      >
        <IcPencil />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(params.row);
        }}
      >
        <IcDeleteTrash />
      </IconButton>
    </>
  ),
});

export const masterOrganizationCols = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterNameOrganization) => void,
) => {
  return {
    name,
    address,
    organizationType,
    subAddress,
    actions: () => actions(navigate, onDelete),
  };
};
