import { useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ControlPosition, MapControl, useMap } from '@vis.gl/react-google-maps';

import { IcMarkerCfs, ICRapidSOS } from '@/assets/images';
import { ASSIGNMENT_STATUS } from '@/constants';
import { useCFSContext } from '@/hooks';
import { ICallerLocation } from '@/models';

import { useStyles } from './sharedMapStyles';

interface QuickNavigationControlProps {
  onClick: (value: {
    center: { lat: number; lng: number };
    zoom: number;
  }) => void;
  position?: ControlPosition;
}

export const QuickNavigationControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
  onClick,
}: QuickNavigationControlProps) => {
  const { cfs, locations } = useCFSContext();
  const map = useMap();
  if (!map) return null;

  const [latestLocation, setLatestLocation] = useState<ICallerLocation | null>(
    null,
  );

  useEffect(() => {
    if (locations && locations.length > 0) {
      setLatestLocation((prevState) => {
        // This is a hack to get the maps library to re-position the buttons, and force a re-render of the map.
        // It will only happen the first time a location is found.
        if (prevState === null) {
          map.setZoom(map.getZoom() as number);
        }

        return locations[locations.length - 1];
      });
    }
  }, [locations, locations?.length]);

  const classes = useStyles();

  return (
    <MapControl position={position}>
      <Box sx={{ mr: 1, mb: 1 }}>
        {cfs && (
          <IconButton
            className={
              latestLocation
                ? `${classes.controllButton} ${classes.topButtonGroup}`
                : `${classes.controllButton}`
            }
            onClick={() => {
              const coordinates = cfs.addressInfo?.address?.point?.coordinates;
              if (coordinates && coordinates.length > 0) {
                onClick({
                  zoom: 15,
                  center: {
                    lng: coordinates[0],
                    lat: coordinates[1],
                  },
                });
              }
            }}
          >
            <IcMarkerCfs
              color={ASSIGNMENT_STATUS[cfs?.assignmentStatus || 'NEW'].color}
            />
          </IconButton>
        )}

        {latestLocation && (
          <IconButton
            className={`${classes.controllButton} ${classes.bottomButtonGroup}`}
            onClick={() =>
              onClick({
                zoom: 15,
                center: {
                  lng: Number(latestLocation.longitude),
                  lat: Number(latestLocation.latitude),
                },
              })
            }
          >
            <ICRapidSOS />
          </IconButton>
        )}
      </Box>
    </MapControl>
  );
};
