import { Box, IconButton } from '@mui/material';
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps';

import { IcExpandIn, IcExpandOut } from '@/assets/images';

import { useStyles } from './sharedMapStyles';

export type ExpandControlProps = {
  isExpanded?: boolean;
  position?: ControlPosition;
  onExpand?: () => void;
  onCondense?: () => void;
};

export const ExpandControl = ({
  position = ControlPosition.RIGHT_BOTTOM,
  isExpanded,
  onCondense,
  onExpand,
}: ExpandControlProps) => {
  const classes = useStyles();

  const renderButton = () => {
    if (isExpanded) {
      return (
        <IconButton
          className={`${classes.controllButton}`}
          onClick={onCondense}
        >
          <IcExpandIn />
        </IconButton>
      );
    }

    return (
      <IconButton className={`${classes.controllButton} `} onClick={onExpand}>
        <IcExpandOut />
      </IconButton>
    );
  };

  return (
    <MapControl position={position}>
      <Box sx={{ mr: 1, mb: 1 }}>
        <div>{renderButton()}</div>
      </Box>
    </MapControl>
  );
};
