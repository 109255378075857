import { useEffect, useRef, useState } from 'react';

import { logger } from '@azure/storage-blob';
import { LinearProgress } from '@mui/material';
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';

import { ApiVersion, baseURL } from '@/config';
import { useAuthContext } from '@/hooks';

import { UserApi } from './api';
import { LocalStorageItems } from './constants';
import { AppRoutes } from './routes';
import { useUserStore } from './store';

function AblyWrapper() {
  const { accessToken } = useAuthContext();
  const { userAgencyId } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);

  const ablyRef = useRef<Ably.Types.RealtimePromise | undefined>(undefined);

  const setAgencyId = () => {
    const pathSegments = window?.location?.pathname?.split('/');
    const agencyIdIndex = pathSegments?.indexOf('agencies') + 1;
    const agencyId = pathSegments[agencyIdIndex];
    if (agencyId) {
      localStorage.setItem(LocalStorageItems.CurrentAgencyId, agencyId);
    } else {
      localStorage.setItem(
        LocalStorageItems.CurrentAgencyId,
        userAgencyId || '',
      );
    }
  };

  useEffect(() => {
    if (accessToken && userAgencyId) {
      if (ablyRef.current) {
        ablyRef.current.close();
      }

      ablyRef.current = new Ably.Realtime.Promise({
        authHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'X-HELPTAPP-AGENCY': userAgencyId || '',
        },
        authUrl: `${baseURL}api/${ApiVersion.V1}/users/ably-token-request`,
        authMethod: 'POST',
        authCallback: async (_, callback) => {
          try {
            setAgencyId();
            const tokenRequest = await UserApi.getAblyToken();
            callback(null, tokenRequest.data);
          } catch (err) {
            logger.error(err);
            if (err) {
              callback(err as string, null);
            }
          }
        },
        echoMessages: false,
      });

      ablyRef.current.connection.once('connected', () => {
        setIsLoading(false);
      });

      ablyRef.current.connection.on('failed', (stateChange) => {
        if (stateChange.reason?.statusCode === 403) {
          logger.error('Ably connection forbidden:', stateChange.reason);
          setIsLoading(false);
        }
      });
    }

    return () => {
      if (ablyRef.current) {
        ablyRef.current.close();
        ablyRef.current = undefined;
      }
    };
  }, [accessToken, userAgencyId]);

  if (!accessToken || !userAgencyId) {
    return <AppRoutes />;
  }

  if (isLoading) {
    return (
      <LinearProgress color="error" variant="indeterminate"></LinearProgress>
    );
  }

  if (ablyRef.current) {
    return (
      <AblyProvider client={ablyRef.current}>
        <AppRoutes />
      </AblyProvider>
    );
  }
}

export default AblyWrapper;
