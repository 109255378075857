import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcDotSpread, IcFile, IcPerson } from '@/assets/images';
import { DATE_FORMAT } from '@/config';
import { RaceList } from '@/constants/form_inputs';
import { formatPhoneNumber } from '@/utils/pattern';

import { KaseStatusChip } from './styles';

const name = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.masterName?.name || '-',
  valueGetter: (_, row) => row.masterName?.name,
});

const dateOfBirth = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const birthday = params.row.masterName?.birthday;
    return birthday ? moment(birthday).format(DATE_FORMAT) : '-';
  },
  valueGetter: (_, row) => row.masterName?.birthday,
});

const isVerified = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.masterName?.isVerified ? 'Verified' : 'Unverified',
});

const phone = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const phoneNumber = params.row.masterName?.phoneNumbers?.[0]?.phoneNumber;
    return phoneNumber ? formatPhoneNumber(phoneNumber) : '-';
  },
  valueGetter: (_, row) => row.masterName?.phoneNumbers?.[0]?.phoneNumber,
});

const involvement = (isKase = false): Partial<GridColDef> => ({
  renderCell: (params) => {
    if (!params?.row?.relationship?.involvement) return '-';

    if (isKase) {
      return params.row.relationship?.involvement;
    }

    return Array.isArray(params.row?.relationship?.involvement)
      ? params.row.relationship.involvement.map(
          (relation: string, index: number) => (
            <KaseStatusChip key={index} label={relation} />
          ),
        )
      : params.row.relationship?.involvement;
  },
  valueGetter: (_, row) => row.relationship?.involvement,
});

const race = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const raceValue = params.row.masterName?.race;
    return RaceList[raceValue as keyof typeof RaceList] || '-';
  },
  valueGetter: (_, row) => row.masterName?.race,
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
  onSetInvolvement?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => void,
  onGotoNotes?: (event: React.MouseEvent<HTMLButtonElement>, row: any) => void,
): Partial<GridColDef> => ({
  sortable: false,
  renderCell: (params) => (
    <>
      <Tooltip title="set involvement" arrow>
        <IconButton
          onClick={(e) => onSetInvolvement?.(e, params.row.masterName._id)}
        >
          <IcPerson />
        </IconButton>
      </Tooltip>
      <Tooltip title="view notes" arrow>
        <IconButton onClick={(e) => onGotoNotes?.(e, params.row)}>
          <IcFile />
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          openMenu?.(e, params.row);
        }}
      >
        <IcDotSpread />
      </IconButton>
    </>
  ),
});

export const masterNamesCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
  onSetInvolvement?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => void,
  onGotoNotes?: (event: React.MouseEvent<HTMLButtonElement>, row: any) => void,
  isKase = false,
) => ({
  name,
  dateOfBirth,
  isVerified,
  phone,
  involvement: () => involvement(isKase),
  race,
  actions: () => actions(openMenu, onSetInvolvement, onGotoNotes),
});
