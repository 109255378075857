import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { IcDotSpread } from '@/assets/images';
import { IMasterVehicle } from '@/models/vehicle';

const licensePlate = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.licensePlate?.number || '-',
  valueGetter: (_, row) => row.licensePlate?.number,
});

const color = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.color || '-',
});

const make = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.make || '-',
});

const model = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.model || '-',
});

const isVerified = (): Partial<GridColDef> => ({
  renderCell: (params) => (params.row.isVerified ? 'Verified' : 'Unverified'),
  valueGetter: (_, row) => (row.isVerified ? 'Verified' : 'Unverified'),
});

const actions = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IMasterVehicle,
  ) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const sharedVehicleCols = (
  openMenu?: (
    event: React.MouseEvent<HTMLButtonElement>,
    param: IMasterVehicle,
  ) => void,
) => {
  return {
    licensePlate,
    color,
    make,
    model,
    isVerified,
    actions: () => actions(openMenu),
  };
};
