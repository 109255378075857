import { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import { FileGrid } from '@/components';
import { useCFSContext } from '@/hooks';
import { PageBackHeader, TabAnimationWrapper } from '@/layouts/TabLayout';
import { IFile } from '@/models';

const CFSMedia = () => {
  const { cfs } = useCFSContext();
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    if (!cfs) {
      return;
    }
    setFiles(cfs.files || []);
  }, [cfs]);

  return (
    <TabAnimationWrapper>
      <PageBackHeader title="Media" style={{ fontWeight: 500 }} />

      <Typography style={{ marginBottom: 20, marginTop: 10 }}>
        Photos and videos
      </Typography>
      <Stack>
        {files.length === 0 && (
          <Typography>No files available to display</Typography>
        )}
        <FileGrid files={files} />
      </Stack>
    </TabAnimationWrapper>
  );
};

export default CFSMedia;
