export type ModalState = {
  noCfsAssigned: boolean;
  newCfsAssigned: boolean;
  directions: boolean;
  requestBackup: boolean;
  backupRequested: boolean;
  backupRequestRejected: boolean;
  cfsDetails: boolean;
  closeCfsAndAssign: boolean;
  confirmAssignment: boolean;
  moveToQueueAndAssign: boolean;
  addToQueue: boolean;
};

export const initialModalState: ModalState = {
  cfsDetails: false,
  requestBackup: false,
  backupRequested: false,
  backupRequestRejected: false,
  directions: false,
  newCfsAssigned: false,
  noCfsAssigned: false,
  closeCfsAndAssign: false,
  confirmAssignment: false,
  moveToQueueAndAssign: false,
  addToQueue: false,
};
