import { Gender } from '@/constants/form_inputs';

import { IAddress, IAddressForm } from './address';
import { IAgency } from './agency';
import { IAuditModel, ObjectId } from './base';
import { IEquipment } from './equipment';
import { IFile } from './file';
import { ILocation, IMultiPolygon, IPoint } from './geo';

export enum Role {
  // Account roles
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  DISPATCHER = 'DISPATCHER',
  VIEWER = 'VIEWER',

  // Agency roles
  AGENCY_ADMIN = 'AGENCY_ADMIN',

  FIRST_RESPONDER = 'FIRST_RESPONDER',
  EMPLOYEE = 'EMPLOYEE',
  POLICE = 'POLICE',
  KASE_APPROVER = 'KASE_APPROVER',
  EVIDENCE_COLLECTOR = 'EVIDENCE_COLLECTOR',
  EVIDENCE_TRANSPORTER = 'EVIDENCE_TRANSPORTER',
  EVIDENCE_CUSTODIAN = 'EVIDENCE_CUSTODIAN',
}

export enum UserStatus {
  INACTIVE = 'INACTIVE',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum BloodType {
  A_POSITIVE = 'A+',
  A_NEGATIVE = 'A-',
  B_POSITIVE = 'B+',
  B_NEGATIVE = 'B-',
  O_POSITIVE = 'O+',
  O_NEGATIVE = 'O-',
  AB_POSITIVE = 'AB+',
  AB_NEGATIVE = 'AB-',
  UNKNOWN = 'UN',
}

export interface IRank extends IAuditModel {
  name: string;
  agency: string;
}

export interface IDepartment extends IAuditModel {
  name: string;
  agency: string;
}

export interface IContact {
  name: string;
  phoneNumber: string;
}

export interface IDriverLicence {
  number: string;
  expireDate: string;
}

export interface ICertification {
  name: string;
  expireDate: string;
  file?: IFile;
}

export interface ISupervisor extends ObjectId {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

interface IEmploymentInformation {
  title: string;
  rank?: IRank;
  badgeNumber: string;
  department?: IDepartment;
  seniority: number;
  supervisor?: ISupervisor;
}

interface IEmploymentInformationForm
  extends Omit<IEmploymentInformation, 'rank' | 'department' | 'supervisor'> {
  rank?: string;
  department?: string;
  supervisor?: string;
}

export interface IUserRole {
  agency?: string | IAgency;
  role: Role;
}

export interface IProfileForm {
  gender: Gender;
  birthday: string;
  bloodType: BloodType;
  address?: IAddressForm;
  emergencyContact: IContact;
  certifications: ICertification[];
  employmentInformation: IEmploymentInformationForm;
}

export interface IProfile
  extends Omit<IProfileForm, 'address' | 'employmentInformation'> {
  address: IAddress;
  employmentInformation: IEmploymentInformation;
}

export interface IProfileReq extends Omit<IProfileForm, 'address'> {
  address?: string;
}

export interface IUserForm extends ObjectId {
  email: string;
  phoneNumber?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  roles: IUserRole[];
  profile: IProfileForm;
}

export interface IUserReq extends Omit<IUserForm, 'profile'> {
  profile: IProfileReq;
}

export interface IUser extends IAuditModel, Omit<IUserForm, 'profile'> {
  profile: IProfile;
  isLoginEnabled: boolean;
  status?: UserStatus;
  latsLoggedIn?: Date;
  location?: ILocation;
  gpsTracker?: IEquipment;
  readonly fullName: string;
}

export enum AccountStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export interface IFeaturesSetting {
  showNearestIntersectionWeb?: boolean;
  isEnabledInventoryLocation: boolean;
  isEnabledVendors: boolean;
  isEnabledUnderFire: boolean;
  isEnabledAutoTaskCreation: boolean;
  cfsExternalService?: string;
  isEnabledAIGeneratedCFSDescription: boolean;
  isEnabledRapidSOSForDifferingReporter?: boolean;
}

export interface IAccountSettings {
  allowCFSAI: boolean;
  autoCFSRTD: boolean;
  groupCFSRequiredUnits: boolean;
  files?: IFile[];
  webhookSecret?: string; // VOIP dialpad webhook secret key. This jwt secret is used to create webhook in dialpad.
  center?: IPoint;
  areas?: IMultiPolygon | null;
  defaultZoom?: number;
  hqAddress?: string | null;
  numberOfNearestResponders: number;
  features?: IFeaturesSetting | null;
  demoMode?: boolean;
}

export interface IAccount {
  _id?: string;
  name: string;
  phoneNumber: string;
  email?: string;
  number?: string;
  address: IAddress;
  contact1: IContact;
  contact2: IContact;
  subdomain?: string;
  ips: string[];
  status?: AccountStatus;
  settings: IAccountSettings;
}
