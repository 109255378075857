import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import {
  MapCameraChangedEvent,
  MapCameraProps,
  useMap,
} from '@vis.gl/react-google-maps';

import { AddressApi, CfsApi } from '@/api';
import { IcMarkerCfs } from '@/assets/images';
import { FormCard } from '@/components';
import { ExpandControl, FireHydrantMap, IMarker } from '@/components/GoogleMap';
import { QuickNavigationControl } from '@/components/GoogleMap/QuickNavigationControl';
import { defaultMapCenter } from '@/config';
import { ASSIGNMENT_STATUS } from '@/constants';
import { useUnitShiftContext } from '@/hooks';
import { useCFSContext } from '@/hooks/useCFSContext';
import { googlePlaceService } from '@/services';
import { useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';
import { formatDistance, openNewWindow } from '@/utils';

import { FormItemRoot } from '../styles';

interface CFSMapProps {
  cfsId?: string;
}

export const CFSMap = (props: CFSMapProps) => {
  const { watch } = useFormContext();
  const {
    cfs,
    cfsMapMarkers = [],
    setMapBounds,
    ablyCfsChannel,
    updateCFS,
    locationBreadCrumb,
    locationMapMarkers,
  } = useCFSContext();
  const { filteredUnitShiftMarkers } = useUnitShiftContext();
  const { account } = useUserStore();
  const [mapCameraValues, setMapCameraValues] =
    useState<Partial<MapCameraProps>>();
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [addressMarker, setAddressMarker] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [mapTypeId, setMapTypeId] = useState<string>();
  const addressInfo = watch('addressInfo');
  const nearestIntersection = addressInfo?.address?.nearestIntersection;
  const urlParams = useParams();
  const cfsId = props?.cfsId || String(urlParams.cfsId);
  const coordinates = addressInfo?.address?.point?.coordinates;
  const { updateToast } = useToastStore();
  const map = useMap();

  useEffect(() => {
    // Configure the click listener.
    if (map) {
      map?.addListener(
        'click',
        async (mapsMouseEvent: google.maps.MapMouseEvent) => {
          // const { lat, lng } = mapsMouseEvent.latLng ?? {};
          const lat = mapsMouseEvent.latLng?.lat();
          const lng = mapsMouseEvent.latLng?.lng();

          if (lat && lng) {
            const place = await googlePlaceService.geocodeCoordinates(lat, lng);

            if (place.geometry && place.geometry.location) {
              place.geometry.location.lat = () => lat;
              place.geometry.location.lng = () => lng;
            }

            setAddressMarker(place);
          }
        },
      );
    }
  }, [map]);

  const handleNewAddress = async () => {
    if (addressMarker && addressMarker.place_id) {
      try {
        const address = await googlePlaceService.getPlaceDetails(
          addressMarker.place_id,
        );

        if (address) {
          const addressResponse = await AddressApi.createAddress({
            ...address,
            point: {
              type: 'Point',
              coordinates: [
                addressMarker.geometry?.location?.lng() ?? 0,
                addressMarker.geometry?.location?.lat() ?? 0,
              ],
            },
          });

          CfsApi.updateAddressInfo(cfsId, addressResponse.data._id as string)
            .then((res) => {
              ablyCfsChannel?.publish('cfsForm', {
                addressInfo: {
                  ...cfs?.addressInfo,
                  ...res.data.addressInfo,
                },
              });
              if (cfs) {
                updateCFS({
                  ...cfs,
                  addressInfo: res.data.addressInfo,
                });
              }
              setAddressMarker(null);
            })
            .catch((err: any) => {
              updateToast({ open: true, message: err.message });
            });
        }
      } catch (err: any) {
        updateToast({ open: true, message: err.message });
      }
    }
  };

  const newCfsAddressMarker: IMarker | null = useMemo<IMarker | null>(() => {
    return addressMarker
      ? {
          key: String(addressMarker.place_id),
          maxWidth: 400,
          info: (
            <Box p={1}>
              <Typography fontWeight={500} fontSize={14}>
                {addressMarker.formatted_address}
              </Typography>
              <Typography fontWeight={400} fontSize={12} color="#007AFF">
                {addressMarker?.geometry?.location?.lat()} -
                {addressMarker?.geometry?.location?.lng()}
              </Typography>
              <Button
                color="error"
                variant="contained"
                onClick={handleNewAddress}
              >
                Use as CFS Address
              </Button>
            </Box>
          ),
          title: 'New address',
          position: {
            lng: addressMarker?.geometry?.location?.lng() ?? 0,
            lat: addressMarker?.geometry?.location?.lat() ?? 0,
          },
          icon: <IcMarkerCfs color={colors.warning.main} />,
          showInfo: true,
          toggleInfo: () => {
            setAddressMarker(null);
          },
        }
      : null;
  }, [addressMarker]);

  const showNearestIntersection =
    account?.settings?.features?.showNearestIntersectionWeb;

  useEffect(() => {
    if (coordinates?.length && cfs) {
      setMarkers([
        {
          key: 'emergency',
          title: cfs.number,
          position: { lng: coordinates[0], lat: coordinates[1] },
          icon: (
            <IcMarkerCfs
              color={ASSIGNMENT_STATUS[cfs.assignmentStatus].color}
            />
          ),
        },
      ]);
      setMapCameraValues({
        center: { lng: coordinates[0], lat: coordinates[1] },
        zoom: 15,
      });
    } else {
      setMarkers([]);
      const accountCenterCoordinates = account?.settings.center?.coordinates;
      setMapCameraValues({
        center: accountCenterCoordinates?.length
          ? {
              lng: accountCenterCoordinates[0],
              lat: accountCenterCoordinates[1],
            }
          : defaultMapCenter,
        zoom: 11,
      });
    }
  }, [addressInfo?.address, cfs?.assignmentStatus]);

  const crossStreetsLabel = useMemo(() => {
    const { crossStreet1, crossStreet2, distance1, distance2 } =
      addressInfo?.address || {};
    if (crossStreet1 && crossStreet2) {
      return `${crossStreet1}(${formatDistance(
        distance1,
      )}) and ${crossStreet2}(${formatDistance(distance2)})`;
    }
    return '-';
  }, [addressInfo?.address]);

  const handleExpandMap = () => {
    let newWindowUrl = `/unit-shifts?cfsId=${cfsId}`;
    if (mapTypeId) {
      newWindowUrl += `&mapTypeId=${mapTypeId}`;
    }
    openNewWindow(newWindowUrl);
  };

  const handleMapBoundsChanged = (e: MapCameraChangedEvent) => {
    setMapBounds(e.detail.bounds);
  };

  return (
    <FormCard classes={{ cardContent: 'map-card-content' }}>
      <FormItemRoot>
        <Stack spacing={2}>
          {!!mapCameraValues && (
            <FireHydrantMap
              {...mapCameraValues}
              breadcrumb={locationBreadCrumb}
              mapTypeId={mapTypeId}
              markers={markers
                .concat(filteredUnitShiftMarkers || [])
                .concat(cfsMapMarkers)
                .concat(newCfsAddressMarker || [])
                .concat(locationMapMarkers || [])}
              showContextMenu={false}
              showStreetViewControl={true}
              onBoundsChanged={handleMapBoundsChanged}
              onHandleMapTypeIdChange={setMapTypeId}
              style={{ height: 342, borderRadius: 8 }}
            >
              <QuickNavigationControl onClick={setMapCameraValues} />
              <ExpandControl onExpand={handleExpandMap} />
            </FireHydrantMap>
          )}
          <Box className="map-details-wrapper">
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{ fontSize: '0.75rem', color: colors.grey[80] }}
                >
                  Cross streets
                </Typography>
                <Typography className="map-item-value">
                  {crossStreetsLabel}
                </Typography>
              </Grid>
              {showNearestIntersection && (
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: '0.75rem', color: colors.grey[80] }}
                  >
                    Nearest intersection
                  </Typography>
                  <Typography className="map-item-value">
                    {nearestIntersection && nearestIntersection.length > 0
                      ? nearestIntersection.join(' and ')
                      : '-'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Stack>
      </FormItemRoot>
    </FormCard>
  );
};
