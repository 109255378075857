import { useContext, useState } from 'react';

import {
  PaletteMode,
  Stack,
  styled,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import { IcMoon, IcSun } from '@/assets/images';
import { ThemeModeContext } from '@/contexts';

export const ThemeSwitch = ({
  switchMode,
}: {
  switchMode?: 'toggle' | 'radio';
}) => {
  const { mode, toggleThemeMode } = useContext(ThemeModeContext);
  const [theme, setTheme] = useState(mode);
  const [checked, setChecked] = useState(mode === 'dark');

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newTheme: PaletteMode,
  ) => {
    if (newTheme === null) return;
    setTheme(newTheme);
    toggleThemeMode(newTheme);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setTheme('dark');
      toggleThemeMode('dark');
    } else {
      setTheme('light');
      toggleThemeMode('light');
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: t }) => ({
    '& .MuiToggleButton-root.Mui-selected': {
      background: theme === 'light' ? '#0D2C3E' : t.palette.background.default,
    },
  }));

  return switchMode === 'radio' ? (
    <Stack
      flex={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography>Dark mode</Typography>
      <Switch
        color="error"
        checked={checked}
        onChange={handleSwitchChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Stack>
  ) : (
    <StyledToggleButtonGroup
      orientation="vertical"
      value={theme}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="light" aria-label="light">
        <IcSun />
      </ToggleButton>
      <ToggleButton value="dark" aria-label="dark" sx={{ color: 'white' }}>
        <IcMoon />
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};
