import { IconButton } from '@mui/material';
import { GridColDef, getGridStringOperators } from '@mui/x-data-grid-pro';

import { IcVisibility, IcPencil, IcDeleteTrash } from '@/assets/images';
import { excludeValues, sortStrings } from '@/components/DataGrid/utils';
import { IMasterAddress } from '@/models/master.address';

const addressLabel = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    return params.row.normalized || params.row.addressLabel || '-';
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
  sortComparator: sortStrings,
});

const subAddress = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.subAddress || '-',
  filterOperators: getGridStringOperators().filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
  sortComparator: sortStrings,
});

const cautionsCount = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.cautionsCount || 0,
  filterable: false,
});

const actions = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterAddress) => void,
): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: (params) => (
    <>
      <IconButton
        aria-label="visibility"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}/view`);
        }}
      >
        <IcVisibility />
      </IconButton>
      <IconButton
        aria-label="edit"
        onClick={(e) => {
          e.stopPropagation();
          if (params.row._id === undefined) return;
          navigate?.(`${params.row._id}/edit`);
        }}
      >
        <IcPencil />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          onDelete?.(params.row);
        }}
      >
        <IcDeleteTrash />
      </IconButton>
    </>
  ),
});

export const masterAddressCols = (
  navigate?: (path: string) => void,
  onDelete?: (row: IMasterAddress) => void,
) => {
  return {
    addressLabel,
    subAddress,
    cautionsCount,
    actions: () => actions(navigate, onDelete),
  };
};
