import {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  IconButton,
  Menu,
  Stack,
  Typography,
  Tooltip,
  Button,
} from '@mui/material';

import { Ic911, IcPlus } from '@/assets/images';
import { useCFSContext, useDimensions } from '@/hooks';
import {
  AgencyType,
  AssignedUnitShiftStatus,
  IAssignedUnitShift,
  IRequiredUnit,
} from '@/models';
import { useAgencyStore, useDataStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { requiredUnitFields } from './data';
import RequiredUnitForm from './RequiredUnitForm';
import { EditableRequiredUnitsRoot } from './styles';

interface MenuProps {
  el?: HTMLElement;
  agencyType?: AgencyType;
  unitType?: string;
}

export const calcRequiredUnits = (
  requiredUnits: IRequiredUnit[],
  agencyType?: AgencyType,
  unitType?: string,
) => {
  return requiredUnits.reduce((total, requiredUnit) => {
    if (unitType) {
      if (requiredUnit.unitType._id === unitType) {
        return total + requiredUnit.number;
      }
    } else if (requiredUnit.unitType.agencyType === agencyType) {
      return total + requiredUnit.number;
    }
    return total;
  }, 0);
};

export const calcAssignedUnitShifts = (
  assignedUnitShifts: IAssignedUnitShift[],
  agencyType?: AgencyType,
  unitType?: string,
) => {
  return assignedUnitShifts.reduce((total: string[], { status, unitShift }) => {
    if (
      status !== AssignedUnitShiftStatus.CANCELED &&
      total.indexOf(String(unitShift._id)) < 0
    ) {
      if (unitType) {
        if (unitShift?.unit?.type?._id === unitType) {
          total.push(String(unitShift?._id));
        }
      } else if (unitShift?.unit?.type?.agencyType === agencyType) {
        total.push(String(unitShift?._id));
      }
    }
    return total;
  }, []).length;
};

export const EditableRequiredUnits = () => {
  const carouselRef = useRef<Carousel>(null);
  const { width: carouselWidth } = useDimensions(
    carouselRef.current?.containerRef,
  );

  const { account } = useUserStore();
  const { unitTypes } = useDataStore();
  const { agencies } = useAgencyStore();
  const { cfs, ablyCfsChannel, updateCFS } = useCFSContext();
  const [menu, setMenu] = useState<MenuProps>({});
  const openMenu = Boolean(menu.el);
  const requiredUnits = cfs?.requiredUnits || [];
  const assignedUnitShifts = cfs?.assignedUnitShifts || [];
  const isSingleAgencyAccount =
    account?.settings.groupCFSRequiredUnits === false;

  // Required unit type items in single agency account.
  const singleAgencyUnitTypes = useMemo(() => {
    if (!account || !agencies.length) return [];
    if (!isSingleAgencyAccount) return [];

    return requiredUnits.map(({ unitType }) => unitType);
  }, [account, unitTypes, agencies.length, cfs?.requiredUnits]);

  const getRequiredUnits = useCallback(
    (agencyType?: AgencyType, unitType?: string) => {
      return calcRequiredUnits(requiredUnits, agencyType, unitType);
    },
    [requiredUnits],
  );

  const getAssignedUnitShifts = useCallback(
    (agencyType?: AgencyType, unitType?: string) => {
      return calcAssignedUnitShifts(assignedUnitShifts, agencyType, unitType);
    },
    [assignedUnitShifts],
  );

  const handleMenuOpen = (
    event: MouseEvent<HTMLButtonElement>,
    agencyType?: AgencyType,
    unitType?: string,
  ) => {
    event.stopPropagation();
    setMenu({ el: event.currentTarget, agencyType, unitType });
  };

  const onUpdateRequiredUnits = (newRequiredUnits: IRequiredUnit[]) => {
    if (cfs) {
      updateCFS({ ...cfs, requiredUnits: newRequiredUnits });
    }
    ablyCfsChannel?.publish('cfsForm', { requiredUnits: newRequiredUnits });
    setMenu((v) => ({ ...v, el: undefined }));
  };

  const renderRequiredUnitItem = (
    name: string,
    _assignedUnitShifts: number,
    _requiredUnits: number,
    Icon?: FunctionComponent<CustomSvgProps> | string,
    _agencyType?: AgencyType,
    _unitType?: string,
  ) => {
    return (
      <Stack
        key={_agencyType || _unitType}
        flexDirection="row"
        columnGap={1}
        alignItems="center"
        style={{ fontWeight: 600 }}
        width={74}
      >
        {!!Icon && (
          <Tooltip title={name} arrow>
            <IconButton
              onClick={(e) => handleMenuOpen(e, _agencyType, _unitType)}
            >
              {typeof Icon === 'string' ? (
                <img src={Icon} style={{ height: 22, width: 22 }} />
              ) : (
                <Icon
                  style={{ color: colors.error.main, height: 22, width: 22 }}
                  viewBox="0 0 23 23"
                />
              )}
            </IconButton>
          </Tooltip>
        )}
        <Typography
          variant="inherit"
          sx={{ fontSize: '0.9375rem' }}
        >{`${_assignedUnitShifts} / ${_requiredUnits}`}</Typography>
      </Stack>
    );
  };

  const showCarouselIcons =
    singleAgencyUnitTypes.length > Math.floor(carouselWidth / 74);

  return (
    <EditableRequiredUnitsRoot
      columnGap={2}
      className={!showCarouselIcons ? 'float-right' : ''}
      style={{
        // paddingRight: showCarouselIcons ? 20 : 0,
        paddingLeft: showCarouselIcons ? 30 : 0,
      }}
    >
      {!!singleAgencyUnitTypes.length && (
        <Carousel
          ref={carouselRef}
          className=""
          containerClass="w-full"
          itemClass="slide-item"
          responsive={{
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 1280 },
              items: Math.floor(carouselWidth / 74),
            },
          }}
          sliderClass="slider"
          slidesToSlide={1}
          renderArrowsWhenDisabled
          arrows={showCarouselIcons}
        >
          {carouselWidth > 0 &&
            carouselWidth < 600 &&
            singleAgencyUnitTypes.map((item) =>
              renderRequiredUnitItem(
                item.name,
                getAssignedUnitShifts(item.agencyType, item._id),
                getRequiredUnits(item.agencyType, item._id),
                item.icon ?? Ic911,
                item.agencyType,
                item._id,
              ),
            )}
        </Carousel>
      )}
      {isSingleAgencyAccount && !!agencies.length && (
        <Stack style={{ paddingLeft: 10, zIndex: 100 }}>
          <Button
            color="error"
            variant="contained"
            onClick={(e) => handleMenuOpen(e, agencies[0].type)}
            sx={{ p: 1, minWidth: '40px', height: '36px' }}
          >
            <IcPlus />
          </Button>
        </Stack>
      )}

      {account?.settings.groupCFSRequiredUnits &&
        requiredUnitFields.map((item) =>
          renderRequiredUnitItem(
            item.name,
            getAssignedUnitShifts(item.agencyType),
            getRequiredUnits(item.agencyType),
            item.icon,
            item.agencyType,
          ),
        )}
      <Menu
        id="unit-filter-menu"
        anchorEl={menu.el}
        open={openMenu}
        onClose={() =>
          setMenu((curr) => ({
            unitType: curr.unitType,
          }))
        }
        MenuListProps={{
          'aria-labelledby': 'unit-filter-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <RequiredUnitForm
          agencyType={menu.agencyType}
          unitType={menu.unitType}
          onUpdateRequiredUnits={onUpdateRequiredUnits}
        />
      </Menu>
    </EditableRequiredUnitsRoot>
  );
};
