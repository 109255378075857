import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

import { IcDotSpread } from '@/assets/images';
import { DATE_TIME_FORMAT } from '@/config';

const taskId = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.taskID || '-',
});

const title = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.title || '-',
});

const taskType = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.taskType?.type || '-',
  valueGetter: (_, row) => row.taskType?.type,
});

const status = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.status?.status || '-',
  valueGetter: (_, row) => row.status?.status || '-',
});

const assignee = (): Partial<GridColDef> => ({
  renderCell: (params) => params.row.assignee?.fullName || '-',
  valueGetter: (_, row) => row.assignee?.fullName || '-',
});

const createdAt = (): Partial<GridColDef> => ({
  renderCell: (params) =>
    params.row.createdAt
      ? moment(params.row.createdAt).format(DATE_TIME_FORMAT)
      : '-',
});

const actions = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
): Partial<GridColDef> => ({
  sortable: false,
  renderCell: (params) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openMenu?.(e, params.row);
      }}
    >
      <IcDotSpread />
    </IconButton>
  ),
});

export const taskCols = (
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>, param: any) => void,
) => ({
  taskId,
  title,
  taskType,
  status,
  assignee,
  createdAt,
  actions: () => actions(openMenu),
});
