import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  controllButton: {
    cursor: 'pointer',
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: '44px !important',
    height: '44px !important',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    alignItems: 'center',
    boxShadow: '10px 10px 50px 0px #0000001A',
    border: `1px solid ${theme.palette.background.default}`,
  },
  topButtonGroup: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  bottomButtonGroup: {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));
