import { ReactNode } from 'react';

import { Tooltip, Typography, TypographyProps } from '@mui/material';

import useTooltipActivation from '@/hooks/useTooltipActivation';

interface EllipsisTooltipProps extends TypographyProps {
  children: ReactNode;
}

export const EllipsisTooltip = ({
  children,
  ...props
}: EllipsisTooltipProps) => {
  const { textElementRef, disableTooltipHover } = useTooltipActivation();

  return (
    <Tooltip
      title={<Typography>{children}</Typography>}
      disableHoverListener={disableTooltipHover}
    >
      <Typography
        ref={textElementRef}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
