import { ContactlessOutlined } from '@mui/icons-material';
import { Box, Typography, Stack } from '@mui/material';

import { stage } from '@/config';

interface EnvironmentBadgeProps {
  open?: boolean;
}

const EnvironmentBadge: React.FC<EnvironmentBadgeProps> = ({
  open,
}: EnvironmentBadgeProps) => {
  if (stage === 'prod') return null;

  const getEnvStyles = () => {
    switch (stage) {
      case 'dev':
        return {
          backgroundColor: '#EEF2FF',
          color: '#5586FF',
          text: open ? 'Development Environment' : 'Dev',
        };
      case 'staging':
        return {
          backgroundColor: '#FDEBEE',
          color: '#EE3E5C',
          text: open ? 'Staging Environment' : 'Stg',
        };
      case 'local':
        return {
          backgroundColor: '#E8FFF3',
          color: '#00B368',
          text: open ? 'Local Environment' : 'Loc',
        };
      case 'test':
        return {
          backgroundColor: '#FFF8E8',
          color: '#FFB800',
          text: open ? 'Test Environment' : 'Test',
        };
      default:
        return null;
    }
  };

  const styles = getEnvStyles();
  if (!styles) return null;

  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
        backgroundColor: styles.backgroundColor,
        borderRadius: 2,
        mb: 2,
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={open ? 'flex-start' : 'center'}
      >
        {open && (
          <>
            <ContactlessOutlined sx={{ color: styles.color }} />
            <Typography
              sx={{
                color: styles.color,
                fontWeight: 500,
              }}
            >
              {styles.text}
            </Typography>
          </>
        )}
        {!open && (
          <Typography
            sx={{
              color: styles.color,
              fontWeight: 500,
            }}
          >
            {styles.text}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default EnvironmentBadge;
