import { IconButton, Chip, Typography, Box } from '@mui/material';
import {
  GridColDef,
  getGridDateOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';

import { IcVisibility, IcPencil, IcDeleteTrash } from '@/assets/images';
import { excludeValues } from '@/components/DataGrid/utils';
import { masterIndexLogsEventStatus } from '@/pages/MasterIndex/data';
import { colors } from '@/theme/variables';
import { getFormattedDateTime } from '@/utils';

const createdAt = (): Partial<GridColDef> => ({
  renderCell: (params) => (
    <Typography
      variant="body2"
      display="flex"
      alignItems="center"
      height="100%"
    >
      {getFormattedDateTime(new Date(params.row.createdAt).toISOString())}
    </Typography>
  ),
  filterOperators: getGridDateOperators(true).filter(
    excludeValues(['isAnyOf', 'isNotEmpty', 'isEmpty']),
  ),
});

const event = (): Partial<GridColDef> => ({
  renderCell: (params) => {
    const {
      label = '',
      color = '',
      bgColor = '',
    } = masterIndexLogsEventStatus[
      params.row.event as keyof typeof masterIndexLogsEventStatus
    ] || {
      label: 'Unknown',
      color: 'white',
      bgColor: colors.primary.main,
    };
    return (
      <Box height="100%" display="flex" alignItems="center">
        <Chip
          label={label}
          sx={{
            backgroundColor: bgColor,
            color,
            borderRadius: 1.2,
            width: 80,
            fontSize: 12,
            textAlign: 'center',
            p: 0,
          }}
        />
      </Box>
    );
  },
  filterOperators: getGridStringOperators().filter(
    excludeValues([
      'isAnyOf',
      'equals',
      'doesNotEqual',
      'startsWith',
      'endsWith',
      'isEmpty',
      'isNotEmpty',
    ]),
  ),
});

const actions = (): Partial<GridColDef> => ({
  sortable: false,
  filterable: false,
  renderCell: () => (
    <>
      <IconButton aria-label="visibility">
        <IcVisibility />
      </IconButton>
      <IconButton aria-label="edit">
        <IcPencil />
      </IconButton>
      <IconButton aria-label="delete">
        <IcDeleteTrash />
      </IconButton>
    </>
  ),
});

export const masterLogsCols = () => {
  return {
    createdAt,
    event,
    actions,
  };
};
